import { useCallback } from "react";

import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import BaseChicletMap from "truck-module-common-timing/ChicletMap";

import saddleCloths from "../sky-timing/assets/saddle-cloths";
import DefaultSaddle from "../sky-timing/assets/saddle-cloths/DefaultSaddle";

import css from "./ChicletMap.module.css";

type ChicletHorse = {
    number: number;
    distanceToFinish: number;
    distanceToRail: number;
};

type ChicletMapState = {
    timestamp: number;
    horses: ChicletHorse[];
    raceLength: number;
    raceDirection: "clockwise" | "anticlockwise";
    isInFinishStraight: boolean;
};

const defaultState: ChicletMapState = {
    timestamp: 0,
    horses: [],
    raceLength: 1,
    raceDirection: "clockwise",
    isInFinishStraight: false,
};

export default function ChicletMap() {
    const state = useTruckComponent("SkyHarness/lf_chiclets", defaultState);

    const { data } = state;

    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const renderChiclet = useCallback((horse: ChicletHorse) => {
        const src = saddleCloths[horse.number as keyof typeof saddleCloths];

        return src ? (
            <img src={src} className={css.chiclet} />
        ) : (
            <DefaultSaddle number={horse.number} />
        );
    }, []);

    const renderDistanceMarker = useCallback(
        (distance: number) => {
            const text =
                distance == data.raceLength
                    ? "FINISH"
                    : `${data.raceLength - distance}m`;
            return <div className={css.distanceMarker}>{text}</div>;
        },
        [data.raceLength],
    );

    if (!visible) {
        return null;
    }

    return (
        <div className={css.chicletMapContainer} ref={ref}>
            <div className={css.chicletMap}>
                <BaseChicletMap
                    horses={data.horses}
                    timestamp={data.timestamp}
                    raceLength={data.raceLength}
                    raceDirection={data.raceDirection}
                    isInFinishStraight={data.isInFinishStraight}
                    xRangeMetres={30}
                    yRangeStdDevs={1.2}
                    renderChiclet={renderChiclet}
                    renderDistanceMarker={renderDistanceMarker}
                />
            </div>
        </div>
    );
}
