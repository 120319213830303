import css from "./Sponsor.module.css";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import { SponsorType } from "truck-module-common-timing/types";

type SponsorState = {
    url?: string;
    type?: SponsorType;
};

const defaultState: SponsorState = {
    url: "",
};

export default function Sponsor(props: {
    modulePrefix: string;
    visible: boolean;
}) {
    const state = useTruckComponent(
        `${props.modulePrefix}/lf_timingSponsor`,
        defaultState,
    );

    if (!props.visible || !state.data.url || !state.data.url) {
        return null;
    }

    return state.data.type === SponsorType.Image ? (
        <img className={css.sponsor} src={state.data.url} />
    ) : (
        <video className={css.sponsor} src={state.data.url} autoPlay muted />
    );
}
