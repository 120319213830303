import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import trotsLogo from "./assets/queensland.png";
import css from "./Sponsor.module.css";

type SponsorState = {
    url: string | undefined;
    isInLastLap: boolean;
    distanceToFinish: number;
};

const defaultState: SponsorState = {
    url: undefined,
    isInLastLap: false,
    distanceToFinish: 0,
};

export default function Sponsor(props: { prefix: string; visible: boolean }) {
    const { data } = useTruckComponent(
        `${props.prefix}/lf_timingSponsor`,
        defaultState,
    );

    const { visible, ref } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const isSponsor = !!(data.url && data.distanceToFinish % 200 >= 100);

    const { visible: sponsorVisible, ref: sponsorRef } = useAnimation(
        isSponsor,
        {
            enter: css.sponsorEnter,
            exit: css.sponsorExit,
        },
    );

    const { visible: trotsVisible, ref: trotsRef } = useAnimation(!isSponsor, {
        enter: sponsorVisible ? css.trotsEnter : "",
        exit: css.trotsExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div ref={ref} className={css.sponsor}>
            {trotsVisible && (
                <img className={css.trots} ref={trotsRef} src={trotsLogo} />
            )}
            {sponsorVisible && (
                <img className={css.bell} ref={sponsorRef} src={data.url} />
            )}
        </div>
    );
}
