import cn from "classnames";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import formatTime from "truck-module-common-timing/formatTime";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import UpDownCaret, { ComparedToAverage } from "./UpDownCaret";

import css from "./Sectionals.module.css";

type SectionalData = {
    time: number;
    comparedToAverage?: ComparedToAverage;
};

type QuarterSectionalsState = {
    leadTime: SectionalData | undefined;
    quarters: SectionalData[];
};

const defaultState: QuarterSectionalsState = {
    leadTime: undefined,
    quarters: [],
};

const MAX_SECTIONALS = 4;

export default function Sectionals() {
    const state = useTruckComponent(
        "SkyHarness/lf_quarterSectionals",
        defaultState,
    );
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    const sectionals = data.quarters.map((q, i) => ({
        ...q,
        name: `Q${i + 1}`,
    }));
    sectionals.reverse();
    if (data.leadTime) {
        sectionals.push({ ...data.leadTime, name: "LT" });
    }

    // Add 1 to MAX_SECTIONALS so we can animate an exiting sectional
    const numSectionals = Math.min(sectionals.length, MAX_SECTIONALS + 1);

    return (
        <div className={css.sectionals} ref={ref}>
            {sectionals.slice(0, numSectionals).map((s, i) => (
                <Sectional
                    key={s.name}
                    index={i}
                    name={s.name}
                    time={s.time}
                    compared={s.comparedToAverage}
                    exiting={i == MAX_SECTIONALS}
                />
            ))}
        </div>
    );
}

function Sectional(props: {
    index: number;
    name: string;
    time: number;
    compared: ComparedToAverage | undefined;
    exiting: boolean;
}) {
    const flipRef = useFlipTransition(props.index);

    const { visible, ref: animationRef } = useAnimation(!props.exiting, {
        enter: css.sectionalEnter,
        exit: css.sectionalExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div
            className={cn(
                css.sectional,
                props.index % 2 == 0 ? css.even : css.odd,
            )}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            <div className={css.name}>{props.name}</div>
            <div className={css.time}>
                <UpDownCaret compared={props.compared} />
                {formatTime(props.time, 2)}
            </div>
        </div>
    );
}
