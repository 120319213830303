import React from "react";

import LowerFrameAnimatedWithImageBox from "../_animation/lf_animatedWithImageBox/LowerFrameAnimatedWithImageBox";

import "./SilkAndName.styles.less";
import oldStyleComponent from "../../core/oldStyleComponent";

interface SilkAndNameProps {
    silk: string;
    personName: string;
    // TODO: Use a typescript enum for titles
    personTitle: string;
    horseNumber: number;
    horseName: string;
    gateNumber: string;
    number: string;
}

class SilkAndName extends React.Component<SilkAndNameProps> {
    animator: LowerFrameAnimatedWithImageBox | null = null;

    onEntering = () => this.animator!.onEntering();
    onExiting = () => this.animator!.onExiting();

    render() {
        const {
            silk,
            personName,
            personTitle,
            horseNumber,
            horseName,
            gateNumber,
        } = this.props;

        return (
            <LowerFrameAnimatedWithImageBox
                ref={(animator) => (this.animator = animator)}
                className="lf_silk-and-name"
                image={<img src={silk} />}
            >
                <div className="two-line">
                    <div className="title">{personName}</div>

                    <div className="text">
                        {personTitle} - {horseNumber}. {horseName}{" "}
                        {isNumeric(gateNumber) && `(${gateNumber})`}
                    </div>
                </div>
            </LowerFrameAnimatedWithImageBox>
        );
    }
}

function isNumeric(str: string) {
    return /^[0-9]+$/.test(str);
}

const mapStateToProps = (state: SilkAndNameProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_silkAndName",
    {} as SilkAndNameProps,
    mapStateToProps,
    SilkAndName,
    { timeout: { enter: 3000, exit: 3000 } },
);
