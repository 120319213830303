import cn from "classnames";
import { ReactElement, useCallback, useRef, useState } from "react";

import useSVGInvertedScaling from "truck-core/hooks/useSVGInvertedScaling";

type TrackMapProps = {
    distanceToFinish: number;
    raceLength: number;
    map: Map;
    padding?: Padding | undefined;
    trackPathStrokeWidth: number;
    trackPathClassName?: string | undefined;
    racePathStrokeWidth: number;
    racePathClassName?: string | undefined;
    racePathLapClassNames?: (string | undefined)[] | undefined;
    trackOutlineStrokeWidth?: number | undefined;
    trackOutlineClassName?: string | undefined;
    raceStartMarker?: SvgPathMarker;
    raceEndMarker?: SvgPathMarker;
    positionMarker?: ReactElement | undefined;
    textBox?: ReactElement | undefined;
    finishMarker?: ReactElement | undefined;
};

type SvgPathMarker = ReactElement<{ id: string }, "marker"> | undefined;

export type Map = {
    // a path describing the physical track
    trackPath: string;
    // the path the horses follow from start to finish
    racePaths: RacePath[];
    dtfLocation?: Point | null;
};

type RacePath = {
    lap: number;
    length: number;
    path: string;
};

type Padding = {
    left?: number | undefined;
    top?: number | undefined;
    right?: number | undefined;
    bottom?: number | undefined;
};

type Point = {
    x: number;
    y: number;
};

export default function TrackMap(props: TrackMapProps) {
    const { raceLength, distanceToFinish, map, padding } = props;
    const distanceFromStart = raceLength - distanceToFinish;

    const [positionPoint, setPositionPoint] = useState({ x: 0, y: 0 });
    const [finishPoint, setFinishPoint] = useState({ x: 0, y: 0 });

    const { scalingFactor, ref: scalingRef } = useSVGInvertedScaling();

    const {
        viewBox,
        boxRef: autoFitBoxRef,
        pathRef: autoFitPathRef,
    } = useSVGAutoFit({
        left: (padding?.left ?? 0) * scalingFactor,
        top: (padding?.top ?? 0) * scalingFactor,
        right: (padding?.right ?? 0) * scalingFactor,
        bottom: (padding?.bottom ?? 0) * scalingFactor,
    });

    if (!map.trackPath || !map.racePaths) {
        return null;
    }

    const { x, y } = map.dtfLocation ? map.dtfLocation : { x: null, y: null };

    const startMarkerUrl = markerUrl(props.raceStartMarker);
    const endMarkerUrl = markerUrl(props.raceEndMarker);

    let totalPathLength = 0;
    const paths = map.racePaths.map((racePath, i) => {
        const previousPathLength = totalPathLength;
        totalPathLength += racePath.length;
        const distanceAlongPath = Math.min(
            Math.max(distanceFromStart - previousPathLength, 0),
            racePath.length,
        );

        const isFirstPath = i == 0;
        const isLastPath = i == map.racePaths.length - 1;
        const isCurrentPath =
            distanceFromStart >= previousPathLength &&
            distanceFromStart < totalPathLength;

        const markerStart = isFirstPath ? startMarkerUrl : undefined;
        const markerEnd = isLastPath ? endMarkerUrl : undefined;

        const ref =
            isCurrentPath || isLastPath
                ? (path: SVGPathElement | null) => {
                      if (path) {
                          // work out where the position marker should be placed
                          if (isCurrentPath) {
                              const { x, y } =
                                  path.getPointAtLength(distanceAlongPath);
                              if (
                                  x !== positionPoint.x &&
                                  y !== positionPoint.y
                              ) {
                                  setPositionPoint({ x, y });
                              }
                          }

                          // work out where the finish marker should be placed
                          if (isLastPath) {
                              const { x, y } = path.getPointAtLength(
                                  racePath.length,
                              );
                              if (x !== finishPoint.x && y !== finishPoint.y) {
                                  setFinishPoint({ x, y });
                              }
                          }
                      }
                  }
                : undefined;

        return (
            <path
                markerStart={markerStart}
                markerEnd={markerEnd}
                key={racePath.lap}
                ref={ref}
                className={cn(
                    props.racePathClassName,
                    props.racePathLapClassNames?.[racePath.lap - 1],
                )}
                strokeWidth={`${props.racePathStrokeWidth * scalingFactor}px`}
                pathLength={racePath.length}
                d={racePath.path}
                // hide the portion of the path that has not yet been completed
                strokeDasharray={`${distanceAlongPath} ${
                    racePath.length - distanceAlongPath
                }`}
            />
        );
    });

    return (
        <svg viewBox={viewBox} style={{ width: "100%", height: "100%" }}>
            <defs>
                {props.raceStartMarker}
                {props.raceEndMarker}
            </defs>
            <g
                ref={(el) => {
                    autoFitBoxRef(el);
                    scalingRef(el);
                }}
            >
                {props.trackOutlineStrokeWidth && (
                    <path
                        className={props.trackOutlineClassName}
                        d={map.trackPath}
                        strokeWidth={`${
                            props.trackOutlineStrokeWidth * scalingFactor
                        }px`}
                    />
                )}
                <path
                    ref={autoFitPathRef}
                    className={props.trackPathClassName}
                    d={map.trackPath}
                    strokeWidth={`${
                        props.trackPathStrokeWidth * scalingFactor
                    }px`}
                />
                {paths}
                {props.textBox && (
                    <TextBox
                        scalingFactor={scalingFactor}
                        x={x}
                        y={y}
                        element={props.textBox}
                    />
                )}
                {props.finishMarker && (
                    <g
                        transform={`translate(${finishPoint.x}, ${finishPoint.y}) scale(${scalingFactor})`}
                    >
                        {props.finishMarker}
                    </g>
                )}
            </g>
            {props.positionMarker && (
                <g
                    transform={`translate(${positionPoint.x}, ${positionPoint.y}) scale(${scalingFactor})`}
                >
                    {props.positionMarker}
                </g>
            )}
        </svg>
    );
}

function TextBox(props: {
    scalingFactor: number;
    x: number | null;
    y: number | null;
    element: ReactElement;
}) {
    const { scalingFactor, x, y, element } = props;

    if (x == null || y == null) {
        // Put text at origin
        return <g transform={`scale(${scalingFactor})`}>{element}</g>;
    }

    // Put the text in a fixed size box where the center is x, y. Rectangle acts
    // as a background for the text. Changing its size will change how much
    // scaling applies after it goes through the autoFitRef.
    const xScaled = x / scalingFactor;
    const yScaled = y / scalingFactor;
    return (
        <>
            <rect
                fill="none"
                x={(xScaled - 32) * scalingFactor}
                y={(yScaled - 18) * scalingFactor}
                width={64 * scalingFactor}
                height={36 * scalingFactor}
            />
            <g transform={`translate(${x}, ${y}) scale(${scalingFactor})`}>
                {element}
            </g>
        </>
    );
}

function useSVGAutoFit(padding?: Padding | undefined) {
    const [viewBox, setViewBox] = useState<string>();
    const pathRef = useRef<SVGPathElement | null>(null);
    const boxRef = useRef<SVGGElement | null>(null);

    const updateViewBox = useCallback(() => {
        if (!pathRef.current || !boxRef.current) {
            return;
        }

        const pathBox = pathRef.current.getBBox();
        const boxBox = boxRef.current.getBBox();

        // We only want to add extra padding on edges that the track map path itself
        // is touching, since the extra padding is meant to account for the width of
        // the path and the size of position marker. Padding is ignored on edges only
        // touched by the text.
        const x = Math.min(boxBox.x, pathBox.x - (padding?.left ?? 0));
        const y = Math.min(boxBox.y, pathBox.y - (padding?.top ?? 0));

        const boxRight = boxBox.x + boxBox.width;
        const pathRight = pathBox.x + pathBox.width;
        const boxBottom = boxBox.y + boxBox.height;
        const pathBottom = pathBox.y + pathBox.height;

        const width = Math.max(boxRight, pathRight + (padding?.right ?? 0)) - x;
        const height =
            Math.max(boxBottom, pathBottom + (padding?.bottom ?? 0)) - y;

        setViewBox(`${x} ${y} ${width} ${height}`);
    }, [padding?.bottom, padding?.left, padding?.right, padding?.top]);

    const boxCallback = useCallback(
        (el: SVGGElement | null) => {
            boxRef.current = el;
            updateViewBox();
        },
        [updateViewBox],
    );

    const pathCallback = useCallback(
        (el: SVGPathElement | null) => {
            pathRef.current = el;
            updateViewBox();
        },
        [updateViewBox],
    );

    return { viewBox, boxRef: boxCallback, pathRef: pathCallback };
}

// Extract the `id` prop from a <marker> react element and turn it into a SVG URL.
// This is a bit fragile since it relies on the caller passing in a marker
// element directly (without any sort of wrapper component).
function markerUrl(marker: SvgPathMarker | undefined): string | undefined {
    if (!marker) {
        return;
    }

    return `url(#${marker.props.id})`;
}
