import { useEffect, useMemo, useState } from "react";

import {
    useTruckPlayer,
    useHarmonyClient,
    useTruckRecording,
    useTruckRecorder,
    TruckPlayerContext,
    TruckRecorderConfig,
} from "truck-core/player/react";
import { useVenkman } from "truck-core/venkman/react";

import RecordingControls from "./RecordingControls";

import css from "./App.module.css";

import HorseRacingModule, {
    AtcWinningOwnerModule,
    RedcliffeInternalScreens,
} from "truck-module-horse-racing";
import ATCTimingModule from "truck-module-atc-timing";
import SkyTimingModule from "truck-module-sky-timing";
import SkyTimingHarnessModule from "truck-module-sky-timing-harness";
import HRVTimingModule from "truck-module-hrv-timing";
import MRCTimingModule from "truck-module-mrc-timing";
import ATC150mHomeStraightModule from "truck-module-atc-homestraight";
import AtcBigScreenModule from "truck-module-atc-bigscreen";
import QldHorseRacingModule from "truck-module-qld-horseracing-timing";
import QldHarnessModule from "truck-module-qld-harness-timing";
import BreezeUpModule from "truck-module-breezeup";

type Props = {
    config: {
        harmonyUrl?: string | undefined;
        sessionToken?: string | undefined;
        licence?: string | undefined;
        identity?: string | undefined;
        recording?: string | undefined;
        recorder?: (TruckRecorderConfig & { name: string }) | undefined;
    };
};

export default function App(props: Props) {
    const venkmanConfig = useMemo(() => {
        const harmonyUrl = props.config.harmonyUrl;
        const licenceKey = props.config.licence;
        const identity = props.config.identity;

        if (harmonyUrl && licenceKey && identity) {
            return {
                harmonyUrl,
                licenceKey,
                identity,
                description: "In-truck Venkman",
            };
        } else {
            return undefined;
        }
    }, [props.config]);

    const { sessionToken: venkmanSessionToken } = useVenkman(venkmanConfig);

    const player = useTruckPlayer();
    const recorder = useTruckRecorder(
        player,
        props.config.recorder?.name,
        props.config.recorder,
    );

    const harmonyConfig = useMemo(() => {
        const url = props.config.harmonyUrl;
        const sessionToken = props.config.sessionToken ?? venkmanSessionToken;

        if (url && sessionToken) {
            return { url, sessionToken };
        } else {
            return undefined;
        }
    }, [props.config, venkmanSessionToken]);

    useHarmonyClient(recorder ?? player, harmonyConfig);

    const recording = useTruckRecording(player, props.config.recording);

    const [enabledModules, setEnabledModules] = useState<string[]>([]);

    useEffect(() => {
        function handleModuleEnabled(modules: string[]) {
            setEnabledModules(modules);
        }

        setEnabledModules(
            player.subscribeToEnabledModules(handleModuleEnabled),
        );

        () => {
            player.unsubscribeFromEnabledModules(handleModuleEnabled);
        };
    }, [player]);

    useEffect(() => {
        if (player && recording) {
            recording?.play();
        }

        return () => {
            recording?.pause();
        };
    }, [recording, player]);

    const components = [];
    for (const id of enabledModules) {
        switch (id) {
            case "ATCBigScreen":
                components.push(<AtcBigScreenModule key={id} />);
                break;
            case "AtcHomestraight":
                components.push(<ATC150mHomeStraightModule key={id} />);
                break;
            case "AtcWinningOwner":
                components.push(<AtcWinningOwnerModule key={id} />);
                break;
            case "RacingAustralia":
                components.push(<HorseRacingModule key={id} />);
                break;
            case "RedcliffeInternalScreens":
                components.push(<RedcliffeInternalScreens key={id} />);
                break;
            case "TripleS":
                components.push(<ATCTimingModule key={id} />);
                break;
            case "SkySTC":
                components.push(<SkyTimingModule key={id} modulePrefix={id} />);
                break;
            case "SkyOne":
                components.push(<SkyTimingModule key={id} modulePrefix={id} />);
                break;
            case "SkyHarness":
                components.push(<SkyTimingHarnessModule key={id} />);
                break;
            case "HRV":
                components.push(<HRVTimingModule key={id} />);
                break;
            case "MRC":
                components.push(<MRCTimingModule key={id} />);
                break;
            case "QldHorseRacing":
                components.push(<QldHorseRacingModule key={id} />);
                break;
            case "QldHarnessRacing":
                components.push(<QldHarnessModule key={id} />);
                break;
            case "BreezeUp":
                components.push(<BreezeUpModule key={id} />);
                break;
        }
    }

    return (
        <TruckPlayerContext.Provider value={player}>
            <div className={css.truck}>
                {...components.map((c) => (
                    <div
                        key={c.key}
                        id={c.key?.toString()}
                        className={css.module}
                    >
                        {c}
                    </div>
                ))}
            </div>
            {recording && <RecordingControls recording={recording} />}
        </TruckPlayerContext.Provider>
    );
}
