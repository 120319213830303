import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useAnimatedTruckComponents from "truck-core/hooks/useAnimatedTruckComponents";

import RaceTitle from "./RaceTitle";
import MapAndClock from "./MapAndClock";
import TopSpeed from "./TopSpeed";
import Sectionals from "./Sectionals";
import ChicletMap from "./ChicletMap";
import MileRate from "./MileRate";
import Watermark from "truck-module-common-timing/Watermark";

import css from "./index.module.css";

function TopSpeedAndMileRate() {
    return (
        <div className={css.topSpeedMileRate}>
            <TruckErrorBoundary>
                <TopSpeed />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <MileRate />
            </TruckErrorBoundary>
        </div>
    );
}

function MapTimeTopSpeed() {
    const { visible, ref: visibleRef } = useAnimatedTruckComponents(
        ["SkyHarness/lf_trackMap", "SkyHarness/lf_runningClock"],
        { enter: css.mapTimeTopSpeedEnter, exit: css.mapTimeTopSpeedExit },
    );

    if (!visible.some((v) => v)) {
        return null;
    }

    return (
        <div className={css.mapTimeTopSpeedContainer} ref={visibleRef}>
            <div className={css.mapTime}>
                <TruckErrorBoundary>
                    <MapAndClock />
                </TruckErrorBoundary>
            </div>
            <TopSpeedAndMileRate />
        </div>
    );
}

export default function SkyTiming() {
    return (
        <div className={css.skyTimingHarness}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="SkyHarness" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RaceTitle />
            </TruckErrorBoundary>
            <MapTimeTopSpeed />
            <TruckErrorBoundary>
                <Sectionals />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <ChicletMap />
            </TruckErrorBoundary>
        </div>
    );
}
