import { TruckEvent, TruckEventHandler, RecordedTruckEvent } from "../events";

export type TruckRecorderConfig = {
    saveEndpoint: string;
    saveInterval: number;
};

export default class TruckRecorder {
    private name: string | undefined = undefined;

    private events: RecordedTruckEvent[] = [];
    private hasSaved = false;
    private startTime: number | undefined = undefined;

    private timeout: number | undefined = undefined;

    constructor(
        private eventHandler: TruckEventHandler,
        private config: TruckRecorderConfig,
    ) {}

    start(name: string) {
        this.name = name;
        this.startTime = performance.now();
        this.save();
    }

    finish() {
        this.save();
        clearTimeout(this.timeout);
        this.timeout = undefined;
        this.name = undefined;
        this.startTime = undefined;
    }

    handleEvent(event: TruckEvent) {
        if (this.startTime) {
            this.hasSaved = false;
            this.events.push({
                ...event,
                timestamp: performance.now() - this.startTime,
            });
        }

        this.eventHandler?.handleEvent(event);
    }

    private save() {
        if (this.timeout !== undefined || !this.name) {
            return;
        }

        if (!this.hasSaved) {
            void fetch(this.config.saveEndpoint, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    name: this.name,
                    events: this.events,
                }),
            });

            this.hasSaved = true;
            this.events = [];
        }

        setTimeout(() => this.save(), this.config.saveInterval);
    }
}
