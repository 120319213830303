import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./ClockAndDistance.module.css";

type RaceState = "Scheduled" | "Armed" | "Running" | "Finished";

type ClockState = {
    time: number;
    state: RaceState;
};

type DistanceState = {
    distanceToFinish: number;
};

const defaultClockState: ClockState = {
    time: 0,
    state: "Scheduled",
};

const defaultDistanceState: DistanceState = {
    distanceToFinish: 0,
};

export default function ClockAndDistance() {
    const clock = useTruckComponent("MRC/lf_runningClock", defaultClockState);
    const distance = useTruckComponent(
        "MRC/lf_runningDistance",
        defaultDistanceState,
    );
    const { visible, ref } = useAnimation(clock.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.clockAndDistance} ref={ref}>
            <div className={css.clock}>
                <RaceTime {...clock.data} />
            </div>
            <div className={css.distance}>
                {distance.visible && <>{distance.data.distanceToFinish}M</>}
            </div>
        </div>
    );
}

function RaceTime(props: ClockState) {
    switch (props.state) {
        case "Armed":
            return <>0.0</>;
        case "Finished":
            return <>{formatTime(props.time, 2)}</>;
        case "Running":
            return <>{formatTime(props.time, 1)}</>;
        default:
            return null;
    }
}
