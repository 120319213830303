import $ from "jquery";
import { Power0, Power2, TimelineMax } from "gsap";

const ANIMATE_DELAY_BEFORE_SECONDS = 2;
const ANIMATE_DELAY_AFTER_SECONDS = 2;
// the speed of the scrolling in letters per second
const SCROLLING_SPEED_FORWARD = 2;
const SCROLLING_SPEED_BACKWARDS = 3;

export default function measureAndScrollRaceTitle(container: Element) {
    const $titleBoxInsideContainer = $(container);
    const $titleBoxInsideRightContainer = $(
        ".title-box-inside-right",
        container,
    );
    const $text = $(".text", $titleBoxInsideRightContainer);
    const raceNumber = $("h1", container);

    const maxWidth =
        ($titleBoxInsideContainer.width() || 0) -
        (raceNumber.outerWidth() || 0);
    $titleBoxInsideRightContainer.css({ "max-width": maxWidth });
    const delta = maxWidth - ($text.width() || 0);

    let tl: TimelineMax | null = null;
    if (delta < 0) {
        tl = new TimelineMax({ repeat: -1 });
        tl.delay(ANIMATE_DELAY_BEFORE_SECONDS);

        const approxNumberOfLettersToScroll =
            Math.abs(delta) / (($text.width() || 0) / $text.text().length);
        const scrollingTime =
            approxNumberOfLettersToScroll / SCROLLING_SPEED_FORWARD;
        tl.to(
            $text,
            scrollingTime,
            { ease: Power0.easeNone, x: delta },
            ANIMATE_DELAY_BEFORE_SECONDS,
        );
        tl.to(
            $text,
            approxNumberOfLettersToScroll / SCROLLING_SPEED_BACKWARDS,
            { ease: Power2.easeOut, x: 0 },
            scrollingTime +
                ANIMATE_DELAY_BEFORE_SECONDS +
                ANIMATE_DELAY_AFTER_SECONDS,
        );
    }
    return tl;
}
