import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import formatTime from "truck-module-common-timing/formatTime";

import UpDownCaret, { ComparedToAverage } from "../hrv-timing/UpDownCaret";

import css from "./QuarterSectionals.module.css";

interface SectionalData {
    horse: number;
    time: number;
    comparedToAverage?: ComparedToAverage;
}

interface QuarterSectionalsState {
    leadTime: SectionalData | undefined;
    quarters: SectionalData[];
}

const defaultState: QuarterSectionalsState = {
    leadTime: undefined,
    quarters: [],
};

const MAX_SECTIONALS = 4;

export default function QuarterSectionals() {
    const state = useTruckComponent(
        "QldHarnessRacing/lf_quarterSectionals",
        defaultState,
    );
    const { data } = state;

    const sectionals = data.quarters.map((q, i) => ({
        ...q,
        name: `Q${i + 1}`,
    }));
    sectionals.reverse();
    if (data.leadTime) {
        sectionals.push({ ...data.leadTime, name: "LT" });
    }

    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.sectionals} ref={ref}>
            {sectionals.map((s, i) => (
                <RunningStage
                    key={s.name}
                    index={i}
                    {...s}
                    visible={i < MAX_SECTIONALS}
                />
            ))}
        </div>
    );
}

function RunningStage(
    stage: SectionalData & { name: string; index: number; visible: boolean },
) {
    const { visible, ref: animationRef } = useAnimation(stage.visible, {
        enter: css.stageEnter,
        exit: css.stageExit,
    });
    const flipRef = useFlipTransition(stage.index);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={css.sectional}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            <div className={css.distance}>{stage.name}</div>
            <UpDownCaret compared={stage.comparedToAverage} />
            <div className={css.time}>{formatTime(stage.time, 2)}</div>
        </div>
    );
}
