import React from "react";
import $ from "jquery";
import className from "classnames";
import { TimelineMax, Back, Power2, Power3 } from "gsap";

import HorseName from "./HorseName";
import LivePrice from "../_shared/live-price/LivePrice";
import ApprenticeClaim from "../_shared/apprentice-claim";
import { Odds } from "../_shared/types";

import "./YardPage.styles.less";
import oldStyleComponent from "../../core/oldStyleComponent";
import * as format from "../../core/format";
import PageInfo from "../../core/PageInfo";
import ScaledText from "truck-core/ScaledText";

interface StartSummaries {
    overall?: string;
    distance?: string;
}

// This interface overlaps considerably with the TotesRunner interface.
// Are the YardPage and Totes components similar displays because if so
// this interface should extend the TotesRunner?
interface YardPageProps {
    silk: string;
    horseNumber: number;
    horseName: string;
    gateNumber: string;
    odds?: Odds;
    pageInfo: PageInfo;
    jockeyName: string;
    jockeyNameShort: string;
    trainerName: string;
    trainerNameShort: string;
    claimAmount: number;
    breeding: string;
    sire: string;
    dame: string;
    handicapWeight: number;
    dfsFormRating: number;
    startSummaries?: StartSummaries;
}

function isNumeric(str: string) {
    return /^[0-9]+$/.test(str);
}

class YardPage extends React.Component<YardPageProps> {
    timeline: TimelineMax | null = null;
    container: HTMLDivElement | null = null;

    onEntering = () => {
        const $silk = $(".silk", this.container!);
        const $content = $(".content-container", this.container!);

        const tl = (this.timeline = new TimelineMax());
        tl.fromTo(
            $silk,
            0.4,
            { ease: Back.easeOut.config(1.3), y: 400, scale: 1 },
            { ease: Back.easeOut.config(1.3), y: 0, scale: 1.5 },
            0.4,
        );
        tl.to($silk, 0.36, { ease: Power3.easeOut, scale: 1 }, 0.64);
        tl.fromTo(
            $content,
            0.4,
            { ease: Power2.easeOut, left: 100, opacity: 0 },
            { ease: Power2.easeOut, left: 200, opacity: 1 },
            1,
        );
    };

    onExiting = () => {
        this.timeline!.reverse();
    };

    render() {
        const {
            silk,
            horseNumber,
            horseName,
            gateNumber,
            odds,
            pageInfo: { currentPage },
        } = this.props;

        const cssClasses = className("lf_yard-page", {
            "page-0": currentPage === 0,
            "page-1": currentPage === 1,
        });

        return (
            <div
                className={cssClasses}
                ref={(container) => (this.container = container)}
            >
                <div className="silk">
                    <img src={silk} />
                </div>

                <div className="content-container">
                    <div className="content two-rows">
                        <div className="row color-scheme-inverse">
                            <div className="horse-details font-large">
                                <div className="horse-number">
                                    {horseNumber}
                                </div>
                                <HorseName horseName={horseName} />
                                {isNumeric(gateNumber) && (
                                    <div className="gate">({gateNumber})</div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            {currentPage === 0 && this.renderPage0()}
                            {currentPage === 1 && this.renderPage1()}
                        </div>
                    </div>

                    <div className="prices two-rows">
                        {odds?.returnWin && (
                            <LivePrice
                                className="row font-large"
                                price={format.asMoney(odds.returnWin)}
                            />
                        )}
                        {odds?.returnPlace && (
                            <LivePrice
                                className="row font-large"
                                price={format.asMoney(odds.returnPlace)}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }

    renderPage0 = () => {
        const {
            jockeyName,
            jockeyNameShort,
            trainerName,
            trainerNameShort,
            claimAmount,
            breeding,
            sire,
            dame,
        } = this.props;

        return (
            <div className="stats">
                <div className="row">
                    <div className="col">
                        <span>
                            {this.selectPersonName(jockeyName, jockeyNameShort)}{" "}
                            <ApprenticeClaim amount={claimAmount} />
                        </span>
                        <span>
                            {this.selectPersonName(
                                trainerName,
                                trainerNameShort,
                            )}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col breeding">
                        <ScaledText>{breeding}&nbsp;&nbsp;&nbsp;&nbsp;{sire} - {dame}</ScaledText>
                    </div>
                </div>
            </div>
        );
    };

    renderPage1 = () => {
        const { handicapWeight, dfsFormRating, startSummaries } = this.props;

        return (
            <div className="stats">
                <div className="row">
                    <div className="col">Career:</div>
                    <div className="col">{startSummaries?.overall}</div>
                    <div className="col">Weight:</div>
                    <div className="col">{handicapWeight}kg</div>
                </div>
                <div className="row">
                    <div className="col">Distance:</div>
                    <div className="col">{startSummaries?.distance}</div>
                    <div className="col">Rating:</div>
                    <div className="col">{dfsFormRating}</div>
                </div>
            </div>
        );
    };

    selectPersonName = (personName: string, personNameShort: string) => {
        if (!personNameShort) return personName;

        return personName.length > 15 || /[&,]/.test(personName)
            ? personNameShort
            : personName;
    };
}

// TODO work out a proper initial state
const initialState = {
    odds: undefined,
    startSummaries: {},
    pageInfo: new PageInfo(2, 0),
} as unknown as YardPageProps;

const mapStateToProps = (state: YardPageProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_yardPage",
    initialState,
    mapStateToProps,
    YardPage,
    { timeout: { enter: 3000, exit: 3000 } },
);
