import React from "react";
import atc from "./ATC.png";
import "./SponsorTile.less";

interface SponsorTileProps {
    src?: string;
    className?: string;
}

class SponsorTile extends React.Component<SponsorTileProps> {
    render() {
        return (
            <div className={"sponsor-tile " + (this.props.className || "")}>
                <img src={this.props.src || atc} alt="Australian Turf Club" />
            </div>
        );
    }
}

export default SponsorTile;
