import { useEffect, useState } from "react";
import classnames from "classnames";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useInterval from "truck-core/hooks/useInterval";

import css from "./SponsorCycle.module.css";

const TIME_BETWEEN_FRAMES = 6000;

interface ISlide {
    key: string;
    src: string;
}

interface SponsorCycleState {
    slides: ISlide[];
    windowed: boolean;
}

const defaultState: SponsorCycleState = {
    slides: [],
    windowed: false,
};

function Slide(props: { src: string; windowed: boolean; visible: boolean }) {
    const { visible: slideVisible, ref: slideAnimationRef } = useAnimation(
        props.visible,
        { enter: css.slideEnter, exit: css.slideExit },
    );
    const cn = classnames(css.layer, {
        [css.fullScreen!]: !props.windowed,
        [css.windowed!]: props.windowed,
        [css.hidden!]: !slideVisible,
    });
    return (
        <div className={cn}>
            <img src={props.src} alt="sponsor" ref={slideAnimationRef} />
        </div>
    );
}

export default function SponsorCycle(props: { component: string }) {
    const state = useTruckComponent(props.component, defaultState);
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });
    const [slide, setSlide] = useState(0);

    const { data } = state;

    useInterval(
        () => {
            setSlide((slide + 1) % data.slides.length);
        },
        TIME_BETWEEN_FRAMES,
        [data.slides],
    );

    useEffect(() => {
        // Reset the slides when we get a different array of slides
        setSlide(0);
    }, [data.slides]);

    if (!visible) {
        if (slide != 0) {
            // Reset the slides shown to 0 when the component hides
            setSlide(0);
        }
        return null;
    }

    return (
        <div className={css.sponsorCycle} ref={animationRef}>
            {data.slides.map((s, i) => (
                <Slide
                    src={s.src}
                    windowed={data.windowed}
                    visible={i === slide}
                    key={i}
                />
            ))}
        </div>
    );
}
