import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import SlicedViewport from "truck-core/SlicedViewport";

import JockeyList from "./JockeyList";

import css from "./index.module.css";

export default function ATCTiming() {
    const regions = [
        { x: 0, y: 0, width: 3840, height: 192 },
        { x: 3840, y: 0, width: 3840, height: 192 },
        { x: 7680, y: 0, width: 3840, height: 192 },
        { x: 11520, y: 0, width: 3840, height: 192 },
    ];
    return (
        <SlicedViewport regions={regions}>
            <div className={css.atcHomestraight}>
                <TruckErrorBoundary>
                    <JockeyList />
                </TruckErrorBoundary>
            </div>
        </SlicedViewport>
    );
}
