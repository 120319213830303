import React from "react";
import classNames from "classnames";

import oldStyleComponent from "../../core/oldStyleComponent";
import FullFrameAnimated, {
    FF_ANIMATED_EXIT_TIMEOUT_SECONDS,
} from "../_animation/ff_animated/FullFrameAnimated";

import SponsorTile from "../_shared/SponsorTile";

import DividendsPlaces from "./components/DividendsPlaces";
import DividendsExotics from "./components/DividendsExotics";

import "./Dividends.styles.less";
import { DIVIDENDS_PER_PAGE } from "./Dividends.reducer";

import { DividendsComponentState, Dividends as DividendsType } from "./types";
import reducer, { initialState } from "./Dividends.reducer";

interface DividendsProps extends DividendsType {
    autoScroll: boolean;
    logo: string;
    startAtRowNumber: number;
}

class Dividends extends React.Component<DividendsProps> {
    backdrop: FullFrameAnimated | null = null;
    animator: FullFrameAnimated | null = null;

    onEntering = () => {
        if (this.backdrop) this.backdrop.onEntering();
        this.animator!.onEntering();
    };

    onExiting = () => {
        if (this.backdrop) this.backdrop.onExiting();
        this.animator!.onExiting();
    };

    render() {
        const {
            raceNumber,
            raceName,
            distanceMetres,
            isInterim,
            places,
            dividends,
            numberOfPlaces,
            logo,
            meetingName,
            autoScroll,
            startAtRowNumber,
        } = this.props;

        const distance = `${distanceMetres}m`;
        const showDividends = !!(dividends && dividends.length);

        const dividendClasses = classNames({
            interim: isInterim,
            final: !isInterim,
        });

        const statusBoxContentClass = classNames(
            "status-box-content",
            dividendClasses,
        );

        return (
            <FullFrameAnimated
                ref={(animator) => (this.animator = animator)}
                raceNumber={raceNumber}
                raceName={raceName}
                distance={distance}
                meetingName={meetingName}
                classNames={dividendClasses}
                hideFooter={!showDividends}
                meetingMaxWidth={575}
                meetingMaxHeight={83}
                meetingInitialFontSize={63}
                logo={<SponsorTile src={logo} />}
                status={
                    <div className={statusBoxContentClass}>
                        {isInterim ? "Interim" : "Final"}
                    </div>
                }
            >
                <DividendsPlaces
                    places={places}
                    numberOfPlaces={numberOfPlaces}
                    trainerLeftOffset={"617px"}
                    scrollingDistance={59}
                />
                <DividendsExotics
                    exotics={dividends}
                    autoScroll={autoScroll}
                    startAtRowNumber={startAtRowNumber}
                />
            </FullFrameAnimated>
        );
    }
}

const mapStateToProps = (state: DividendsComponentState) => {
    const componentState = reducer(state);

    const { pageInfo, dividends } = componentState;

    const startIndex =
        pageInfo.currentPage > 0
            ? Math.min(
                  pageInfo.currentPage * DIVIDENDS_PER_PAGE - 1,
                  dividends.length - DIVIDENDS_PER_PAGE,
              )
            : 0;

    return {
        ...componentState,
        startAtRowNumber: startIndex,
    };
};

export default oldStyleComponent(
    "RacingAustralia/ff_dividends",
    initialState,
    mapStateToProps,
    Dividends,
    { timeout: { enter: 3000, exit: FF_ANIMATED_EXIT_TIMEOUT_SECONDS * 1500 } },
);

// TODO: This is kind of hacky but redcliffe is supposed to get new styles soon
export const RedcliffeDividends = oldStyleComponent(
    "RedcliffeInternalScreens/ff_dividends",
    initialState,
    mapStateToProps,
    Dividends,
    { timeout: { enter: 3000, exit: FF_ANIMATED_EXIT_TIMEOUT_SECONDS * 1500 } },
);
