import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimatedTruckComponents from "truck-core/hooks/useAnimatedTruckComponents";
import TrackMap, { Map } from "truck-module-common-timing/TrackMap";

import css from "./TrackMapAndDistance.module.css";

type TrackMapState = {
    distanceToFinish: number;
    raceLength: number;
    map: Map;
};

const trackMapDefaultState: TrackMapState = {
    distanceToFinish: 0,
    raceLength: 1,
    map: {
        trackPath: "",
        racePaths: [],
    },
};

interface RunningDistanceState {
    distanceToFinish: number;
    raceLength: number;
}

const runningDistanceDefaultState: RunningDistanceState = {
    distanceToFinish: 0,
    raceLength: 1,
};

export default function TrackMapAndDistance() {
    const { data: trackMap } = useTruckComponent(
        "QldHarnessRacing/lf_trackMap",
        trackMapDefaultState,
    );

    const { data: runningDistanceData } = useTruckComponent(
        "QldHarnessRacing/lf_runningDistance",
        runningDistanceDefaultState,
    );
    // Running distance counts up from 0 and is in increments of 50m
    const runningDistance =
        Math.floor(
            (runningDistanceData.raceLength -
                runningDistanceData.distanceToFinish) /
                50,
        ) * 50;

    const { visible, ref: visibleRef } = useAnimatedTruckComponents(
        ["QldHarnessRacing/lf_trackMap", "QldHarnessRacing/lf_runningDistance"],
        {
            enter: css.mapAndDistanceEnter,
            exit: css.mapAndDistanceExit,
        },
    );

    if (!trackMap.map.trackPath || !trackMap.map.racePaths) {
        return null;
    }

    if (!visible.some((v) => v)) {
        return null;
    }

    return (
        <div ref={visibleRef} className={css.mapAndDistance}>
            <TrackMap
                distanceToFinish={trackMap.distanceToFinish}
                raceLength={trackMap.raceLength}
                map={trackMap.map}
                padding={{ left: 6, top: 6, right: 6, bottom: 6 }}
                trackPathStrokeWidth={4}
                trackPathClassName={css.track}
                racePathStrokeWidth={4}
                racePathClassName={css.horse}
                racePathLapClassNames={[
                    css.lap1,
                    css.lap2,
                    css.lap3,
                    css.lap4,
                    css.lap5,
                ]}
                textBox={
                    <RunningDistance
                        runningDistance={runningDistance}
                        visible={visible[1]}
                    />
                }
                positionMarker={<PositionMarker />}
            />
        </div>
    );
}

function RunningDistance(props: {
    runningDistance: number;
    visible: boolean | undefined;
}) {
    const { runningDistance, visible } = props;

    return (
        <text
            className={css.runningDistanceText}
            dominantBaseline="central"
            visibility={visible ? "visible" : "hidden"}
        >
            {runningDistance}m
        </text>
    );
}

function PositionMarker() {
    return <circle className={css.positionMarker} r="5.5px" />;
}
