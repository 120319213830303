import React, { useRef, useCallback } from "react";

interface ScaledTextProps {
    children: React.ReactNode;
    resize?: (element: HTMLElement) => void;
}

function ScaledText(props: ScaledTextProps) {
    const resizeObserverRef = useRef<ResizeObserver | null>(null);
    const resizeCallback = props.resize ?? fitTextToContainer;

    const textRefCallback = useCallback(
        (element: HTMLDivElement | null) => {
            if (resizeObserverRef.current) {
                // disconnect existing observer
                resizeObserverRef.current.disconnect();
                resizeObserverRef.current = null;
            }

            if (!element) {
                return;
            }

            // We use a ResizeObserver rather than detecting the size directly,
            // because the size can change without the react component being
            // re-rendered. This happens if the text uses a web font that isn't yet
            // loaded when the component is initially rendered, so we end up
            // calculating the size based on the browser's fallback font.
            const resizeObserver = new ResizeObserver(([element]) => {
                if (element?.target instanceof HTMLElement) {
                    resizeCallback(element.target);
                }
            });

            resizeCallback(element);
            resizeObserver.observe(element);

            // save observer so we can clean up later
            resizeObserverRef.current = resizeObserver;
        },
        [resizeCallback],
    );

    return (
        <div
            style={{ display: "inline-block", whiteSpace: "nowrap" }}
            ref={textRefCallback}
        >
            {props.children}
        </div>
    );
}

function fitTextToContainer(element: HTMLElement) {
    const target = getTargetDimensions(element);
    if (!target) {
        return;
    }

    // binary search
    let bestFontSize = 1;
    let low = 1;
    // arbitrarily chosen max value that will probably always be big enough
    let high = 1000;
    while (low <= high) {
        const mid = (low + high) / 2;
        setFontSize(element, mid);
        const width = element.scrollWidth;
        const height = element.scrollHeight;
        if (width <= target.width && height <= target.height) {
            bestFontSize = mid;
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }

    setFontSize(element, bestFontSize);
}

function setFontSize(element: HTMLElement, size: number) {
    element.style.fontSize = `${size}px`;
}

function getTargetDimensions(element: HTMLElement) {
    const parent = element.parentElement;

    if (!parent) {
        return;
    }

    const parentStyle = getComputedStyle(parent);
    const width =
        parent.clientWidth -
        parseFloat(parentStyle.paddingLeft) -
        parseFloat(parentStyle.paddingRight);
    const height =
        parent.clientHeight -
        parseFloat(parentStyle.paddingTop) -
        parseFloat(parentStyle.paddingBottom);

    return { width, height };
}

export function squeeze(element: HTMLElement) {
    const target = getTargetDimensions(element);
    if (!target) {
        return;
    }

    const width = element.scrollWidth;
    if (width > target.width) {
        element.style.transform = `scaleX(${target.width / width})`;
    }
}

export default React.memo(ScaledText);
