import {
    ShowComponentEvent,
    HideComponentEvent,
    UpdateComponentEvent,
    EnableModuleEvent,
    TruckEvent,
} from "./events";

type TruckState = {
    components: Map<string, ComponentState<unknown>>;
    enabledModules: Set<string>;
};

type ComponentStateBase<T> = {
    visible: boolean;
    data: T;
};

export type ComponentState<T> = Readonly<ComponentStateBase<T>>;

type ComponentStateUpdate<T> = Partial<ComponentStateBase<T>>;

export type ComponentUpdateCallback<T> = (state: ComponentState<T>) => void;
export type EnabledModuleCallback = (enabledModules: string[]) => void;

export default class TruckPlayer {
    private state: TruckState = {
        components: new Map(),
        enabledModules: new Set(),
    };
    private componentListeners: Map<
        string,
        Set<ComponentUpdateCallback<unknown>>
    > = new Map();
    private enabledModuleListeners: Set<EnabledModuleCallback> = new Set();

    handleEvent(event: TruckEvent) {
        switch (event.type) {
            case "reset":
                this.reset();
                break;
            case "showComponent":
                this.showComponent(event);
                break;
            case "hideComponent":
                this.hideComponent(event);
                break;
            case "updateComponent":
                this.updateComponent(event);
                break;
            case "enableModule":
                this.enableModule(event);
                break;
        }
    }

    subscribeToComponent<T>(id: string, callback: ComponentUpdateCallback<T>) {
        let set = this.componentListeners.get(id);
        if (!set) {
            set = new Set();
            this.componentListeners.set(id, set);
        }

        set.add(callback as ComponentUpdateCallback<unknown>);

        return this.getComponentState(id) as ComponentState<T>;
    }

    unsubscribeFromComponent<T>(
        id: string,
        callback: ComponentUpdateCallback<T>,
    ) {
        this.componentListeners
            .get(id)
            ?.delete(callback as ComponentUpdateCallback<unknown>);
    }

    subscribeToEnabledModules(callback: EnabledModuleCallback) {
        this.enabledModuleListeners.add(callback);
        return Array.from(this.state.enabledModules);
    }

    unsubscribeFromEnabledModules(callback: EnabledModuleCallback) {
        this.enabledModuleListeners.delete(callback);
    }

    private notifyComponentChanged<T>(id: string, state: ComponentState<T>) {
        const listeners = this.componentListeners.get(id);
        listeners?.forEach((callback) => callback(state));
    }

    private notifyModuleEnabled() {
        this.enabledModuleListeners?.forEach((callback) =>
            callback(Array.from(this.state.enabledModules)),
        );
    }

    private getComponentState(id: string) {
        return this.state.components.get(id);
    }

    private getOrCreateComponentState(id: string) {
        let state = this.state.components.get(id);

        if (!state) {
            state = {
                visible: false,
                data: undefined,
            };

            this.state.components.set(id, state);
        }

        return state;
    }

    private createOrUpdateComponentState<T>(
        id: string,
        stateUpdate: ComponentStateUpdate<T>,
    ) {
        const oldState = this.getOrCreateComponentState(id);
        const newState = { ...oldState, ...stateUpdate };
        this.state.components.set(id, newState);
        return newState;
    }

    private reset() {
        for (const [id] of this.state.components) {
            this.notifyComponentChanged(id, {
                visible: false,
                data: undefined,
            });
        }

        this.state.components.clear();
        this.state.enabledModules.clear();
        this.notifyModuleEnabled();
    }

    private enableModule(event: EnableModuleEvent) {
        this.state.enabledModules.add(event.id);
        this.notifyModuleEnabled();
    }

    private showComponent(event: ShowComponentEvent) {
        const { id } = event;
        const stateUpdate = { visible: true };
        const newState = this.createOrUpdateComponentState(id, stateUpdate);

        this.notifyComponentChanged(id, newState);
    }

    private hideComponent(event: HideComponentEvent) {
        const { id } = event;
        // there's no point in creating new state for a component if it's going to be hidden anyway
        if (this.getComponentState(id)) {
            const state = this.createOrUpdateComponentState(id, {
                visible: false,
            });
            this.notifyComponentChanged(id, state);
        }
    }

    private updateComponent(event: UpdateComponentEvent) {
        const { id, data } = event;
        const state = this.createOrUpdateComponentState(id, { data });
        this.notifyComponentChanged(id, state);
    }
}
