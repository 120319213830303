import { useRef } from "react";
import { Transition } from "react-transition-group";

import useTruckComponent from "truck-core/hooks/useTruckComponent";

const defaultTransitionOptions = {
    unmountOnExit: true,
};

type TimeoutTransitionOption = {
    timeout: number | { enter?: number; exit?: number };
};
type OptionsForTransition = number | TimeoutTransitionOption;
type TransitionProps = TimeoutTransitionOption &
    typeof defaultTransitionOptions;

type MapStateToProps<State, Props> = (state: State) => Props;

export default function oldStyleComponent<State, Props>(
    componentId: string,
    defaultState: State,
    mapStateToProps: MapStateToProps<State, Props>,
    Component: React.ComponentType<Props>,
    optionsForTransition: OptionsForTransition,
) {
    const transitionOptions: TransitionProps = {
        ...defaultTransitionOptions,
        timeout: 0,
    };

    if (typeof optionsForTransition === "number") {
        transitionOptions.timeout = {
            enter: optionsForTransition,
            exit: optionsForTransition,
        };
    } else {
        Object.assign(transitionOptions, optionsForTransition);
    }

    function ConnectedComponent() {
        const { data, visible } = useTruckComponent(componentId, defaultState);
        const connectedInstance = useRef<typeof Component>();

        const props = mapStateToProps(data);

        const delegateTransitionEvent = (eventName: string) => () => {
            // TODO fix type
            const delegate = (connectedInstance.current as any)[eventName];
            delegate && delegate();
        };

        return (
            <Transition
                {...transitionOptions}
                in={visible}
                onEnter={delegateTransitionEvent("onEnter")}
                onEntering={delegateTransitionEvent("onEntering")}
                onEntered={delegateTransitionEvent("onEntered")}
                onExit={delegateTransitionEvent("onExit")}
                onExiting={delegateTransitionEvent("onExiting")}
                onExited={delegateTransitionEvent("onExited")}
            >
                <Component {...props} ref={connectedInstance} />
            </Transition>
        );
    }

    ConnectedComponent.displayName = `OldStyleComponent(${
        Component.displayName || Component.name
    })`;
    return ConnectedComponent;
}
