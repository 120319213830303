import React from "react";
import { Transition, TransitionGroup } from "react-transition-group";
import $ from "jquery";
import { Back, TweenLite, TimelineLite } from "gsap";

import Runner from "./Runner";

import oldStyleComponent from "../../core/oldStyleComponent";
import { RunningNumbersRunner } from "./types";

import "./RunningNumbers.styles.less";

interface RunningNumbersProps {
    runners: RunningNumbersRunner[];
}

class RunningNumbers extends React.Component<RunningNumbersProps> {
    container: HTMLDivElement | null = null;

    onEntering = () => {
        const tl = new TimelineLite();
        let offset = 0.4;

        const $runners = $(".runner", this.container!);

        for (const runner of $runners) {
            tl.fromTo(
                runner,
                0.2,
                { ease: Back.easeOut.config(1.7), y: 300, opacity: 0 },
                { ease: Back.easeOut.config(1.7), y: 0, opacity: 1 },
                offset,
            );
            offset += 0.08;
        }
    };

    onExiting = () => {
        const tl = new TimelineLite();
        let offset = 0;

        const $runners = $(".runner", this.container!);

        for (const runner of $runners) {
            tl.to(
                runner,
                0.2,
                { ease: Back.easeIn.config(1.7), y: 300, opacity: 0 },
                offset,
            );
            offset += 0.08;
        }
    };

    onRunnerEntering = (element: HTMLElement) => {
        const tl = new TimelineLite();
        tl.from(
            element,
            0.2,
            { ease: Back.easeOut.config(1.7), y: 300, opacity: 0 },
            0.2,
        );
    };

    onRunnerExiting = (element: HTMLElement) => {
        TweenLite.to(element, 0.2, {
            ease: Back.easeIn.config(1.7),
            y: 300,
            opacity: 0,
        });
    };

    render() {
        const { runners } = this.props;

        return (
            <div ref={(container) => (this.container = container)}>
                <TransitionGroup component="div" className="lf_runningNumbers">
                    {runners.map((runner, index) => (
                        <Transition
                            key={`${runner.horseNumber}-${index}`}
                            onEntering={this.onRunnerEntering}
                            onExiting={this.onRunnerExiting}
                            timeout={{
                                enter: 400,
                                exit: 200,
                            }}
                        >
                            <Runner
                                position={index + 1}
                                horseNumber={runner.horseNumber}
                                silk={runner.silk}
                            />
                        </Transition>
                    ))}
                </TransitionGroup>
            </div>
        );
    }
}

const initialState: RunningNumbersProps = { runners: [] };
const mapStateToProps = (state: RunningNumbersProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_runningNumbers",
    initialState,
    mapStateToProps,
    RunningNumbers,
    1600,
);
