import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import CommonTrackMap, { Map } from "truck-module-common-timing/TrackMap";

import css from "./TrackMap.module.css";

type TrackMap = {
    // a path describing the physical track
    trackPath: string;
    // the path the horses follow from start to finish
    racePath: string;
};

type TrackMapState = {
    distanceToFinish: number;
    raceLength: number;
    map: Map;
};

const defaultState: TrackMapState = {
    distanceToFinish: 0,
    raceLength: 1,
    map: {
        trackPath: "",
        racePaths: [],
    },
};

export default function TrackMap() {
    const state = useTruckComponent("TripleS/lf_trackMap", defaultState);
    const { data } = state;
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible || !data.map?.trackPath) {
        return null;
    }

    return (
        <div className={css.trackMap} ref={animationRef}>
            <CommonTrackMap
                distanceToFinish={data.distanceToFinish}
                raceLength={data.raceLength}
                map={data.map}
                padding={{ left: 50, top: 50, right: 50, bottom: 50 }}
                trackPathStrokeWidth={12}
                trackPathClassName={css.track}
                trackOutlineStrokeWidth={15}
                trackOutlineClassName={css.trackOutline}
                racePathStrokeWidth={12}
                racePathClassName={css.horse}
                // HACK: These markers can't be react components since CommonTrackMap
                // needs to access the <marker>'s ID prop directly.
                raceStartMarker={startMarker()}
                raceEndMarker={endMarker()}
                positionMarker={<PositionMarker />}
                finishMarker={<FinishMarker />}
            />
        </div>
    );
}

function PositionMarker() {
    return (
        <>
            <defs>
                <radialGradient
                    id="marker-gradient"
                    cx="0.4"
                    cy="0.4"
                    r="1"
                    fx="0.4"
                    fy="0.4"
                >
                    <stop offset="0%" stopColor="white" />
                    <stop offset="100%" stopColor="black" />
                </radialGradient>
            </defs>
            <circle
                className={css.positionMarker}
                fill="url(#marker-gradient)"
                r="15px"
            />
        </>
    );
}

function FinishMarker() {
    return (
        <text dy="35px" className={css.finishText}>
            FINISH
        </text>
    );
}

function startMarker() {
    return (
        <marker
            id="start"
            markerWidth="0.25"
            markerHeight="1"
            refX="0"
            refY="0.5"
            orient="auto"
        >
            <rect className={css.startMarker} width="0.3" height="1" />
        </marker>
    );
}

function endMarker() {
    return (
        <marker
            id="finish"
            markerWidth="0.3"
            markerHeight="1"
            refX="0"
            refY="0.5"
            orient="auto"
        >
            <rect x="0" className={css.finishMarker} width="0.3" height="30" />
        </marker>
    );
}
