import React from "react";
import classnames from "classnames";

import "./RunningNumbers.styles.less";

interface RunnerProps {
    horseNumber: number;
    silk: string;
    position: number;
}
class Runner extends React.Component<RunnerProps> {
    render() {
        const { horseNumber, silk, position } = this.props;

        const classNames = classnames("runner", `position-${position}`);

        return (
            <div className={classNames}>
                <div className="silk">
                    <img src={silk} />
                </div>

                <div className="box">
                    <div className="number">{horseNumber}</div>
                </div>
            </div>
        );
    }
}

export default Runner;
