import { useCallback } from "react";

import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import BaseChicletMap from "truck-module-common-timing/ChicletMap";
import { RaceType } from "truck-module-common-timing/types";
import saddleCloths from "truck-module-hrv-timing/assets/saddle-cloths";

import sponsorBar from "./assets/sponsor-bar.png";
import css from "./ChicletMap.module.css";

type ChicletHorse = {
    number: number;
    distanceToFinish: number;
    distanceToRail: number;
    baseColour: string;
    textColour: string;
};

type ChicletMapState = {
    timestamp: number;
    raceType: RaceType;
    horses: ChicletHorse[];
    raceLength: number;
    raceDirection: "clockwise" | "anticlockwise";
    isInFinishStraight: boolean;
};

const defaultState: ChicletMapState = {
    timestamp: 0,
    raceType: RaceType.R,
    horses: [],
    raceLength: 1,
    raceDirection: "clockwise",
    isInFinishStraight: false,
};

export default function ChicletMap() {
    const state = useTruckComponent(
        "QldHarnessRacing/lf_chiclets",
        defaultState,
    );

    const { data } = state;

    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const renderChiclet = useCallback(
        (horse: ChicletHorse) => (
            <img
                src={saddleCloths[horse.number as keyof typeof saddleCloths]}
                className={css.chiclet}
            />
        ),
        [],
    );

    const renderDistanceMarker = useCallback(
        (distance: number) => {
            const text =
                distance == data.raceLength
                    ? "FINISH"
                    : `${data.raceLength - distance}m`;
            return <div className={css.distanceMarker}>{text}</div>;
        },
        [data.raceLength],
    );

    if (!visible) {
        return null;
    }

    return (
        <div className={css.chiclets} ref={ref}>
            <div className={css.chicletMap}>
                <BaseChicletMap
                    horses={data.horses}
                    timestamp={data.timestamp}
                    raceLength={data.raceLength}
                    raceDirection={data.raceDirection}
                    isInFinishStraight={data.isInFinishStraight}
                    xRangeMetres={30}
                    yRangeStdDevs={2}
                    renderChiclet={renderChiclet}
                    renderDistanceMarker={renderDistanceMarker}
                />
            </div>
            <img src={sponsorBar} className={css.sponsorBar} />
        </div>
    );
}
