import PageInfo from "../../core/PageInfo";
import * as format from "../../core/format";
import { DividendsComponentState } from "./types";

export const DIVIDENDS_PER_PAGE = 4;

const initialState: DividendsComponentState = {
    meetingName: "",
    raceName: "",
    raceNumber: 0,
    distanceMetres: 0,

    places: [],
    dividends: [],
    numberOfPlaces: 3,
    isInterim: false,

    // For paging through exotics, not always necessary
    autoScroll: false,
    pageInfo: new PageInfo(),
};

const reducer = (state: any) => {
    const newState = {
        ...state,
    };

    newState.dividends = (newState.dividends ?? []).map((dividend: any) => {
        dividend.amount =
            dividend.amount === 0 && dividend.jackpotCarriedOver > 0
                ? "Jackpot"
                : format.asMoney(dividend.amount);

        return dividend;
    });

    newState.places.forEach((place: any) => {
        place.winAmount = format.asMoney(place.winAmount ?? 0);
        place.placeAmount = format.asMoney(place.placeAmount ?? 0);
    });

    return newState;
};

export { initialState };
export default reducer;
