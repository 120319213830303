import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./RunningStages.module.css";
import ScaledText from "truck-core/ScaledText";

interface Stage {
    endDistance: number;
    horse: number;
    time: number;
    baseColour: string;
    textColour: string;
}

interface RunningStagesState {
    // It is assumed that stages later in the array were recorded at
    // a later time (with a shorter race distance remaining)
    stages: Stage[];
}

const defaultState: RunningStagesState = {
    stages: [],
};

const MAX_STAGES = 4;

export default function RunningStages() {
    const state = useTruckComponent(
        "QldHorseRacing/lf_runningStages",
        defaultState,
    );
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    const stages = data.stages.slice().reverse();

    return (
        <div className={css.sectionals} ref={ref}>
            {stages.map((stage, i) => (
                <RunningStage
                    key={stage.endDistance}
                    index={i}
                    {...stage}
                    visible={i < MAX_STAGES}
                />
            ))}
        </div>
    );
}

function RunningStage(stage: Stage & { index: number; visible: boolean }) {
    const { visible, ref: animationRef } = useAnimation(stage.visible, {
        enter: css.stageEnter,
        exit: css.stageExit,
    });
    const flipRef = useFlipTransition(stage.index);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={css.sectional}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            <div
                style={{
                    backgroundColor: stage.baseColour,
                    color: stage.textColour,
                }}
                className={css.leader}
            >
                {stage.horse}
            </div>
            <div className={css.time}>{formatTime(stage.time, 2)}</div>
            <div className={css.distance}>
                <ScaledText>{stage.endDistance}m</ScaledText>
            </div>
        </div>
    );
}
