import RaceTable from "./RaceTable";
import Transition from "./Transition";
import RaceTitle from "./RaceTitle";

import css from "./index.module.css";
import TruckErrorBoundary from "truck-core/TruckErrorBoundary";

export default function ABAPrices() {
    return (
        <>
            <div className={css.abaPrices}>
                <RaceTable />
                <TruckErrorBoundary>
                    <Transition />
                </TruckErrorBoundary>
            </div>
            <div className={css.bottomStrip}>
                <TruckErrorBoundary>
                    <RaceTitle />
                </TruckErrorBoundary>
            </div>
        </>
    );
}
