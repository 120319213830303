import React from "react";
import $ from "jquery";
import { TimelineMax, Power0, Power2 } from "gsap";

const ANIMATE_FORWARD_SECONDS = 6;
const ANIMATE_BACK_SECONDS = 0.4;
const ANIMATE_DELAY_BEFORE_SECONDS = 2;
const ANIMATE_DELAY_AFTER_SECONDS = 2;

interface HorseNameProps {
    horseName: string;
}

class HorseName extends React.Component<HorseNameProps> {
    timeline: TimelineMax | null = null;
    textWidth = 0;

    shouldComponentUpdate(nextProps: HorseNameProps) {
        const { horseName } = this.props;
        const { horseName: nextHorseName } = nextProps;

        return horseName !== nextHorseName;
    }

    render() {
        return (
            <div
                className="horse-name-container"
                ref={(container) => this.measureAndScroll(container)}
            >
                <div className="horse-name">{this.props.horseName}</div>
            </div>
        );
    }

    measureAndScroll(container: HTMLElement | null) {
        if (!container) {
            return;
        }

        const $container = $(container);
        const $text = $(".horse-name", $container);

        // Reset CSS since if reuse this component
        $container.css({ "max-width": "none" });

        const containerWidth = $container.width() || 0;
        this.textWidth = $text.width() || 0;

        const delta = containerWidth - this.textWidth;
        if (delta >= 0) {
            $container.css({ "max-width": this.textWidth });
            return;
        }

        $container.css({ "max-width": "none" });
        const tl = (this.timeline = new TimelineMax({ repeat: -1 }));
        tl.delay(ANIMATE_DELAY_BEFORE_SECONDS);

        tl.to(
            $text,
            ANIMATE_FORWARD_SECONDS,
            { ease: Power0.easeNone, x: delta },
            ANIMATE_DELAY_BEFORE_SECONDS,
        );
        tl.to(
            $text,
            ANIMATE_BACK_SECONDS,
            { ease: Power2.easeOut, x: 0 },
            ANIMATE_FORWARD_SECONDS +
                ANIMATE_DELAY_BEFORE_SECONDS +
                ANIMATE_DELAY_AFTER_SECONDS,
        );
    }

    componentWillUnmount() {
        this.timeline && this.timeline.kill();
    }
}

export default HorseName;
