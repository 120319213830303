import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import { RaceType } from "truck-module-common-timing/types";

import css from "./JockeyPositions.module.css";
import fade from "./fade.module.css";
import ScaledText from "truck-core/ScaledText";
import { formatPrice } from "truck-module-common-timing/formatPrice";

type Jockey = {
    number: number;
    name: string;
    rank: number;
    silk: string;
    totePrice: number | null;
    baseColour: string;
    textColour: string;
};

type RunningNumbersState = {
    positions: Jockey[];
    firstHorseOnRight: boolean;
    raceType: RaceType;
};

const defaultState: RunningNumbersState = {
    positions: [],
    firstHorseOnRight: false,
    raceType: RaceType.R,
};

export default function RunningNumbers() {
    const state = useTruckComponent(
        "QldHorseRacing/lf_jockeyPositions",
        defaultState,
    );
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: fade.enter,
        exit: fade.exit,
    });

    const { data } = state;

    if (!visible) {
        return null;
    }

    return (
        <div className={css.runningNumbers} ref={animationRef}>
            {data.positions.map((jockey, i) => (
                <JockeyPosition
                    key={jockey.number}
                    index={i}
                    raceType={data.raceType}
                    {...jockey}
                />
            ))}
        </div>
    );
}

function JockeyPosition(props: Jockey & { index: number; raceType: RaceType }) {
    const ref = useFlipTransition(props.index);

    return (
        <div ref={ref} className={css.jockeyPosition}>
            <div className={css.jockey}>
                <div
                    style={{
                        backgroundColor: props.baseColour,
                        color: props.textColour,
                    }}
                    className={css.number}
                >
                    {props.number}
                </div>
                <div className={css.name}>
                    <ScaledText>{props.name}</ScaledText>
                </div>

                {props.totePrice && (
                    <div className={css.price}>
                        <ScaledText>${formatPrice(props.totePrice)}</ScaledText>
                    </div>
                )}
            </div>
        </div>
    );
}
