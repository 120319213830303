import React from "react";

type TruckErrorHandler = (error: Error, errorInfo: React.ErrorInfo) => void;

const TruckErrorBoundaryContext = React.createContext<Set<TruckErrorHandler>>(
    new Set(),
);
export { TruckErrorBoundaryContext };

type TruckErrorBoundaryProps = {
    children: React.ReactNode;
};

export default class TruckErrorBoundary extends React.Component<TruckErrorBoundaryProps> {
    private errorHandlers = new Set<TruckErrorHandler>();

    constructor(props: TruckErrorBoundaryProps) {
        super(props);
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        this.errorHandlers.forEach((f) => f(error, errorInfo));
    }

    static getDerivedStateFromError() {
        return null;
    }

    render() {
        return (
            <TruckErrorBoundaryContext.Provider value={this.errorHandlers}>
                {this.props.children}
            </TruckErrorBoundaryContext.Provider>
        );
    }
}
