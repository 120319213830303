import React, { useRef, useCallback } from "react";

interface ScaledTextProps {
    targetWidth: number;
    targetHeight: number;
    className?: string;
    children: React.ReactNode;
}

function ScaledText(props: ScaledTextProps) {
    const textRef = useRef<HTMLDivElement | null>(null);
    const resizeObserverRef = useRef<ResizeObserver | null>(null);

    const textRefCallback = useCallback(
        (element: HTMLDivElement | null) => {
            if (textRef.current && resizeObserverRef.current) {
                // disconnect existing observer
                resizeObserverRef.current.disconnect();
                resizeObserverRef.current = null;
            }

            textRef.current = element;
            if (!textRef.current) {
                return;
            }

            // We use a ResizeObserver rather than detecting the size directly,
            // because the size can change without the react component being
            // re-rendered. This happens if the text uses a web font that isn't yet
            // loaded when the component is initially rendered, so we end up
            // calculating the size based on the browser's fallback font.
            const resizeObserver = new ResizeObserver(([element]) => {
                if (element?.target instanceof HTMLElement) {
                    fitTextToContainer(
                        element.target,
                        props.targetWidth,
                        props.targetHeight,
                    );
                }
            });

            fitTextToContainer(
                textRef.current,
                props.targetWidth,
                props.targetHeight,
            );
            resizeObserver.observe(textRef.current);

            // save observer so we can clean up later
            resizeObserverRef.current = resizeObserver;
        },
        [props.targetHeight, props.targetWidth],
    );

    return (
        <div
            className={props.className}
            style={{
                maxWidth: props.targetWidth,
                maxHeight: props.targetHeight,
            }}
        >
            <div
                style={{ display: "inline-block", whiteSpace: "nowrap" }}
                ref={textRefCallback}
            >
                {props.children}
            </div>
        </div>
    );
}

function fitTextToContainer(
    element: HTMLElement,
    targetWidth: number,
    targetHeight: number,
) {
    // binary search
    let bestFontSize = 1;
    let low = 1;
    // arbitrarily chosen max value that will probably always be big enough
    let high = 1000;
    while (low <= high) {
        const mid = (low + high) / 2;
        setFontSize(element, mid);
        const width = element.scrollWidth;
        const height = element.scrollHeight;
        if (width <= targetWidth && height <= targetHeight) {
            bestFontSize = mid;
            low = mid + 1;
        } else {
            high = mid - 1;
        }
    }

    setFontSize(element, bestFontSize);
}

function setFontSize(element: HTMLElement, size: number) {
    element.style.fontSize = `${size}px`;
}

export default React.memo(ScaledText);
