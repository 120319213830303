import React from "react";
import classnames from "classnames";
import * as format from "../../../core/format";

import "./LivePrice.less";

const ANIMATION_TIMEOUT_SECONDS = 0.5;

interface LivePriceProps {
    price: number | string;
    delay?: number | undefined;
    className?: string;
}

interface LivePriceState {
    delta?: number;
    price: number | string;
}

class LivePrice extends React.Component<LivePriceProps, LivePriceState> {
    state = {
        delta: 0,
        price: 0,
    };

    shouldComponentUpdate(
        nextProps: LivePriceProps,
        nextState: LivePriceState,
    ) {
        const { price } = this.props;
        const { price: nextPrice } = nextProps;

        if (price !== nextPrice) return true;

        const { delta } = this.state;
        const { delta: nextDelta } = nextState;

        return delta !== nextDelta;
    }

    UNSAFE_componentWillMount() {
        const { price } = this.props;
        this.setState(() => ({ price }));
    }

    UNSAFE_componentWillUpdate(nextProps: LivePriceProps) {
        let { price } = this.props;
        let { price: nextPrice } = nextProps;

        if (typeof price === "string") price = parseFloat(price);
        if (typeof nextPrice === "string") nextPrice = parseFloat(nextPrice);

        if (isNaN(price) || isNaN(nextPrice)) return;
        if (nextPrice === price) return;

        const delta = nextPrice - price;
        const nextState = {
            delta,
            price: nextPrice,
        };

        if (!nextProps.delay) {
            this.setState(() => nextState);
        } else {
            setTimeout(() => {
                this.setState(() => nextState);
            }, nextProps.delay * 1000);
        }
    }

    render() {
        const { delta, price } = this.state;
        let { className } = this.props;

        className = classnames(className, {
            "live-price": delta !== 0,
        });

        return <div className={className}>{format.asMoney(price)}</div>;
    }

    componentDidUpdate() {
        const { delta } = this.state;

        if (isNaN(delta) || delta === 0) return;

        const { delay } = this.props;
        const removeTimeout = ANIMATION_TIMEOUT_SECONDS + (delay || 0);

        const removeDelta = () =>
            this.setState((prevState) => {
                return prevState.delta === delta ? { delta: 0 } : {};
            });

        setTimeout(removeDelta, removeTimeout * 1000);
    }
}

export default LivePrice;
