import React from "react";
import $ from "jquery";
import classnames from "classnames";
import { TweenLite, TimelineMax, Power0, Power3, Back } from "gsap";

import oldStyleComponent from "../../core/oldStyleComponent";
import PageInfo from "../../core/PageInfo";
import ScaledText from "../_shared/scaled-text";

import atcLogoRegular from "./images/ATC_logo_regular.png";
import atcLogoCarnival from "./images/ATC_logo_carnival.png";
//import Layer2 from './images/Layer2.png';
import horse_portrait from "./images/horse_portrait.png";
import Layer3 from "./images/Layer3.png";
import coolmoreRegular from "./images/coolmore_regular.png";
import coolmoreCarnival from "./images/coolmore_carnival.png";

import "./WinningOwner.less";

interface WinningOwnerState {
    winningOwners: {
        horseName: string;
        owners: string;
        coolmoreSire: string | null;
    }[];
    isCarnival: boolean;
    pageInfo: PageInfo;
}

interface WinningOwnerProps {
    horseName: string;
    owners: { __html: string } | null;
    isCarnival: boolean;
    ownersCharCount: number;
    coolmoreSire: string | null;
}

class WinningOwnerComponent extends React.Component<WinningOwnerProps> {
    container: Element | null = null;

    onEntering = () => {
        const $layer1 = $(".layer-1", this.container!);
        const $layer2 = $(".layer-2", this.container!);
        const $layer3 = $(".layer-3", this.container!);
        const $layer4 = $(".layer-4", this.container!);
        const $content = $(".content", this.container!);

        const tl = new TimelineMax();

        tl.from($layer1, 0.4, { ease: Power0.easeIn, opacity: 0 }, 0.1);

        tl.from(
            $layer2,
            0.5,
            { ease: Power3.easeIn, opacity: 0, left: 150 },
            0.5,
        );
        tl.from(
            $layer3,
            0.8,
            { ease: Power3.easeIn, opacity: 0, right: -285 },
            0.5,
        ); // Layer3 ends up at -135px;
        tl.from(
            $layer4,
            0.3,
            { ease: Back.easeOut.config(2), top: -100, opacity: 0 },
            1.3,
        );

        tl.from($content, 0.36, { ease: Power3.easeOut, opacity: 0 }, 1.5);
    };

    onExiting = () => {
        const $container = $(this.container!);
        TweenLite.to($container, 0.7, {
            ease: Power0.easeOut,
            opacity: 0,
            delay: 0.3,
        });
    };

    render() {
        const { horseName, owners, isCarnival, ownersCharCount } = this.props;

        const layer1ClassNames = classnames("layer-1", {
            bg_carnival: isCarnival,
            bg_regular: !isCarnival,
        });

        const ownersClassNames = classnames({
            large: ownersCharCount < 100,
            medium: ownersCharCount >= 100 && ownersCharCount < 150,
            small: ownersCharCount >= 150,
        });

        const atcLogo = isCarnival ? atcLogoCarnival : atcLogoRegular;
        const coolmoreLogo = isCarnival ? coolmoreCarnival : coolmoreRegular;

        return (
            <div
                className="ff_winning-owner"
                ref={(container) => (this.container = container)}
            >
                <div className={layer1ClassNames}>
                    <div className="layer-2">
                        <img src={horse_portrait} alt="Horsies" />
                    </div>
                    <div className="layer-3">
                        <img src={Layer3} alt="Gradient" />
                    </div>
                    <div className="layer-4">
                        <img src={atcLogo} alt="Australian Turf Club" />
                    </div>

                    <div className="content">
                        <h3 className="congratulations">Congratulations to</h3>
                        <ScaledText
                            className="horse-name"
                            targetWidth={1250}
                            targetHeight={190}
                        >
                            {horseName}
                        </ScaledText>
                        {owners && (
                            <div>
                                <h3 className="subtitle">
                                    And the winning connections
                                </h3>
                                <h4
                                    className={`owner-names ${ownersClassNames}`}
                                    dangerouslySetInnerHTML={owners}
                                />
                            </div>
                        )}

                        {this.props.coolmoreSire && (
                            <div className="coolmore">
                                <div className="coolmore-sire">
                                    By{" "}
                                    <span className="sire-name">
                                        {this.props.coolmoreSire}
                                    </span>
                                </div>
                                <img
                                    className="coolmore-logo"
                                    src={coolmoreLogo}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const sanitizeWinningOwners = (owners: string) => {
    if (!owners) return null;

    // Try and clean up the owners text a bit, and stop weird line-breaks from happening
    owners = owners.replace(/&\s*$/, "");
    owners = owners.replace(
        /([A-Z0-9])\s/gi,
        (_, beforeMatch) => `${beforeMatch}&nbsp;`,
    );

    return owners;
};

const initialState = {
    winningOwners: [{ horseName: "", owners: "", coolmoreSire: null }],
    isCarnival: false,
    pageInfo: new PageInfo(),
};

const mapStateToProps = (state: WinningOwnerState) => {
    const { winningOwners, isCarnival, pageInfo } = state;

    // we are assuming harmony sends us a sensible pageInfo
    const { horseName, owners, coolmoreSire } =
        winningOwners[pageInfo.currentPage]!;
    const ownersHtml = sanitizeWinningOwners(owners);

    return {
        horseName,
        ownersCharCount: (owners && owners.length) || 0,
        owners: ownersHtml ? { __html: ownersHtml } : null,
        isCarnival,
        coolmoreSire,
    };
};

const WinningOwner = oldStyleComponent(
    "RacingAustralia/ff_winningOwner",
    initialState,
    mapStateToProps,
    WinningOwnerComponent,
    { timeout: { enter: 3000, exit: 1000 } },
);

const VenueWinningOwner = oldStyleComponent(
    "AtcWinningOwner/ff_venueWinningOwner",
    initialState,
    mapStateToProps,
    WinningOwnerComponent,
    { timeout: { enter: 3000, exit: 1000 } },
);

export { WinningOwner, VenueWinningOwner };

export default WinningOwner;
