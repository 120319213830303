import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./RunningClock.module.css";

type RaceState = "Scheduled" | "Armed" | "Running" | "Finished";

type RunningClockState = {
    time: number;
    timeLastUpdatedAt: number;
    state: RaceState;
};

const defaultState: RunningClockState = {
    time: 0,
    timeLastUpdatedAt: 0,
    state: "Scheduled",
};

export default function RunningClock() {
    const state = useTruckComponent("TripleS/lf_runningClock", defaultState);
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.runningClock} ref={ref}>
            <RaceTime {...data} />
        </div>
    );
}

function RaceTime(props: RunningClockState) {
    switch (props.state) {
        case "Armed":
            return <>0.0</>;
        case "Finished":
            return <>{formatTime(props.time, 2)}</>;
        case "Running":
            return <>{formatTime(props.time, 1)}</>;
        default:
            return null;
    }
}
