import classNames from "classnames";

import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useTruckComponent from "truck-core/hooks/useTruckComponent";

import RaceTitle from "./RaceTitle";
import Clock from "./Clock";
import TrackMapAndRunningDistance from "./TrackMapAndRunningDistance";
import Sponsor from "./Sponsor";
import ChicletMap from "./ChicletMap";
import RunningStages from "./RunningStages";
import JockeyPositions from "./JockeyPositions";

import css from "./index.module.css";
import Watermark from "truck-module-common-timing/Watermark";

export default function HRVTiming() {
    return (
        <div className={css.hrvTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="QldHorseRacing" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RaceTitle />
            </TruckErrorBoundary>
            <TimingBox />
            <TruckErrorBoundary>
                <RunningStages />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <ChicletMap prefix="QldHorseRacing" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <JockeyPositions />
            </TruckErrorBoundary>
        </div>
    );
}

function TimingBox() {
    let className = css.timingBox0;
    const sponsor = useTruckComponent("QldHorseRacing/lf_timingSponsor", {});
    const clock = useTruckComponent("QldHorseRacing/lf_runningClock", {});
    if (clock.visible) {
        className = css.timingBox1;
    }
    const trackMap = useTruckComponent("QldHorseRacing/lf_trackMap", {});
    if (trackMap.visible) {
        className = css.timingBox2;
    }
    const runningDistance = useTruckComponent(
        "QldHorseRacing/lf_runningDistance",
        {},
    );
    if (runningDistance.visible) {
        className = css.timingBox2;
    }

    return (
        <div className={css.timingBoxContainer}>
            <div className={classNames(css.timingBox, className)}>
                <div className={css.mapAndDistance}>
                    <TruckErrorBoundary>
                        <TrackMapAndRunningDistance prefix="QldHorseRacing" />
                    </TruckErrorBoundary>
                </div>
                <div className={css.clock}>
                    <TruckErrorBoundary>
                        <Clock
                            prefix="QldHorseRacing"
                            visible={clock.visible}
                        />
                    </TruckErrorBoundary>
                </div>
            </div>
            <div className={css.sponsor}>
                <TruckErrorBoundary>
                    <Sponsor
                        prefix="QldHorseRacing"
                        visible={sponsor.visible}
                    />
                </TruckErrorBoundary>
            </div>
        </div>
    );
}
