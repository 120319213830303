import React from "react";

export interface RaceClassConditionsProps {
    value: string;
}

class RaceClassConditions extends React.Component<RaceClassConditionsProps> {
    render() {
        const { value } = this.props;
        if (!value) return null;

        const groupStringMatch = /(?:group|.*-GP?)(\d)$/i.exec(value);
        if (groupStringMatch?.[1]) {
            const groupNumber = parseInt(groupStringMatch[1], 10);

            return ` (G${groupNumber})`;
        }

        return null;
    }
}

export default RaceClassConditions;
