import useTruckComponent from "truck-core/hooks/useTruckComponent";

import TrackMap, { Map } from "truck-module-common-timing/TrackMap";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./MapAndClock.module.css";
import useAnimation from "truck-core/hooks/useAnimation";

type TrackMapState = {
    distanceToFinish: number;
    raceLength: number;
    map: Map;
};

const trackMapDefaultState: TrackMapState = {
    distanceToFinish: 0,
    raceLength: 1,
    map: {
        trackPath: "",
        racePaths: [],
    },
};

type RaceState = "Scheduled" | "Armed" | "Running" | "Finished";

type ClockState = {
    time: number;
    state: RaceState;
};

const clockDefaultState: ClockState = {
    time: 0,
    state: "Scheduled",
};

export default function MapAndClock() {
    const { visible: mapVisibleComponent, data: trackMap } = useTruckComponent(
        "SkyHarness/lf_trackMap",
        trackMapDefaultState,
    );

    const { visible: clockVisible, data: clockData } = useTruckComponent(
        "SkyHarness/lf_runningClock",
        clockDefaultState,
    );

    const { visible: mapVisible, ref: mapAnimationRef } = useAnimation(
        mapVisibleComponent,
        {
            enter: css.enter,
            exit: css.exit,
        },
    );

    if (!trackMap.map.trackPath || !trackMap.map.racePaths) {
        return null;
    }

    if (!mapVisible && !clockVisible) {
        return null;
    }

    return (
        <div className={css.mapAndDistance} ref={mapAnimationRef}>
            <TrackMap
                distanceToFinish={trackMap.distanceToFinish}
                raceLength={trackMap.raceLength}
                map={trackMap.map}
                padding={{ left: 20, top: 10, right: 20, bottom: 10 }}
                trackPathStrokeWidth={8}
                trackPathClassName={css.track}
                racePathStrokeWidth={8}
                racePathClassName={css.horse}
                racePathLapClassNames={[
                    css.lap1,
                    css.lap2,
                    css.lap3,
                    css.lap4,
                    css.lap5,
                    css.lap6,
                ]}
                textBox={<Clock time={clockData.time} visible={clockVisible} />}
                positionMarker={<PositionMarker />}
            />
        </div>
    );
}

function Clock(props: { time: number; visible: boolean | undefined }) {
    const { time } = props;
    return (
        <text
            className={css.clockText}
            dominantBaseline="central"
            // visibility={visible ? "visible" : "hidden"}
        >
            {formatTime(time)}
        </text>
    );
}

function PositionMarker() {
    return <circle className={css.positionMarker} r="7px" />;
}
