import ATCBackground from "./FixedBackgroundATC.png";
import Redcliffe from "./redcliffe.png";

export type ImageTypes = "ATC" | "Redcliffe";

const Images: Record<ImageTypes, string> = {
    ATC: ATCBackground,
    Redcliffe: Redcliffe,
};

export default Images;
