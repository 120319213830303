import { TruckEvent, TruckEventHandler } from "../events";

export default class HarmonyClient {
    private isRunning: boolean;
    private client: WebSocket | null = null;
    private reconnectInterval = 500;
    private reconnectTimeout: number | null = null;

    private instances: Map<string, { id: string; visible: boolean }> =
        new Map();

    // opaque data sent by harmony
    private harmonyDetails: unknown;

    constructor(
        private readonly eventHandler: TruckEventHandler,
        private readonly harmonyUri: string,
        private readonly sessionToken: string,
    ) {
        this.isRunning = true;
        this.reconnect();
    }

    disconnect() {
        if (this.client?.readyState == 1) {
            this.send("close");
        }

        if (this.reconnectTimeout) {
            window.clearTimeout(this.reconnectTimeout);
            this.reconnectTimeout = null;
        }

        this.isRunning = false;
        this.client && this.client.close();
        this.client = null;
    }

    private send(messageType: string, data?: unknown) {
        if (!this.client || this.client.readyState != 1) {
            throw new Error("Socket is not connected.");
        }

        const message = JSON.stringify({ msg: messageType, data });
        this.client.send(message);
    }

    private reconnect() {
        const uri = `${this.harmonyUri}/truck?x-session-token=${this.sessionToken}`;
        try {
            this.client = new WebSocket(uri, "json");

            this.client.onmessage = ({ data }) => this.handleMessage(data);
            this.client.onopen = () => this.handleOpen();
            this.client.onclose = () => this.handleClose();
        } catch (error) {
            this.handleClose();
        }
    }

    private dispatchEvent(event: TruckEvent) {
        this.eventHandler?.handleEvent(event);
    }

    private messageHandlers = {
        ping: (data: unknown) => {
            this.send("pong", data);
        },
        pong: (timestamp: number) => {
            const latency = Date.now() - timestamp;
            this.send("latency", latency);
        },
        details: (details: unknown) => {
            this.harmonyDetails = details;
        },
        requestDetails: () => {
            this.send("details", this.harmonyDetails);
        },
        stop: () => {
            this.send("log", "Closing due to request");
            this.disconnect();
        },
        enableModule: ({ moduleId }: { moduleId: string }) => {
            this.dispatchEvent?.({ type: "enableModule", id: moduleId });
        },
        showComponent: ({
            moduleId,
            componentId,
            componentInstanceId,
            componentData,
        }: {
            moduleId: string;
            componentId: string;
            componentInstanceId: string;
            componentData: unknown;
        }) => {
            // for simplicity we combine the module and component IDs into a single string
            const id = `${moduleId}/${componentId}`;

            // hide existing instance
            const instance = this.instances.get(id);
            if (instance?.visible && instance.id != componentInstanceId) {
                this.dispatchEvent?.({ type: "hideComponent", id });
                this.send("componentHidden", {
                    componentInstanceId: instance.id,
                });
            }

            this.instances.set(id, {
                id: componentInstanceId,
                visible: true,
            });

            if (componentData !== undefined) {
                this.dispatchEvent?.({
                    type: "updateComponent",
                    id,
                    data: componentData,
                });
                this.send("componentUpdated", { componentInstanceId });
            }

            this.dispatchEvent?.({ type: "showComponent", id });
            this.send("componentVisible", { componentInstanceId });
        },
        hideComponent: ({
            moduleId,
            componentId,
            componentInstanceId,
        }: {
            moduleId: string;
            componentId: string;
            componentInstanceId: string;
        }) => {
            const id = `${moduleId}/${componentId}`;
            this.dispatchEvent?.({ type: "hideComponent", id });
            this.send("componentHidden", { componentInstanceId });
        },
        updateComponentData: ({
            moduleId,
            componentId,
            componentInstanceId,
            componentData,
        }: {
            moduleId: string;
            componentId: string;
            componentInstanceId: string;
            componentData: unknown;
        }) => {
            // for simplicity we combine the module and component IDs into a single string
            const id = `${moduleId}/${componentId}`;

            // hide existing instance
            const instance = this.instances.get(id);
            if (instance?.visible && instance.id != componentInstanceId) {
                this.dispatchEvent?.({ type: "hideComponent", id });
                this.send("componentHidden", {
                    componentInstanceId: instance.id,
                });
            }

            this.instances.set(id, {
                id: componentInstanceId,
                visible:
                    instance?.id == componentInstanceId
                        ? instance.visible
                        : false,
            });

            this.dispatchEvent?.({
                type: "updateComponent",
                id,
                data: componentData,
            });
            this.send("componentUpdated", { componentInstanceId });
        },
        hideAllComponents: () => {
            for (const [id, instance] of this.instances) {
                if (instance.visible) {
                    instance.visible = false;
                    this.dispatchEvent?.({ type: "hideComponent", id });
                    this.send("componentHidden", {
                        componentInstanceId: instance.id,
                    });
                }
            }
        },
        preload: ({
            moduleId,
            preloadData,
        }: {
            moduleId: string;
            preloadData: unknown;
        }) => {
            void moduleId;
            void preloadData;
            // we don't handle this at the moment
        },
    };

    private handleMessage(raw: string) {
        // TODO we should properly handle invalid message data
        const message = JSON.parse(raw);
        if (typeof message != "object" || typeof message?.msg != "string") {
            throw new Error(`Invalid message format`);
        }

        const handler = (this.messageHandlers as any)[message.msg];
        if (!handler) {
            throw new Error(`No handler for message type ${message.msg}`);
        }

        handler(message.data);
    }

    private handleOpen() {
        // reset exponential backoff interval on successful connection
        this.reconnectInterval = 500;

        this.send("ready", {
            browserVersion: navigator.userAgent,
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    private handleClose() {
        if (this.client) {
            this.client = null;
        }

        if (this.isRunning) {
            this.reconnectTimeout = window.setTimeout(() => {
                if (this.isRunning) {
                    this.reconnect();
                    this.reconnectTimeout = null;
                }
            }, this.reconnectInterval);

            // exponential backoff with maximum of 10 seconds
            this.reconnectInterval *= 1.5;
            this.reconnectInterval = Math.min(this.reconnectInterval, 10000);
        }
    }
}
