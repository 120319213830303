import classNames from "classnames";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import ScaledText, { squeeze } from "truck-core/ScaledText";

import css from "./JockeyPositions.module.css";
import { formatPrice } from "truck-module-common-timing/formatPrice";

type Position = {
    silk: string;
    name: string;
    number: string;
    totePrice: number | null;
    horseId: string;
};

interface JockeyPositionsState {
    positions: Position[];
    firstHorseOnRight: boolean;
}

const defaultState: JockeyPositionsState = {
    positions: [],
    firstHorseOnRight: false,
};

export default function JockeyPositions() {
    const state = useTruckComponent("MRC/lf_jockeyPositions", defaultState);
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div
            className={classNames(css.jockeyPositions, {
                [css.reverse!]: state.data.firstHorseOnRight,
            })}
            ref={ref}
        >
            {state.data.positions.map((p, i) => (
                // The index is used as a key for useFlipTransition. Since we
                // want the horses to transition when the display direction
                // changes, we need to have the index depend on
                // firstHorseOnRight.
                <JockeyPosition
                    key={p.horseId}
                    index={state.data.firstHorseOnRight ? -i - 1 : i}
                    {...p}
                />
            ))}
        </div>
    );
}

function JockeyPosition(props: Position & { index: number }) {
    const ref = useFlipTransition(props.index);
    return (
        <div className={css.jockeyPosition} ref={ref}>
            <img className={css.silk} src={props.silk} />
            <div className={css.number}>{props.number}</div>
            {props.totePrice && (
                <div className={css.price}>
                    ${formatPrice(props.totePrice, true)}
                </div>
            )}
            <div className={css.name}>
                <ScaledText resize={squeeze}>{props.name}</ScaledText>
            </div>
        </div>
    );
}
