import { ReactNode } from "react";

type SliceRegion = {
    x: number;
    y: number;
    width: number;
    height: number;
    className?: string | undefined;
};

type SlicedViewportProps = {
    children: ReactNode | ReactNode[];
    regions: SliceRegion[];
};

export default function SlicedViewport(props: SlicedViewportProps) {
    return (
        <>
            {props.regions.map((r, i) => (
                <Slice key={i} region={r}>
                    {props.children}
                </Slice>
            ))}
        </>
    );
}

type SliceProps = {
    children: React.ReactNode | React.ReactNode[];
    region: SliceRegion;
};

function Slice(props: SliceProps) {
    const { region, children } = props;
    return (
        <div
            className={region.className}
            style={{
                overflow: "hidden",
                width: region.width,
                height: region.height,
            }}
        >
            <div
                style={{
                    position: "relative",
                    left: -region.x,
                    top: -region.y,
                }}
            >
                {children}
            </div>
        </div>
    );
}
