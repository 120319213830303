import React from "react";

import oldStyleComponent from "../../core/oldStyleComponent";
import LowerFrameAnimatedWithImageBox from "../_animation/lf_animatedWithImageBox/LowerFrameAnimatedWithImageBox";

import "./LateScratching.styles.less";

interface LateScratchingProps {
    horseNumber: number;
    horseName: string;
    silk: string;
}

class LateScratching extends React.Component<LateScratchingProps> {
    animator: LowerFrameAnimatedWithImageBox | null = null;

    onEntering = () => this.animator!.onEntering();
    onExiting = () => this.animator!.onExiting();

    render() {
        const { horseNumber, horseName, silk } = this.props;

        return (
            <LowerFrameAnimatedWithImageBox
                ref={(animator) => (this.animator = animator)}
                className="lf_late-scratching"
                image={<img src={silk} />}
            >
                <div className="two-line">
                    <div className="text">Late Scratching</div>
                    <div className="title">
                        {horseNumber} {horseName}
                    </div>
                </div>
            </LowerFrameAnimatedWithImageBox>
        );
    }
}

const mapStateToProps = (state: LateScratchingProps) => {
    const { horseNumber, horseName, silk } = state;

    return {
        horseNumber,
        horseName,
        silk,
    };
};

export default oldStyleComponent(
    "RacingAustralia/lf_lateScratching",
    {} as LateScratchingProps,
    mapStateToProps,
    LateScratching,
    1500,
);
