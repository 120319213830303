import React from "react";
import $ from "jquery";
import { TimelineMax, Power3, Back } from "gsap";
import { Transition } from "react-transition-group";

import "./LowerFrameAnimatedWithImageBox.styles.less";

const ANIMATION_TIMEOUT = { enter: 3000, exit: 3000 };

interface LowerFrameAnimatedWithImageBoxProps {
    image: string | JSX.Element;
    className: string;
    in?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

class LowerFrameAnimatedWithImageBox extends React.Component<LowerFrameAnimatedWithImageBoxProps> {
    container: HTMLDivElement | null = null;

    onEntering = () => {
        const $image = $(".image-box", this.container!);
        const $textContent = $(".text-container", this.container!);

        const tl = new TimelineMax();
        tl.fromTo(
            $image,
            0.45,
            { ease: Back.easeOut.config(1.3), x: -500, scale: 1 },
            { ease: Back.easeOut.config(1.3), x: 0, scale: 1.5 },
            0.5,
        );
        tl.to($image, 0.35, { ease: Power3.easeOut, x: 0, scale: 1 }, 0.8);

        tl.fromTo(
            $textContent,
            0.35,
            { ease: Power3.easeOut, x: -110, opacity: 0 },
            { ease: Power3.easeOut, x: 0, opacity: 1 },
            0.8,
        );
    };

    onExiting = () => {
        const $image = $(".image-box", this.container!);
        const $textContent = $(".text-container", this.container!);

        const tl = new TimelineMax();
        tl.to(
            $textContent,
            0.35,
            { ease: Back.easeOut.config(1.3), x: -110, opacity: 0 },
            0.25,
        );
        tl.fromTo(
            $image,
            0.35,
            { ease: Power3.easeIn, scale: 1 },
            { ease: Power3.easeIn, scale: 1.5 },
            0.25,
        );
        tl.to(
            $image,
            0.45,
            { ease: Back.easeIn.config(1.3), x: -500, scale: 1 },
            0.55,
        );
    };

    render() {
        const { image, className, children } = this.props;

        let containerClasses = "lf_animated-with-image-box-container";
        if (className) containerClasses += ` ${className}`;

        return (
            <Transition
                in={this.props.in}
                timeout={ANIMATION_TIMEOUT}
                onEntering={this.onEntering}
                onExiting={this.onExiting}
            >
                <div
                    className={containerClasses}
                    ref={(container) => (this.container = container)}
                >
                    <div className="image-box">{image}</div>
                    <div className="text-container">{children}</div>
                </div>
            </Transition>
        );
    }
}

export default LowerFrameAnimatedWithImageBox;
