import React from "react";
import { TimelineLite, Power4 } from "gsap";

import oldStyleComponent from "../../core/oldStyleComponent";

import "./PhotoAllPlacings.styles.less";

class PhotoAllPlacings extends React.Component<void> {
    components: {
        textContainer: HTMLDivElement | null;
        text: HTMLDivElement | null;
    } = {
        textContainer: null,
        text: null,
    };
    timeline: TimelineLite | null = null;

    onEntering = () => {
        const { textContainer, text } = this.components;

        const tl = (this.timeline = new TimelineLite());
        tl.fromTo(
            textContainer,
            0.35,
            {
                ease: Power4.easeOut,
                x: -20,
                opacity: 0,
            },
            {
                ease: Power4.easeOut,
                x: 0,
                opacity: 1,
            },
            0.85,
        );

        tl.fromTo(
            text,
            0.35,
            {
                ease: Power4.easeIn,
                x: -40,
                opacity: 0,
            },
            {
                ease: Power4.easeIn,
                x: 0,
                opacity: 1,
            },
            0.9,
        );
    };

    onExiting = () => {
        this.timeline!.reverse();
    };

    render() {
        return (
            <div className="lf_photo_all_placings">
                <div
                    className="text-container"
                    ref={(textContainer) =>
                        (this.components.textContainer = textContainer)
                    }
                >
                    <div ref={(text) => (this.components.text = text)}>
                        <div className="text">Photo all placings</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default oldStyleComponent(
    "RacingAustralia/lf_photoAllPlacings",
    {},
    () => {},
    PhotoAllPlacings,
    1200,
);
