import React from "react";

import oldStyleComponent from "../../core/oldStyleComponent";
import FullFrameAnimated, {
    FF_ANIMATED_EXIT_TIMEOUT_SECONDS,
} from "../_animation/ff_animated/FullFrameAnimated";

import PageInfo from "../../core/PageInfo";
import SponsorTile from "../_shared/SponsorTile";
import TimeToRace from "../_shared/TimeToRace";
import TotesTable from "./components/TotesTable";

import "./Totes.styles.less";
import classNames from "classnames";
import { BettingStatus, Race, TotesRunner, TotesComponentState } from "./types";

interface TotesProps {
    race: Race;
    runners: TotesRunner[];
    numberOfPlaces: number;
    autoScroll: boolean;
    useMarketOrder: boolean;
    showOnlyWinPrice: boolean;
    pageNumber: number;
    logo: string;
    meetingName: string;
}

class Totes extends React.Component<TotesProps> {
    backdrop: FullFrameAnimated | null = null;
    animator: FullFrameAnimated | null = null;

    onEntering = () => {
        if (this.backdrop) this.backdrop.onEntering();
        this.animator!.onEntering();
    };

    onExiting = () => {
        if (this.backdrop) this.backdrop.onExiting();
        this.animator!.onExiting();
    };

    render() {
        const {
            race,
            runners,
            numberOfPlaces,
            autoScroll,
            useMarketOrder,
            pageNumber,
            showOnlyWinPrice,
            logo,
            meetingName,
        } = this.props;
        const { raceName, raceNumber, distance, startTime } = race;

        return (
            <FullFrameAnimated
                ref={(animator) => (this.animator = animator)}
                raceNumber={raceNumber}
                raceName={raceName}
                meetingName={meetingName}
                distance={distance}
                meetingMaxWidth={670}
                meetingMaxHeight={83}
                meetingInitialFontSize={63}
                logo={<SponsorTile src={logo} />}
                status={
                    <TimeToRace
                        startTime={startTime}
                        render={this.renderTimeToRace}
                    />
                }
            >
                <TotesTable
                    key="horses"
                    runners={runners}
                    useMarketOrder={useMarketOrder}
                    numberOfPlaces={numberOfPlaces}
                    autoScroll={autoScroll}
                    pageNumber={pageNumber}
                    showOnlyWinPrice={showOnlyWinPrice}
                />
            </FullFrameAnimated>
        );
    }

    renderTimeToRace = (minutes: number, seconds: number) => {
        // Check minutes <= 1 so the change occurs on transition from 2:00 to 1:59
        const timeToRaceCssClasses = classNames("time-to-race", {
            "t-2minutes": minutes <= 1,
        });
        const sign = seconds < 0 || minutes < 0 ? "-" : "";

        return (
            <div className={timeToRaceCssClasses}>
                <span className="time-value">
                    {sign}
                    {Math.abs(minutes)}:
                    {Math.abs(seconds).toString().padStart(2, "0")}
                </span>
            </div>
        );
    };
}

const initialState: TotesComponentState = {
    raceNumber: 0, // 1,
    raceName: "", // "Pantsdown all in classic plate"
    raceClassConditions: "", // "3Y+MSW"
    distanceMetres: "", // 1800
    startTime: "", // Date
    useMarketOrder: false, // True to show horses in market order instead of running order
    numberOfPlaces: 3,
    meetingName: "",

    // horse {
    //     horseName: string,
    //     horseNumber: number,
    //     gateNumber: number,
    //     odds {
    //         returnWin: number,
    //         returnPlace: number,
    //         isFavouriteWin: boolean,
    //         isFavouritePlace: boolean,
    //         bettingStatus: Unknown, Open, Closed, Scratched, Winner, Placing, Loser
    //     }
    // }
    runners: [],

    pageInfo: new PageInfo(),
} as any; // TODO proper initial state

function isScratched(runner: TotesRunner) {
    return (
        runner.odds.bettingStatus === BettingStatus.SCRATCHED ||
        runner.odds.bettingStatus === BettingStatus.LATE_SCRATCHED
    );
}

const mapStateToProps = (state: TotesComponentState) => {
    const race = {
        raceName: state.raceName,
        raceNumber: state.raceNumber,
        distance: `${state.distanceMetres}m`,
        startTime: state.startTime,
    };

    const {
        runners,
        numberOfPlaces,
        pageInfo,
        autoScroll,
        useMarketOrder,
        showOnlyWinPrice,
        logo,
        meetingName,
    } = state;

    if (state.useMarketOrder) {
        runners.sort((r1, r2) => {
            if (isScratched(r1)) {
                if (isScratched(r2)) {
                    return r1.horseNumber - r2.horseNumber;
                } else {
                    return 1;
                }
            } else {
                if (isScratched(r2)) {
                    return -1;
                } else {
                    return r1.odds.returnWin - r2.odds.returnWin;
                }
            }
        });
    }

    return {
        race,
        runners,
        numberOfPlaces,
        autoScroll,
        useMarketOrder,
        logo,
        meetingName,
        pageNumber: pageInfo.currentPage,
        showOnlyWinPrice,
    };
};

export default oldStyleComponent(
    "RacingAustralia/ff_totes",
    initialState,
    mapStateToProps,
    Totes,
    { timeout: { enter: 3000, exit: FF_ANIMATED_EXIT_TIMEOUT_SECONDS * 1500 } },
);

// TODO: This is kind of hacky but redcliffe is supposed to get new styles soon
export const RedcliffeTotes = oldStyleComponent(
    "RedcliffeInternalScreens/ff_totes",
    initialState,
    mapStateToProps,
    Totes,
    { timeout: { enter: 3000, exit: FF_ANIMATED_EXIT_TIMEOUT_SECONDS * 1500 } },
);
