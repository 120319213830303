import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import css from "./RaceTitle.module.css";

type RaceTitleState = {
    venueName: string;
    raceName: string;
    raceNumber: number;
    raceLength: number;
};

const defaultState: RaceTitleState = {
    venueName: "",
    raceName: "",
    raceNumber: -1,
    raceLength: 0,
};

export default function RaceTitle() {
    const state = useTruckComponent(
        `QldHorseRacing/lf_raceTitle`,
        defaultState,
    );

    const { data } = state;

    const raceNumber = useAnimation(state.visible, {
        enter: css.raceNumberEnter,
        exit: css.raceNumberExit,
    });
    const raceInfo = useAnimation(state.visible, {
        enter: css.raceInfoEnter,
        exit: css.raceInfoExit,
    });

    const visible = raceNumber.visible || raceInfo.visible;
    if (!visible) {
        return null;
    }

    return (
        <div className={css.raceTitleContainer}>
            <div className={css.raceNumber} ref={raceNumber.ref}>
                {data.raceNumber}
            </div>
            <div className={css.raceInfo} ref={raceInfo.ref}>
                <div className={css.raceVenueAndLength}>
                    <div className={css.raceVenue}>{data.venueName}</div>
                    <div className={css.raceLength}>{data.raceLength}m</div>
                </div>
                <div className={css.raceName}>{data.raceName}</div>
            </div>
        </div>
    );
}
