import React from "react";
import $ from "jquery";
import { TimelineMax, Power0 } from "gsap";
import ApprenticeClaim from "../../_shared/apprentice-claim";

import ANIMATION_TIMES from "../AnimationTimes";

import { PlaceData } from "../types";

interface PlacingProps {
    place: number;
    numberOfPlaces: number;
    runners: PlaceData[];
    trainerLeftOffset: string;
    scrollingDistance: number;
}

export default class Placing extends React.PureComponent<PlacingProps> {
    runnerContainers: (HTMLElement | null)[] = [];
    jockeyTrainerContainers: (HTMLElement | null)[] = [];
    prevJockeyTrainer: [string | undefined, string | undefined] = [
        this.props.runners[0]?.jockeyName,
        this.props.runners[0]?.trainerName,
    ];
    prevHasDeadHeat: boolean = this.hasDeadHeat();

    timeline: TimelineMax | null = null;

    hasDeadHeat() {
        return this.props.place === 3 && this.runnerContainers.length > 1;
    }

    componentDidMount() {
        // Delay the element measuring by 1.5 seconds so we measure the final sizes after the entering transitions are complete
        setTimeout(() => this.startAnimations(), 1500);
    }

    componentDidUpdate() {
        const hasChanged =
            this.props.runners[0]?.jockeyName !== this.prevJockeyTrainer[0] ||
            this.props.runners[0]?.trainerName !== this.prevJockeyTrainer[1] ||
            this.prevHasDeadHeat !== this.hasDeadHeat();

        if (!hasChanged) {
            return;
        }

        this.prevJockeyTrainer = [
            this.props.runners[0]?.jockeyName,
            this.props.runners[0]?.trainerName,
        ];
        this.prevHasDeadHeat = this.hasDeadHeat();

        if (this.timeline) {
            this.timeline.seek(this.timeline.duration());
            this.timeline.kill();
        }
        this.startAnimations();
    }

    componentWillUnmount() {
        this.timeline && this.timeline.kill();
    }

    render() {
        const { place, numberOfPlaces, runners } = this.props;
        const css = `row place-${place}`;
        // We have to reset the containers on each render otherwise
        // duplicates of the divs get added to the collections and
        // that results in incorrect changes to the animations.
        this.runnerContainers = [];
        this.jockeyTrainerContainers = [];
        return runners.map((runner) => (
            <div
                className={css}
                key={runner.horseNumber}
                ref={(container) =>
                    container !== null &&
                    !this.runnerContainers.includes(container)
                        ? this.runnerContainers.push(container)
                        : null
                }
            >
                <div className="horse-number">{runner.horseNumber}</div>
                {this.renderNameJockeyAndTrainer(runner)}
                {this.renderWinAmount(runner)}
                {runner.horseName !== "Photo" && (
                    <div className="place-amount">
                        {this.getPlaceAmount(
                            runner.placeAmount,
                            place,
                            numberOfPlaces,
                        )}
                    </div>
                )}
            </div>
        ));
    }

    renderNameJockeyAndTrainer(runner: PlaceData) {
        const components: JSX.Element[] = [];
        components.push(
            <span key={runner.horseName} className="horse-name">
                {runner.horseName}
            </span>,
        );

        const trainerName = this.selectTrainerName(
            runner.trainerName,
            runner.trainerNameShort,
        );

        if (runner.place === 1) {
            components.push(
                <div
                    key={runner.jockeyName}
                    className="jockey-trainer"
                    ref={(container) =>
                        !this.jockeyTrainerContainers.includes(container)
                            ? this.jockeyTrainerContainers.push(container)
                            : null
                    }
                >
                    <span className="jockey" key={runner.jockeyName}>
                        J: {runner.jockeyName}{" "}
                        <ApprenticeClaim amount={runner.claimAmount} />
                    </span>
                    <span className="trainer" key={trainerName}>
                        T: {trainerName}
                    </span>
                </div>,
            );
        }

        return <div className="horse-jockey-trainer">{[...components]}</div>;
    }

    selectTrainerName = (trainerName: string, trainerNameShort: string) => {
        if (!trainerNameShort) return trainerName;

        return trainerName.length > 27 || /[&,]/.test(trainerName)
            ? trainerNameShort
            : trainerName;
    };

    renderWinAmount(placing: PlaceData) {
        const winAmount = placing.place === 1 ? placing.winAmount : " ";
        return <div className="win-amount">{winAmount}</div>;
    }

    getPlaceAmount(
        placeAmount: string | undefined,
        placeNumber: number,
        numberOfPlaces: number,
    ) {
        if (numberOfPlaces === 0) return "NPP";

        // If 3 (or more?) places, just show the amount
        if (numberOfPlaces > 2) return placeAmount;

        // If only 2 places, show the amount for the first two horses, and 'NTD' for third
        if (numberOfPlaces === 2) {
            return placeNumber === 3 ? "NTD" : placeAmount;
        }

        // If only 1 place, show 'NPP' for 2nd and 3rd horses
        return placeNumber === 1 ? placeAmount : "NPP";
    }

    startAnimations() {
        this.animateJockeyAndTrainer();
        this.animateThirdPlaceDeadHeat();
    }

    animateJockeyAndTrainer() {
        const { scrollingDistance, trainerLeftOffset } = this.props;

        const { TRANSITION_TIME, TRANSITION_WAIT_TIME } =
            ANIMATION_TIMES.PLACINGS;

        const tl = (this.timeline = new TimelineMax({ repeat: -1 }));
        let waitTime = 0;

        this.jockeyTrainerContainers.forEach((jockeyTrainerContainer) => {
            const $jockeyTrainerContainer = $(jockeyTrainerContainer!);
            const $jockey = $(".jockey", $jockeyTrainerContainer);
            const $trainer = $(".trainer", $jockeyTrainerContainer);

            // Perform element measurements to determine if scrolling is required
            const $row = $jockeyTrainerContainer.closest(".row");
            const trainerOffset = parseFloat(
                trainerLeftOffset.match(/^\d*/g)?.[0] ?? "0",
            );

            // Ensure there is at least a 30px gap between jockey and trainer
            const maxJockeyWidth =
                trainerOffset -
                ($(".horse-number", $row || undefined).outerWidth()! + 30);
            const winColumn = $(".win-amount", $row);
            const placeColumn = $(".place-amount", $row);
            const maxTrainerWidth =
                $(".mask").width()! -
                (winColumn.outerWidth()! +
                    placeColumn.outerWidth()! +
                    trainerOffset);

            if (
                $jockey.width()! >= maxJockeyWidth ||
                $trainer.width()! >= maxTrainerWidth
            ) {
                $jockey.css({ display: "block" });
                $trainer.css({ opacity: 0 });

                waitTime += TRANSITION_WAIT_TIME;
                tl.to(
                    $jockey,
                    TRANSITION_TIME,
                    {
                        ease: Power0.easeNone,
                        y: -scrollingDistance,
                        opacity: 0,
                    },
                    waitTime,
                );
                tl.to(
                    $trainer,
                    TRANSITION_TIME,
                    { ease: Power0.easeOut, y: -scrollingDistance, opacity: 1 },
                    waitTime,
                );

                waitTime += TRANSITION_WAIT_TIME;
                tl.to(
                    $jockey,
                    TRANSITION_TIME,
                    { ease: Power0.easeNone, y: 0, opacity: 1 },
                    waitTime,
                );
                tl.to(
                    $trainer,
                    TRANSITION_TIME,
                    { ease: Power0.easeOut, y: 0, opacity: 0 },
                    waitTime,
                );
            } else {
                // PastThePost component is smaller than the Dividends component so they must display the trainer at different positions
                $trainer.css({ left: trainerLeftOffset, position: "absolute" });
            }
        });
    }

    animateThirdPlaceDeadHeat() {
        if (!this.hasDeadHeat()) return;

        const { TRANSITION_TIME, TRANSITION_WAIT_TIME } =
            ANIMATION_TIMES.PLACINGS;

        const tl = (this.timeline = new TimelineMax({ repeat: -1 }));
        let waitTime = 0;

        // This is only catering for a two-place dead heat; if we have more than two horses at the same place then
        // everything falls to shit.
        const $container0 = $(this.runnerContainers[0]!);
        const $container1 = $(this.runnerContainers[1]!);
        const scrollingDistance = $container0.height();

        $container0.css({ "padding-bottom": 0 });
        $container1.css({ opacity: 0 });

        waitTime += TRANSITION_WAIT_TIME;
        tl.to(
            $container0,
            TRANSITION_TIME,
            { ease: Power0.easeNone, y: -(scrollingDistance || 0), opacity: 0 },
            waitTime,
        );
        tl.to(
            $container1,
            TRANSITION_TIME,
            { ease: Power0.easeOut, y: -(scrollingDistance || 0), opacity: 1 },
            waitTime,
        );

        waitTime += TRANSITION_WAIT_TIME;
        tl.to(
            $container0,
            TRANSITION_TIME,
            { ease: Power0.easeNone, y: 0, opacity: 1 },
            waitTime,
        );
        tl.to(
            $container1,
            TRANSITION_TIME,
            { ease: Power0.easeOut, y: 0, opacity: 0 },
            waitTime,
        );
    }
}
