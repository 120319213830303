import { useEffect, useRef, useState } from "react";

import Venkman from "./";

export type VenkmanConfig = {
    harmonyUrl: string;
    licenceKey: string;
    identity: string;
    description: string;
};

export type VenkmanState = {
    sessionToken: string | undefined;
};

export function useVenkman(config: VenkmanConfig | undefined) {
    const venkman = useRef<Venkman>();
    const [state, setState] = useState<VenkmanState>({
        sessionToken: undefined,
    });

    useEffect(() => {
        if (venkman.current) {
            venkman.current.disconnect();
        }

        if (config) {
            venkman.current = new Venkman({
                ...config,
                onOpenTruck: (sessionToken) => setState({ sessionToken }),
            });
        }

        return () => {
            if (venkman.current) {
                venkman.current.disconnect();
                venkman.current = undefined;
            }
        };
    }, [config]);

    return state;
}
