import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLongArrowAltUp,
    faCloudShowersHeavy,
    faTemperatureHigh,
    faWind,
    faTint,
} from "@fortawesome/free-solid-svg-icons";

import cn from "classnames";

import css from "./Weather.module.css";

type WeatherState = {
    rainfall: number;
    windSpeed: number;
    windDirection: number;
    windDirectionCardinal: string;
    airTemperature: number;
    airHumidity: number;
    venueName?: string | null;
    trackRating?: number | null;
    trackRatingText?: string | null;
    railPosition?: string | null;
};

// TODO: We probably don't want these defaults...
const defaultWeatherState: WeatherState = {
    rainfall: 0,
    windSpeed: 0,
    windDirection: 0,
    windDirectionCardinal: "?",
    airTemperature: 0,
    airHumidity: 0,
};

export default function Weather(props: { modulePrefix: string }) {
    const { data, visible } = useTruckComponent(
        `${props.modulePrefix}/lf_weather`,
        defaultWeatherState,
    );

    const { ref: animationRef } = useAnimation(visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.weather} ref={animationRef}>
            <div className={css.venue}>{data.venueName}</div>
            <div className={css.weatherDetails}>
                <WeatherDetails data={data} />
            </div>
            {data.trackRating && (
                <div className={css.trackInformation}>
                    <div className={css.trackRating}>
                        Track: {data.trackRatingText} {data.trackRating}
                    </div>
                    {data.railPosition && (
                        <div className={css.railPosition}>
                            Rail: {data.railPosition}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

function WeatherDetails(props: { data: WeatherState }) {
    const {
        rainfall,
        airTemperature,
        windSpeed,
        windDirection,
        airHumidity,
        windDirectionCardinal,
        trackRatingText,
    } = props.data;

    const windSpeedKmh = windSpeed * 3.6;

    // Range of wind direction is -180~180. Convert this into 0~360.
    const windDirectionPositive = (windDirection + 360) % 360;

    return (
        <>
            <IconAndText
                icon={<FontAwesomeIcon icon={faCloudShowersHeavy} />}
                text={`${rainfall} mm`}
            />
            <IconAndText
                icon={<FontAwesomeIcon icon={faTemperatureHigh} />}
                text={`${airTemperature.toFixed(2)} °C`}
            />
            <IconAndText
                icon={<FontAwesomeIcon icon={faWind} />}
                text={`${windSpeedKmh.toFixed(2)} km/H`}
            />
            <IconAndText
                icon={<WindCompass windDirection={windDirection} />}
                text={`${Math.round(
                    windDirectionPositive,
                )}° ${windDirectionCardinal}`}
                classes={css.wind}
            />
            <IconAndText
                icon={<FontAwesomeIcon icon={faTint} />}
                text={`${airHumidity.toFixed(1)}%`}
            />
            <IconAndText
                icon={<div className={css.trackOvalIcon} />}
                text={trackRatingText ?? ""}
            />
        </>
    );
}

function WindCompass(props: { windDirection: number }) {
    const { windDirection } = props;

    // The direction that is reported by the sensor is flipped to the direction
    // of the arrow we need to show.
    const windDirectionFlipped = windDirection - 180;

    return (
        <div className={css.compass}>
            <div className={css.compassBackground}>
                <FontAwesomeIcon
                    icon={faLongArrowAltUp}
                    className={css.compassArrow ?? ""}
                    style={{
                        transform: `translate(-50%, -50%) rotate(${windDirectionFlipped}deg)`,
                    }}
                />
            </div>
        </div>
    );
}

function IconAndText(props: {
    icon: JSX.Element;
    text: string;
    classes?: string | undefined;
}) {
    return (
        <div className={cn(css.weatherItem, props.classes)}>
            <div className={css.icon}>{props.icon}</div>
            <div className={css.text}>{props.text}</div>
        </div>
    );
}
