import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import saddleCloths from "truck-module-sky-timing/assets/saddle-cloths";

import css from "./TopSpeed.module.css";

type TopSpeedState = {
    horseNumber: number;
    currentSpeed: number;
    leadTime?: number;
    leadTimeHorse?: number;
};

const defaultState: TopSpeedState = {
    horseNumber: 0,
    currentSpeed: 0.0,
};

export default function TopSpeed() {
    const data = useTruckComponent("SkyHarness/lf_topSpeed", defaultState);

    const { visible, ref: animationRef } = useAnimation(data.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    const speed = (data.data.currentSpeed * 3.6).toFixed(2);

    return (
        <div className={css.topSpeed} ref={animationRef}>
            <img
                src={
                    saddleCloths[
                        data.data.horseNumber as keyof typeof saddleCloths
                    ]
                }
                className={css.chiclet}
            />
            <div>TOP SPEED</div>
            <div>
                {speed} <span className={css.kmh}>KM/H</span>
            </div>
        </div>
    );
}
