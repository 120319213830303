import TruckErrorBoundary from "truck-core/TruckErrorBoundary";

import ClockAndDistance from "./ClockAndDistance";
import JockeyPositions from "./JockeyPositions";
import OverallPositions from "./OverallPositions";
import Sectionals from "./Sectionals";

import css from "./index.module.css";
import Watermark from "truck-module-common-timing/Watermark";

export default function ATCTiming() {
    return (
        <div className={css.mrcTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="MRC" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <ClockAndDistance />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Sectionals />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <JockeyPositions />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <OverallPositions />
            </TruckErrorBoundary>
        </div>
    );
}
