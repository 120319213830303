import useTruckComponent from "truck-core/hooks/useTruckComponent";
import formatTime from "truck-module-common-timing/formatTime";
import useAnimation from "truck-core/hooks/useAnimation";

import UpDownCaret, { ComparedToAverage } from "./UpDownCaret";

import css from "./MileRate.module.css";

type MileRateState = {
    mileRate: number;
    comparedToAverage?: ComparedToAverage;
};

const defaultState: MileRateState = {
    mileRate: 0,
};

export default function MileRate() {
    const state = useTruckComponent("SkyHarness/lf_mileRate", defaultState);

    const { data } = state;

    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible || !data.mileRate) {
        return null;
    }

    return (
        <div className={css.mileRate} ref={animationRef}>
            <div className={css.name}>MILE RATE</div>
            <div className={css.time}>
                <UpDownCaret compared={data.comparedToAverage} />
                {formatTime(data.mileRate, 2)}
            </div>
        </div>
    );
}
