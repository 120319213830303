import React from "react";

import LowerFrameAnimatedWithImageBox from "../_animation/lf_animatedWithImageBox/LowerFrameAnimatedWithImageBox";

import "./CommentatorPick.styles.less";
import oldStyleComponent from "../../core/oldStyleComponent";

interface CommentatorPickProps {
    silk: string;
    title: string;
    // TODO: Use a typescript enum for titles
    horseNumber: number;
    horseName: string;
    gateNumber: string;
    number: string;
}

class CommentatorPick extends React.Component<CommentatorPickProps> {
    animator: LowerFrameAnimatedWithImageBox | null = null;

    onEntering = () => this.animator!.onEntering();
    onExiting = () => this.animator!.onExiting();

    render() {
        const { silk, title, horseNumber, horseName, gateNumber } = this.props;

        return (
            <LowerFrameAnimatedWithImageBox
                ref={(animator) => (this.animator = animator)}
                className="lf_commentator-pick"
                image={<img src={silk} />}
            >
                <div className="two-line">
                    <div className="horse-pick">
                        {horseNumber}. {horseName}{" "}
                        <span className="gate_commentator-pick">
                            ({gateNumber})
                        </span>
                    </div>
                    <div className="text">{title}</div>
                </div>
            </LowerFrameAnimatedWithImageBox>
        );
    }
}

const mapStateToProps = (state: CommentatorPickProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_commentatorPick",
    {} as CommentatorPickProps,
    mapStateToProps,
    CommentatorPick,
    { timeout: { enter: 3000, exit: 3000 } },
);
