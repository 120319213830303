import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import { SponsorType } from "truck-module-common-timing/types";

import css from "./TimingSponsor.module.css";

type SponsorState = {
    url?: string;
    type?: SponsorType;
};

const defaultState: SponsorState = {
    url: "",
};

export default function TimingSponsor() {
    const state = useTruckComponent("TripleS/lf_timingSponsor", defaultState);
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible || !state.data.url || !state.data.type) {
        return null;
    }

    return state.data.type === SponsorType.Image ? (
        <img className={css.timingSponsor} src={state.data.url} ref={ref} />
    ) : (
        <video
            className={css.timingSponsor}
            src={state.data.url}
            ref={ref}
            autoPlay
            muted
        />
    );
}
