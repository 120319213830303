import { useEffect, useContext, useState } from "react";

import { TruckPlayerContext, ComponentState } from "../player/react";

type TruckComponentState<T> = {
    visible: boolean;
    data: T;
};

export default function useTruckComponent<T>(id: string, defaultState: T) {
    const harmony = useContext(TruckPlayerContext);
    const [state, setState] = useState<TruckComponentState<T>>(() => ({
        visible: false,
        timestamp: 0,
        data: defaultState,
    }));

    useEffect(() => {
        function update(newState: ComponentState<T>) {
            setState({
                visible: newState.visible,
                data: newState.data,
            });
        }

        const initialState = harmony.subscribeToComponent(id, update);
        if (initialState !== undefined) {
            update(initialState);
        }

        return () => {
            harmony.unsubscribeFromComponent(id, update);
        };
    }, [harmony, id]);

    return state;
}
