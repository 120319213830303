import React from "react";
import classNames from "classnames";

import AnimatedScrollTable from "../../_animation/animated-scroll-table/AnimatedScrollTable";
import LivePrice from "../../_shared/live-price/LivePrice";
import * as format from "../../../core/format";

import { BettingStatus, TotesRunner } from "../types";

interface TotesTableProps {
    runners: TotesRunner[];
    numberOfPlaces: number;
    useMarketOrder: boolean;
    autoScroll: boolean;
    pageNumber: number;
    showOnlyWinPrice: boolean;
}

const RUNNERS_PER_PAGE = 10;

class TotesTable extends React.Component<TotesTableProps> {
    render() {
        const {
            runners,
            numberOfPlaces,
            useMarketOrder,
            autoScroll,
            showOnlyWinPrice,
            pageNumber,
        } = this.props;

        const startAtRowNumber =
            pageNumber > 0
                ? Math.min(
                      pageNumber * RUNNERS_PER_PAGE - 1,
                      runners.length - RUNNERS_PER_PAGE,
                  )
                : 0;

        // If there's only 1 place (i.e. a winner only), then we don't show the place column; in these instances,
        // we use the 'place-amount' style for the win column, simply because it has the right padding for being
        // the last column on the component.
        const showPlacingPrices = !showOnlyWinPrice && numberOfPlaces > 1;

        const winClassNames = classNames({
            "win-amount": numberOfPlaces > 1,
            "place-amount": numberOfPlaces <= 1,
            "only-win-amount": !showPlacingPrices,
        });

        const winHeaderClassNames = classNames(winClassNames, {
            npp: numberOfPlaces <= 1,
        });

        return (
            <AnimatedScrollTable
                className="totes-table"
                pageRowCount={RUNNERS_PER_PAGE}
                totalRowCount={runners.length}
                autoScroll={autoScroll}
                startAtRowNumber={startAtRowNumber}
            >
                <AnimatedScrollTable.Header className="totes-table-header">
                    <div className="gate-number">&nbsp;</div>
                    <div className="horse-display-order">
                        {this.renderOrderHeader(useMarketOrder)}
                    </div>
                    <div className={winHeaderClassNames}>Win</div>
                    {showPlacingPrices && (
                        <div className="place-amount">Place</div>
                    )}
                </AnimatedScrollTable.Header>

                <AnimatedScrollTable.Body className="totes-table-runners">
                    {runners.map((runner) =>
                        renderRunner(runner, winClassNames, showPlacingPrices),
                    )}
                </AnimatedScrollTable.Body>
            </AnimatedScrollTable>
        );
    }

    renderOrderHeader = (useMarketOrder: boolean) => {
        if (useMarketOrder) return "Market Order";
        return <span>&nbsp;</span>;
    };
}

function renderRunner(
    runner: TotesRunner,
    winClassNames: string,
    showPlacingPrices: boolean,
) {
    const { odds } = runner;
    const hasScratched =
        odds.bettingStatus === BettingStatus.SCRATCHED ||
        odds.bettingStatus === BettingStatus.LATE_SCRATCHED;

    const rowClasses = classNames("totes-table-row", {
        "late-scratching": hasScratched,
    });

    let returnPlace = format.asMoney(odds.returnPlace);
    let returnWin = format.asMoney(odds.returnWin);
    if (odds.bettingStatus === BettingStatus.LATE_SCRATCHED) {
        returnWin = returnPlace = "L SCR";
    }

    if (odds.bettingStatus === BettingStatus.SCRATCHED) {
        returnWin = returnPlace = "SCR";
    }

    return (
        <AnimatedScrollTable.Row
            key={runner.horseNumber}
            className={rowClasses}
        >
            <div className="gate-number">{runner.horseNumber}</div>
            <div className="horse-name">
                {runner.horseName} <span>({runner.gateNumber})</span>
            </div>
            <LivePrice
                key={`win_${runner.horseNumber}`}
                className={winClassNames}
                price={returnWin}
            />
            {showPlacingPrices && (
                <LivePrice
                    key={`place_${runner.horseNumber}`}
                    className="place-amount"
                    price={returnPlace}
                />
            )}
        </AnimatedScrollTable.Row>
    );
}

export default TotesTable;
