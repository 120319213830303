import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import TrackMap, { Map } from "truck-module-common-timing/TrackMap";

import css from "./TrackMapAndDistance.module.css";
import fade from "./fade.module.css";

type TrackMapState = {
    distanceToFinish: number;
    raceLength: number;
    map: Map;
};

const defaultTrackMapState: TrackMapState = {
    distanceToFinish: 0,
    raceLength: 1,
    map: {
        trackPath: "",
        racePaths: [],
    },
};

interface RunningDistanceState {
    distanceToFinish: number;
    raceLength: number;
}

const defaultRunningDistanceState: RunningDistanceState = {
    distanceToFinish: 0,
    raceLength: 0,
};

export default function TrackMapAndDistance(props: {
    trackMapVisible: boolean;
    runningDistanceVisible: boolean;
}) {
    const { data: trackMapData } = useTruckComponent(
        "HRV/lf_trackMap",
        defaultTrackMapState,
    );
    const { data: runningDistanceData } = useTruckComponent(
        "HRV/lf_runningDistance",
        defaultRunningDistanceState,
    );

    const { visible: trackMapVisible, ref: trackMapRef } = useAnimation(
        props.trackMapVisible,
        {
            enter: fade.enter,
            exit: fade.exit,
        },
    );

    const { visible: runningDistanceVisible, ref: runningDistanceRef } =
        useAnimation(props.runningDistanceVisible, {
            enter: fade.enter,
            exit: fade.exit,
        });

    if (!trackMapVisible) {
        return null;
    }

    if (!trackMapData.map.trackPath || !trackMapData.map.racePaths) {
        return null;
    }

    // Round to 50m
    const runningDistance = Math.min(
        Math.ceil(runningDistanceData.distanceToFinish / 50) * 50,
        runningDistanceData.raceLength,
    );

    return (
        <div
            className={css.trackMap}
            ref={(el) => {
                if (el) {
                    trackMapRef(el);
                    runningDistanceRef(el);
                }
            }}
        >
            <TrackMap
                distanceToFinish={trackMapData.distanceToFinish}
                raceLength={trackMapData.raceLength}
                map={trackMapData.map}
                padding={{ left: 10, top: 10, right: 10, bottom: 10 }}
                trackPathClassName={css.track}
                trackPathStrokeWidth={3}
                racePathClassName={css.horse}
                racePathStrokeWidth={3}
                textBox={
                    <RunningDistance
                        runningDistance={runningDistance}
                        visible={runningDistanceVisible}
                    />
                }
                positionMarker={<PositionMarker />}
            />
        </div>
    );
}

function RunningDistance(props: {
    runningDistance: number;
    visible: boolean | undefined;
}) {
    const { runningDistance, visible } = props;

    return (
        <text
            className={css.runningDistanceText}
            dominantBaseline="central"
            visibility={visible ? "visible" : "hidden"}
        >
            {runningDistance}m
        </text>
    );
}

function PositionMarker() {
    return <circle className={css.positionMarker} r="7px" />;
}
