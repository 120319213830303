import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./Sectionals.module.css";

type SectionalData = {
    sectionNumber: number;
    startDistance: number;
    endDistance: number;
    time: number;
};

type SectionalsState = {
    stages: SectionalData[];
};

const defaultState: SectionalsState = {
    stages: [],
};

const MAX_SECTIONALS = 2;

export default function Sectionals(props: { modulePrefix: string }) {
    const state = useTruckComponent(
        `${props.modulePrefix}/lf_runningStages`,
        defaultState,
    );

    const data = state.data;

    const { visible, ref: animationRef } = useAnimation(state.visible, {
        exit: css.sectionalsExit,
    });

    // Add 1 to MAX_SECTIONALS so we can animate the an exiting sectional
    const numSectionals = Math.min(MAX_SECTIONALS + 1, data.stages.length);
    const flipRef = useFlipTransition(numSectionals);

    if (!visible) {
        return null;
    }

    return (
        <div
            className={css.sectionals}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            {data.stages.slice(-numSectionals).map((data, i) => (
                <Sectional
                    key={data.sectionNumber}
                    index={numSectionals > MAX_SECTIONALS ? i - 1 : i}
                    exiting={i == 0 && numSectionals > MAX_SECTIONALS}
                    animateEntrance={numSectionals > MAX_SECTIONALS}
                    {...data}
                />
            ))}
        </div>
    );
}

function Sectional(
    props: SectionalData & {
        index: number;
        exiting: boolean;
        animateEntrance: boolean;
    },
) {
    const flipRef = useFlipTransition(props.index);

    const { visible, ref: animationRef } = useAnimation(!props.exiting, {
        enter: props.animateEntrance ? css.sectionalEnter : "",
        exit: css.sectionalExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div
            className={css.sectional}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            <div className={css.interval}>
                {props.sectionNumber == 0
                    ? `${props.endDistance}m`
                    : `${props.startDistance}m - ${props.endDistance}m`}
            </div>
            <div className={css.time}>{formatTime(props.time, 2)}</div>
        </div>
    );
}
