import TruckRecording from "truck-core/player/drivers/recording";

type Props = {
    recording: TruckRecording;
};

export default function RecordingControls(props: Props) {
    const { recording } = props;
    return (
        <div>
            <button onClick={() => recording.pause()}>Pause</button>
            <button onClick={() => recording.play()}>Play</button>
            <button onClick={() => recording.setPosition(0)}>Reset</button>
        </div>
    );
}
