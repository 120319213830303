import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import css from "./RunningDistance.module.css";

interface RunningDistanceState {
    distanceToFinish: number;
}

const defaultState: RunningDistanceState = {
    distanceToFinish: 0,
};

export default function RunnningDistance() {
    const state = useTruckComponent("TripleS/lf_runningDistance", defaultState);
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.runningDistance} ref={ref}>
            <div className={css.distance}>{data.distanceToFinish}m</div>
        </div>
    );
}
