import React from "react";
import ReactDOMClient from "react-dom/client";

import App from "./App";

function getRecorderConfig(params: URLSearchParams) {
    const saveEndpoint = params.get("recordEndpoint");
    const name = params.get("recordName");

    if (saveEndpoint && name) {
        return {
            saveEndpoint,
            saveInterval: 10000,
            name,
        };
    }
}

function loadConfigFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);

    const harmonyUrl = searchParams.get("harmony") ?? undefined;

    const sessionToken = searchParams.get("x-session-token") ?? undefined;

    const licence = searchParams.get("licence") ?? undefined;
    const identity = searchParams.get("identity") ?? undefined;

    const recorder = getRecorderConfig(searchParams);

    const recording = searchParams.get("recording") ?? undefined;

    return { harmonyUrl, sessionToken, licence, identity, recorder, recording };
}

function main() {
    const el = document.getElementById("root");
    if (el === null) {
        return;
    }

    const root = ReactDOMClient.createRoot(el);

    root.render(
        <React.StrictMode>
            <App config={loadConfigFromUrl()} />
        </React.StrictMode>,
    );
}

main();
