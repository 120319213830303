import css from ".//UpDownCaret.module.css";

export type ComparedToAverage = ">" | "<" | "=";

export default function UpDownCaret(props: {
    compared: ComparedToAverage | undefined;
}) {
    switch (props.compared) {
        case ">":
            return <div className={css.caretUp} />;
        case "<":
            return <div className={css.caretDown} />;
        case "=":
        default:
            return <div className={css.caretEmpty} />;
    }
}
