function priceHasOneDp(price: number) {
    const cents = Math.round(price * 100);
    return cents % 10 == 0;
}

export function formatPrice(price: number, truncateTensZero = false) {
    if (price == 0) {
        return "0";
    }
    if (price < 10) {
        if (truncateTensZero && priceHasOneDp(price)) {
            return price.toFixed(1).toString();
        }
        return price.toFixed(2).toString();
    }

    return Math.round(price).toString();
}
