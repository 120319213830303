import cn from "classnames";
import AnimatedScrollTable from "truck-module-horse-racing/components/_animation/animated-scroll-table/AnimatedScrollTable";
import TimeToRace from "truck-module-horse-racing/components/_shared/TimeToRace";
import useAnimation from "truck-core/hooks/useAnimation";
import {
    getOfficialPriceHeader,
    JockeyState,
    Runner,
    formatPrice,
} from "./RaceTable";

import common from "./RaceTable.module.css";
import css from "./PreRaceTable.module.css";
import { useEffect, useRef, useState } from "react";

export default function PreRaceTable(props: {
    data: JockeyState;
    visible: boolean;
}) {
    const {
        data: {
            venueName,
            raceNumber,
            raceTime,
            runners,
            jurisdiction,
            venueColor,
            isPastThePost,
        },
    } = props;

    const { visible, ref: animationRef } = useAnimation(props.visible, {
        enter: common.abaTableEnter,
        exit: common.abaTableExit,
    });

    if (!visible) return null;

    // https://www.punters.com.au/faq/what-are-market-percentages/
    const marketPercentage = runners.reduce(
        (prev, runner) =>
            prev +
            (!runner.isScratched && runner.officialPrice
                ? 100 / runner.officialPrice
                : 0),
        0,
    );

    return (
        <div
            ref={animationRef}
            className={cn(common.abaTable, css.preRaceTable)}
        >
            <AnimatedScrollTable
                className={cn(css.preRaceTableContainer)}
                pageRowCount={12}
                totalRowCount={runners.length}
                autoScroll={runners.length > 12}
                startAtRowNumber={0}
                key={`race-${raceNumber}`}
            >
                <AnimatedScrollTable.Header
                    className={css.abaTableHeader ?? ""}
                >
                    <div
                        className={common.venueInfo}
                        style={{
                            backgroundColor: venueColor ?? "var(--dark-grey)",
                        }}
                    >
                        <TimeToRace
                            startTime={raceTime}
                            render={renderTimeToRace}
                        />
                        <div className={common.venueNameAndRace}>
                            {venueName}&nbsp;R{raceNumber}
                        </div>
                        <div className={common.atcPrice}>ATC PRICE</div>
                    </div>
                    <div className={cn(common.bodyHeaders, css.bodyHeaders)}>
                        <div className={css.runnersHeader}>Runners</div>
                        <div className={css.flucsHeader}>
                            ATC Price Fluctuations
                        </div>
                        <div className={css.opHeader}>
                            {getOfficialPriceHeader(jurisdiction)}
                        </div>
                        <div className={css.tabHeader}>
                            <div className={css.tabTopHeader}>TAB</div>
                            <div className={css.tabPriceHeader}>
                                <span>Win</span>
                                <span>PLC</span>
                            </div>
                        </div>
                    </div>
                </AnimatedScrollTable.Header>
                <AnimatedScrollTable.Body className={cn(css.abaTableRunners)}>
                    {runners.map((runner) => (
                        <AnimatedScrollTable.Row
                            className={cn(common.abaTableRunnerRow)}
                            key={`row-${runner.number}`}
                        >
                            <RunnerRow runner={runner} />
                        </AnimatedScrollTable.Row>
                    ))}
                </AnimatedScrollTable.Body>
            </AnimatedScrollTable>
            <div className={css.footer}>
                <div className={css.marketPercentage}>
                    ATC MARKET PERCENTAGE&nbsp;&nbsp;
                    {marketPercentage.toFixed(0)}%
                </div>
                {isPastThePost && (
                    <div className={css.pastThePost}>PAST THE POST</div>
                )}
            </div>
        </div>
    );
}

function renderTimeToRace(minutes: number) {
    return (
        <div className={css.timeToRace}>
            <div className={css.label}>MINS</div>
            <div className={css.mins}>{minutes}</div>
        </div>
    );
}

function LivePrice(props: { price: string; classNames: string | undefined }) {
    const { price, classNames: incomingClassNames } = props;

    const priceRef = useRef(price);
    const [animate, setAnimate] = useState(false);
    useEffect(() => {
        if (priceRef.current == price) {
            return;
        }
        priceRef.current = price;
        setAnimate(true);
        const t = setTimeout(() => {
            setAnimate(false);
        }, 5000);

        return () => {
            clearTimeout(t);
            setAnimate(false);
        };
    }, [price]);

    return (
        <div
            className={cn(incomingClassNames, {
                [css.newPriceEntered!]: animate,
            })}
        >
            {priceRef.current}
        </div>
    );
}

function RunnerRow(props: { runner: Runner }) {
    const { runner } = props;

    const flucs: (number | undefined)[] = [...runner.flucs]
        .reverse()
        .slice(0, 5)
        .reverse();
    const maxFluc = flucs.reduce(
        (prev, curr) => (curr! > prev! ? curr : prev),
        0,
    )!;
    if (flucs.length < 5) {
        for (let i = 0; i < 5 - runner.flucs.length; i++) flucs.push(undefined);
    }

    return (
        <>
            <div className={common.runner}>
                {runner.isScratched ? (
                    <b className={cn(cn(common.silk, css.silk), css.scratched)}>
                        SCR
                    </b>
                ) : (
                    <div className={cn(common.silk, css.silk)}>
                        <img src={runner.silk} />
                    </div>
                )}
                <div
                    className={cn(common.runnerNumber, {
                        [css.scratched!]: runner.isScratched,
                    })}
                >
                    {runner.number}
                </div>
                <div
                    className={cn(common.runnerName, {
                        [css.scratched!]: runner.isScratched,
                    })}
                >
                    {runner.name}
                </div>
            </div>
            {flucs.map((f, i) => (
                <LivePrice
                    key={`${runner.number}-fluc${i}`}
                    price={f?.toString() ?? ""}
                    classNames={cn(common.price, css.flucs, {
                        [css.maxFluxHighlight!]: f === maxFluc,
                    })}
                />
            ))}
            <LivePrice
                price={
                    !runner.isScratched
                        ? `${formatPrice(runner.officialPrice)}`
                        : ""
                }
                classNames={cn(common.price, css.op)}
                key={`${runner.number}-op`}
            />
            <LivePrice
                price={
                    !runner.isScratched ? `${formatPrice(runner.tabWin)}` : ""
                }
                classNames={common.price}
                key={`${runner.number}-win`}
            />
            <LivePrice
                price={
                    !runner.isScratched ? `${formatPrice(runner.tabPlace)}` : ""
                }
                classNames={common.price}
                key={`${runner.number}-place`}
            />
        </>
    );
}
