import { useCallback, useState } from "react";

export default function useSVGInvertedScaling() {
    const [scalingFactor, setScalingFactor] = useState<number>(1);
    const ref = useCallback((el: SVGGElement | null) => {
        if (el) {
            const ctm = el.getScreenCTM();
            if (ctm) {
                // this is assuming that the aspect ratio is maintained when
                // scaling, so the x and y coords are scaled by the same amount
                const scale = ctm.inverse().a;
                setScalingFactor(scale);
            } else {
                setScalingFactor(1);
            }
        }
    }, []);

    return { scalingFactor, ref };
}
