import useAnimation from "truck-core/hooks/useAnimation";
import {
    Deduction,
    getOfficialPriceHeader,
    JockeyState,
    Weight,
} from "./RaceTable";

import cn from "classnames";
import common from "./RaceTable.module.css";
import css from "./PostRaceTable.module.css";

export default function PostRaceTable(props: {
    data: JockeyState;
    visible: boolean;
}) {
    const {
        data: {
            venueName,
            raceNumber,
            weights,
            deductions,
            jurisdiction,
            venueColor,
        },
    } = props;

    const { visible, ref: animationRef } = useAnimation(props.visible, {
        enter: common.abaTableEnter,
        exit: common.abaTableExit,
    });

    if (!visible) return null;

    return (
        <div
            ref={animationRef}
            className={cn(common.abaTable, css.postRaceTable)}
        >
            <div
                className={cn(common.venueInfo, css.venueInfo)}
                style={{
                    backgroundColor: venueColor ?? "var(--dark-grey)",
                }}
            >
                <div className={common.venueNameAndRace}>
                    {venueName}&nbsp;R{raceNumber}
                </div>
                <div className={common.atcPrice}>ATC PRICE</div>
            </div>
            <div className={css.body}>
                <Weights weights={weights} jurisdiction={jurisdiction} />
                <Deductions deductions={deductions} />
            </div>
        </div>
    );
}

function Weights(props: { weights: Weight[] | null; jurisdiction: string }) {
    const { weights, jurisdiction } = props;

    if (!weights) return null;

    const officialPriceHeader = getOfficialPriceHeader(jurisdiction);

    return (
        <div className={css.weightsTable}>
            <div className={css.title}>CORRECT WEIGHT</div>
            <div className={common.bodyHeaders}>
                <div className={css.runnersHeader}>Runners</div>
                <div className={css.weightsOpHeader}>
                    {officialPriceHeader} TF
                </div>
                <div className={css.weightsOpHeader}>
                    {officialPriceHeader} SP
                </div>
                <div className={css.weightsTabHeader}>
                    <div className={css.weightsTabTopHeader}>
                        {jurisdiction} TAB
                    </div>
                    <div className={css.weightsTabPriceHeader}>
                        <span>Win</span>
                        <span>PLC</span>
                    </div>
                </div>
            </div>
            {weights.map((weight, i) => (
                <WeightRow weight={weight} key={`weight-row-${i}`} />
            ))}
        </div>
    );
}

function getPlacingAsString(place: number) {
    switch (place) {
        case 1:
            return "1ST";
        case 2:
            return "2ND";
        case 3:
            return "3RD";
        default:
            // We usually only have up to 4 places anyway
            return `${place}TH`;
    }
}

function WeightRow(props: { weight: Weight }) {
    const { weight } = props;

    const placing = Number.parseInt(weight.placing);

    return (
        <div className={common.abaTableRunnerRow}>
            <div className={common.runner}>
                <div className={css.placing}>{getPlacingAsString(placing)}</div>
                <div className={common.silk}>
                    <img src={weight.silk} />
                </div>
                <div className={common.runnerNumber}>{weight.number}</div>
                <div className={common.runnerName}>{weight.name}</div>
            </div>
            <div className={common.price}>
                {placing == 1 && weight.officialPriceTf
                    ? weight.officialPriceTf
                    : ""}
            </div>
            <div className={common.price}>
                {placing == 1 && weight.officialPriceSp
                    ? weight.officialPriceSp
                    : ""}
            </div>
            <div className={common.price}>
                {placing == 1 && weight.tabWin ? weight.tabWin : ""}
            </div>
            <div className={common.price}>
                {weight.tabPlace ? weight.tabPlace : ""}
            </div>
        </div>
    );
}

function Deductions(props: { deductions: Deduction[] | null }) {
    const { deductions } = props;

    if (!deductions) return null;

    return (
        <div className={css.deductionsTable}>
            <div className={css.title}>DEDUCTIONS</div>
            <div className={common.bodyHeaders}>
                <div className={css.runnersHeader}>Runners</div>
                <div className={css.deductionsFirstHeader}>
                    <div>1ST</div>
                    <div className={css.deductionsFirstTypeHeader}>
                        <span>WIN</span>
                        <span>PLC</span>
                    </div>
                </div>
                <div className={css.deductionsPlacing}>
                    <div className={css.deductionsPlacingTop}>2ND</div>
                    <div className={css.deductionsPlacingBottom}>PLC</div>
                </div>
                <div className={css.deductionsPlacing}>
                    <div className={css.deductionsPlacingTop}>3RD</div>
                    <div className={css.deductionsPlacingBottom}>PLC</div>
                </div>
                <div className={css.deductionsScratchedTimeHeader}>SCRTIME</div>
            </div>
            {deductions.map((deduction, i) => (
                <DeductionRow
                    deduction={deduction}
                    key={`deduction-row-${i}`}
                />
            ))}
        </div>
    );
}

function DeductionRow(props: { deduction: Deduction }) {
    const { deduction } = props;

    const scratchedTime = new Date(deduction.scratchedTime).toLocaleTimeString(
        [],
        { hour: "2-digit", minute: "2-digit", hour12: true },
    );

    return (
        <div className={common.abaTableRunnerRow}>
            <div className={common.runner}>
                <div className={common.silk}>
                    <img src={deduction.silk} />
                </div>
                <div className={common.runnerNumber}>{deduction.number}</div>
                <div className={common.runnerName}>{deduction.name}</div>
            </div>
            <div className={css.price}>{deduction.winPrice}</div>
            <div className={css.price}>{deduction.placePrice}</div>
            <div className={css.price}>{deduction.secondPlacePrice}</div>
            <div className={css.price}>{deduction.thirdPlacePrice}</div>
            <div className={css.scratchedTime}>{scratchedTime}</div>
        </div>
    );
}
