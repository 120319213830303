import { useCallback, useLayoutEffect, useRef } from "react";

export default function useFlipTransition(key: string | number) {
    const element = useRef<HTMLElement>();
    const prevPosition = useRef<{
        left: number;
        top: number;
        right: number;
        bottom: number;
        width: number;
        height: number;
    }>();
    const prevKey = useRef<string | number>(key);

    const ref = useCallback((el: HTMLElement | null) => {
        element.current = el ?? undefined;
    }, []);

    useLayoutEffect(() => {
        if (!element.current) {
            return;
        }

        const { left, top, right, bottom, width, height } =
            element.current.getBoundingClientRect();
        // get position relative to document
        const pos = {
            left: left + window.scrollX,
            top: top + window.scrollY,
            right: right + window.scrollX,
            bottom: bottom + window.scrollY,
            width,
            height,
        };

        if (prevKey.current == key || !prevPosition.current) {
            prevPosition.current = pos;
            prevKey.current = key;
            return;
        }

        const xOffset =
            prevPosition.current.right !== pos.right
                ? prevPosition.current.left - pos.left
                : 0;
        const yOffset =
            prevPosition.current.bottom !== pos.bottom
                ? prevPosition.current.top - pos.top
                : 0;
        const widthDelta = pos.width - prevPosition.current.width;
        const heightDelta = pos.height - prevPosition.current.height;

        const borderRadius = getBorderRadius(element.current);

        element.current.style.transition = "transform 0s, clip-path 0s";
        element.current.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
        if (heightDelta != 0 || widthDelta != 0) {
            element.current.style.clipPath = `inset(0 0 ${heightDelta}px ${widthDelta}px round ${borderRadius})`;
        }

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                if (element.current) {
                    element.current.style.transition = "";
                    element.current.style.transform = "";
                    if (heightDelta != 0 || widthDelta != 0) {
                        element.current.style.clipPath = `inset(0 0 0 0 round ${borderRadius})`;
                    }
                }
            });
        });

        prevPosition.current = pos;
        prevKey.current = key;
    });

    return ref;
}

function getBorderRadius(el: HTMLElement) {
    const tl = getComputedStyle(el).getPropertyValue("border-top-left-radius");
    const tr = getComputedStyle(el).getPropertyValue("border-top-right-radius");
    const bl = getComputedStyle(el).getPropertyValue(
        "border-bottom-left-radius",
    );
    const br = getComputedStyle(el).getPropertyValue(
        "border-bottom-right-radius",
    );
    return `${tl} ${tr} ${bl} ${br}`;
}
