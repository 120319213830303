/**
 * A consistent way of representing paging information.
 */
class PageInfo {
    readonly pageCount: number;
    readonly currentPage: number;

    constructor(pageCount: PageInfo | number = 1, currentPage = 0) {
        if (typeof pageCount === "object") {
            this.pageCount = pageCount.pageCount;
            this.currentPage = pageCount.currentPage;
        } else {
            this.pageCount = pageCount;
            this.currentPage = currentPage;
        }
    }

    next = () => {
        const nextIndex = (this.currentPage + 1) % this.pageCount;
        return new PageInfo(this.pageCount, nextIndex);
    };
}

export default PageInfo;
