import React from "react";

import "./ApprenticeClaim.less";

type Props = {
    amount: number | string;
};

const ApprenticeClaim = ({ amount }: Props) => {
    if (amount && amount >= 0) {
        return <span className="apprentice-claim">(a{amount})</span>;
    }

    return null;
};

export default ApprenticeClaim;
