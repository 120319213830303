import { useEffect, useRef, useState } from "react";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import mainLogo from "./assets/hrvtv.png";
import bell from "./assets/bell.svg";
import css from "./Sponsor.module.css";

type SponsorState = {
    url: string | undefined;
    isInLastLap: boolean;
    distanceToFinish: number;
    raceLength: number;
};

const defaultState: SponsorState = {
    url: undefined,
    isInLastLap: false,
    distanceToFinish: 0,
    raceLength: 0,
};

export default function Sponsor(props: { visible: boolean }) {
    const { data } = useTruckComponent("HRV/lf_timingSponsor", defaultState);

    const { visible, ref } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const isBell = useIsBell(data.isInLastLap);
    const isSponsor = !!(
        data.url && (data.raceLength - data.distanceToFinish) % 200 >= 100
    );

    const { visible: bellVisible, ref: bellRef } = useAnimation(isBell, {
        enter: css.bellEnter,
        exit: css.bellExit,
    });

    const { visible: sponsorVisible, ref: sponsorRef } = useAnimation(
        isSponsor && !isBell,
        {
            enter: css.sponsorEnter,
            exit: css.sponsorExit,
        },
    );

    const { visible: mainLogoVisible, ref: mainLogoRef } = useAnimation(
        !isSponsor,
        {
            enter: sponsorVisible ? css.mainLogoEnter : "",
            exit: css.mainLogoExit,
        },
    );

    if (!visible) {
        return null;
    }

    return (
        <div ref={ref} className={css.sponsor}>
            {!isBell && mainLogoVisible && (
                <img ref={mainLogoRef} src={mainLogo} />
            )}
            {!isBell && sponsorVisible && (
                <img ref={sponsorRef} src={data.url} />
            )}
            {bellVisible && <img ref={bellRef} src={bell} />}
        </div>
    );
}

const BELL_TIME = 5000;

function useIsBell(isInLastLap: boolean) {
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [prevIsInLastLap, setPrevIsInLastLap] = useState(isInLastLap);
    const [isBell, setIsBell] = useState(false);

    if (prevIsInLastLap != isInLastLap) {
        if (isInLastLap) {
            setIsBell(true);
        }
        setPrevIsInLastLap(isInLastLap);
    }

    useEffect(() => {
        if (isBell && timeout.current == null) {
            timeout.current = setTimeout(() => {
                setIsBell(false), (timeout.current = null);
            }, BELL_TIME);
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [isBell]);

    return isBell;
}
