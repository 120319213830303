const ANIMATION_TIMES = {
    PLACINGS: {
        // How quickly should we transition from one state to the next?
        TRANSITION_TIME: 0.4,

        // How long should we leave each state on screen for before transitioning again?
        TRANSITION_WAIT_TIME: 5,
    },

    EXOTICS: {},
};

export default ANIMATION_TIMES;
