import classNames from "classnames";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";

import css from "./OverallPositions.module.css";

type Position = {
    number: number;
    horseId: string;
};

interface OverallPositionsState {
    positions: Position[];
    firstHorseOnRight: boolean;
}

const defaultState: OverallPositionsState = {
    positions: [],
    firstHorseOnRight: false,
};

export default function JockeyPositions() {
    const state = useTruckComponent("MRC/lf_jockeyPositions", defaultState);
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div
            className={classNames(css.overallPositions, {
                [css.reverse!]: state.data.firstHorseOnRight,
            })}
            ref={ref}
        >
            {state.data.positions.map((p, i) => (
                // The index is used as a key for useFlipTransition. Since we
                // want the horses to transition when the display direction
                // changes, we need to have the index depend on
                // firstHorseOnRight.
                <HorseNumber
                    key={p.horseId}
                    index={state.data.firstHorseOnRight ? -i - 1 : i}
                    number={p.number}
                />
            ))}
        </div>
    );
}

function HorseNumber(props: { number: number; index: number }) {
    const ref = useFlipTransition(props.index);
    return (
        <div className={css.horseNumber} ref={ref}>
            {props.number}
        </div>
    );
}
