import { useCallback } from "react";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import BaseChicletMap from "truck-module-common-timing/ChicletMap";
import { RaceType } from "truck-module-common-timing/types";

import Chiclet from "./Chiclet";

import css from "./ChicletMap.module.css";
import fade from "./fade.module.css";

type ChicletHorse = {
    number: number;
    baseColour: string;
    textColour: string;
    distanceToFinish: number;
    distanceToRail: number;
};

type ChicletSettings = {
    xRange?: number | null;
    yRange?: number | null;
};

type ChicletMapState = {
    timestamp: number;
    horses: ChicletHorse[];
    raceType: RaceType;
    raceLength: number;
    raceDirection: "clockwise" | "anticlockwise";
    isInFinishStraight: boolean;
    settings: ChicletSettings;
};

const defaultState: ChicletMapState = {
    timestamp: 0,
    horses: [],
    raceType: RaceType.R,
    raceLength: 1,
    raceDirection: "clockwise",
    isInFinishStraight: false,
    settings: {
        xRange: null,
        yRange: null,
    },
};

export default function ChicletMap(props: { modulePrefix: string }) {
    const state = useTruckComponent(
        `${props.modulePrefix}/lf_chiclets`,
        defaultState,
    );
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: fade.enter,
        exit: fade.exit,
    });

    const { data } = state;

    const renderChiclet = useCallback(
        (horse: ChicletHorse) => (
            <Chiclet
                className={css.chiclet}
                raceType={data.raceType}
                number={horse.number}
                baseColour={horse.baseColour}
                textColour={horse.textColour}
            />
        ),
        [data.raceType],
    );

    const renderDistanceMarker = useCallback(
        (distance: number) => {
            const text =
                distance == data.raceLength
                    ? "FINISH"
                    : `${data.raceLength - distance}m`;
            return <div className={css.distanceMarker}>{text}</div>;
        },
        [data.raceLength],
    );

    if (!visible) {
        return null;
    }

    return (
        <div className={css.chicletMap} ref={animationRef}>
            <BaseChicletMap
                horses={data.horses}
                timestamp={data.timestamp}
                raceLength={data.raceLength}
                raceDirection={data.raceDirection}
                isInFinishStraight={data.isInFinishStraight}
                xRangeMetres={data.settings.xRange ?? 30}
                yRangeStdDevs={data.settings.yRange ?? 1.5}
                renderChiclet={renderChiclet}
                renderDistanceMarker={renderDistanceMarker}
            />
        </div>
    );
}
