import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import css from "./SingleImage.module.css";

interface SingleImageState {
    image: string;
}

const defaultState: SingleImageState = {
    image: "",
};

export default function SingleImageComponent() {
    const state = useTruckComponent(
        "RacingAustralia/ff_singleImage",
        defaultState,
    );
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const { data } = state;

    if (!visible) {
        return null;
    }

    return (
        <div className={css.singleImage} ref={animationRef}>
            <img src={data.image} />
        </div>
    );
}
