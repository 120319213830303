import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import RunningDistance from "./RunningDistance";
import RunningClock from "./RunningClock";
import RunningStages from "./RunningStages";
import TimingSponsor from "./TimingSponsor";
import TrackMap from "./TrackMap";
import JockeyPositions from "./JockeyPositions";

import css from "./index.module.css";
import Watermark from "truck-module-common-timing/Watermark";

function DistanceAndClock() {
    const { visible: distanceVisible } = useTruckComponent(
        "TripleS/lf_runningDistance",
        null,
    );
    const { visible: clockVisible } = useTruckComponent(
        "TripleS/lf_runningClock",
        null,
    );
    const { visible, ref } = useAnimation(distanceVisible || clockVisible, {
        enter: css.distanceAndClockEnter,
        exit: css.distanceAndClockExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.distanceAndClock} ref={ref}>
            <TruckErrorBoundary>
                <RunningDistance />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RunningClock />
            </TruckErrorBoundary>
        </div>
    );
}

export default function ATCTiming() {
    return (
        <div className={css.atcTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="TripleS" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <DistanceAndClock />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RunningStages />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <TimingSponsor />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <TrackMap />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <JockeyPositions />
            </TruckErrorBoundary>
        </div>
    );
}
