import { useState, useCallback, useLayoutEffect, useRef } from "react";
import { flushSync } from "react-dom";

export default function useAnimation(
    visible: boolean,
    options: {
        enter?: string | undefined;
        exit?: string | undefined;
    },
) {
    const result = useAnimationList([visible], options);
    return {
        ...result,
        visible: result.visible[0],
    };
}

enum AnimationState {
    HIDDEN,
    ENTERING,
    ENTERED,
    EXITING,
}

export function useAnimationList(
    visible: boolean[],
    {
        enter,
        exit,
    }: {
        enter?: string | undefined;
        exit?: string | undefined;
    },
) {
    const [animationVisible, setAnimationVisible] = useState(visible);
    const state = useRef(AnimationState.HIDDEN);

    const ref = useRef<HTMLElement | null>(null);
    const refCallback = useCallback((el: HTMLElement | null) => {
        ref.current = el;
    }, []);

    useLayoutEffect(() => {
        // we need to do this dance so we only update the state if any of the booleans in the array have actually changed
        const newAnimationVisible = animationVisible.map((a, i) =>
            a ? true : visible[i]!,
        );
        const animationVisibleChanged = animationVisible.some(
            (a, i) => a != newAnimationVisible[i],
        );
        if (animationVisibleChanged) {
            setAnimationVisible(newAnimationVisible);
        }

        const anyVisible = visible.reduce((acc, a) => acc || a, false);
        const anyAnimationVisible = animationVisible.reduce(
            (acc, a) => acc || a,
            false,
        );

        if (!ref.current) {
            return;
        }

        const el = ref.current;

        if (anyVisible) {
            if (!enter) {
                // remove stray exit animation
                if (exit) {
                    el.classList.remove(exit);
                }

                state.current = AnimationState.ENTERED;
                return;
            }

            const handleEnterAnimationEnd = (event: AnimationEvent) => {
                if (event.target == el) {
                    el.removeEventListener(
                        "animationend",
                        handleEnterAnimationEnd,
                    );
                    state.current = AnimationState.ENTERED;
                }
            };

            switch (state.current) {
                case AnimationState.ENTERING:
                    // recreate the event listener and we are done
                    el.addEventListener(
                        "animationend",
                        handleEnterAnimationEnd,
                    );
                    return () => {
                        el.removeEventListener(
                            "animationend",
                            handleEnterAnimationEnd,
                        );
                    };
                case AnimationState.ENTERED:
                    // we have already animated the entrance, we don't need to do anything
                    return;
                case AnimationState.HIDDEN:
                case AnimationState.EXITING:
                    // stop the exit animation so we can start the entrance
                    if (exit && el.classList.contains(exit)) {
                        el.classList.remove(exit);
                        // force reflow
                        el.offsetHeight;
                    }
                    break;
            }

            el.addEventListener("animationend", handleEnterAnimationEnd);
            el.classList.add(enter);
            state.current = AnimationState.ENTERING;

            return () => {
                el.removeEventListener("animationend", handleEnterAnimationEnd);
            };
        }

        if (!anyVisible && anyAnimationVisible) {
            if (!exit) {
                // remove stray entry animation
                if (enter) {
                    el.classList.remove(enter);
                }

                state.current = AnimationState.HIDDEN;
                flushSync(() => {
                    setAnimationVisible(animationVisible.map(() => false));
                });
                return;
            }

            const handleExitAnimationEnd = (event: AnimationEvent) => {
                if (event.target == el) {
                    el.removeEventListener(
                        "animationend",
                        handleExitAnimationEnd,
                    );
                    state.current = AnimationState.HIDDEN;
                    flushSync(() => {
                        setAnimationVisible(animationVisible.map(() => false));
                    });
                }
            };

            switch (state.current) {
                case AnimationState.HIDDEN:
                    // already hidden, nothing to do
                    return;
                case AnimationState.ENTERING:
                case AnimationState.ENTERED:
                    // stop the entry animation so we can start the exit
                    if (enter && el.classList.contains(enter)) {
                        el.classList.remove(enter);
                        // force reflow
                        el.offsetHeight;
                    }
                    break;
                case AnimationState.EXITING:
                    // recreate the event listener and we are done
                    el.addEventListener("animationend", handleExitAnimationEnd);
                    return () => {
                        el.removeEventListener(
                            "animationend",
                            handleExitAnimationEnd,
                        );
                    };
            }

            el.addEventListener("animationend", handleExitAnimationEnd);
            el.classList.add(exit);
            state.current = AnimationState.EXITING;

            return () => {
                el.removeEventListener("animationend", handleExitAnimationEnd);
            };
        }
    }, [animationVisible, visible, enter, exit]);

    return { visible: animationVisible, ref: refCallback };
}
