import React from "react";
import { TimelineLite, Back, Power4 } from "gsap";
import { TransitionGroup } from "react-transition-group";

import oldStyleComponent from "../../core/oldStyleComponent";
import SponsorTile from "../_shared/SponsorTile";

import DetailLine from "./DetailLine";
import "./Super.styles.less";

interface SuperProps {
    silk: string;
    title: string;
    text?: string;
    pageIndex?: number;
}

class Super extends React.Component<SuperProps> {
    components: {
        logo: HTMLDivElement | null;
        textContainer: HTMLDivElement | null;
        text: HTMLDivElement | null;
    } = {
        logo: null,
        textContainer: null,
        text: null,
    };
    timeline: TimelineLite | null = null;

    onEntering = () => {
        const { logo, textContainer, text } = this.components;

        const tl = (this.timeline = new TimelineLite());
        tl.from(
            logo,
            0.35,
            {
                ease: Back.easeOut.config(1.3),
                y: 250,
            },
            0.5,
        );

        tl.from(
            textContainer,
            0.35,
            {
                ease: Power4.easeOut,
                x: -20,
                opacity: 0,
            },
            0.85,
        );

        tl.from(
            text,
            0.35,
            {
                ease: Power4.easeIn,
                x: -40,
                opacity: 0,
            },
            0.9,
        );
    };

    onExiting = () => {
        this.timeline!.reverse();
    };

    render() {
        const { silk, title, text, pageIndex } = this.props;
        const isMultiLine = !!text;

        return (
            <div className="lf_super">
                <div
                    className="image-box"
                    ref={(logo) => (this.components.logo = logo)}
                >
                    {silk && <img src={silk} />}
                    {!silk && <SponsorTile />}
                </div>

                <div
                    className="text-container"
                    ref={(textContainer) =>
                        (this.components.textContainer = textContainer)
                    }
                >
                    <div
                        className="text-content"
                        ref={(text) => (this.components.text = text)}
                    >
                        {!isMultiLine && this.renderSingleLine(title)}
                        {isMultiLine &&
                            this.renderMultiLine(
                                title,
                                text || "",
                                pageIndex || -1,
                            )}
                    </div>
                </div>
            </div>
        );
    }

    renderSingleLine = (text: string) => {
        return <div className="one-line">{text}</div>;
    };

    renderMultiLine = (title: string, text: string, pageIndex: number) => {
        return (
            <div className="two-line">
                <div className="title">{title}</div>

                <TransitionGroup component="div" className="text">
                    <DetailLine key={pageIndex}>{text}</DetailLine>
                </TransitionGroup>
            </div>
        );
    };
}

// TODO work out proper initialState and handle pagination
const initialState: SuperProps = {
    // Title is constant, won't change from page to page
    title: "",

    // As many lines of text as you like, each line creates a new page.
    text: [],
} as unknown as SuperProps;

export default oldStyleComponent(
    "RacingAustralia/lf_super",
    initialState,
    (state) => state,
    Super,
    1500,
);
