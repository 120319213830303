import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import ScaledText from "truck-core/ScaledText";
import css from "./RaceTitle.module.css";

interface RaceTitleState {
    meetingName: string;
    raceNumber: number;
    raceName: string;
    distanceMetres: number;
    startTime: string;
}

const defaultState: RaceTitleState = {
    meetingName: "",
    raceNumber: 0,
    raceName: "",
    distanceMetres: 0,
    startTime: "",
};

export default function RaceTitle() {
    const state = useTruckComponent("ATCBigScreen/lf_raceTitle", defaultState);

    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const { raceNumber, raceName, distanceMetres } = state.data;

    if (!visible) {
        return null;
    }

    return (
        <div ref={animationRef} className={css.raceTitle}>
            <div className={css.raceNumber}>RACE {raceNumber}</div>
            <div className={css.raceName}>
                <ScaledText>{raceName}</ScaledText>
            </div>
            <div className={css.raceDistance}>{distanceMetres}M</div>
        </div>
    );
}
