import React from "react";
import $ from "jquery";
import cn from "classnames";

import "./AnimatedScrollTable.less";

import { Back, Power0, TimelineMax, TweenLite } from "gsap";

// Scroll forward time will be this multiplied by however many horses there are to scroll.
const ANIMATE_FORWARD_MULTIPLIER_SECONDS = 1.6;

// Delay before the initial scrolling starts
const ANIMATE_FORWARD_DELAY_SECONDS = 10;

// How quickly the scroll should bounce back to the top
const ANIMATE_BACK_SECONDS = 0.6;

// Delay before bouncing back
const ANIMATE_BACK_DELAY_SECONDS = ANIMATE_FORWARD_DELAY_SECONDS;

const wrapChildren = (
    wrapperClassName: string,
    { className, children }: { className?: string; children: React.ReactNode },
) => {
    if (!React.Children.count(children)) {
        return null;
    }

    const classNames = cn(wrapperClassName, className);

    return <div className={classNames}>{children}</div>;
};

interface AnimatedScrollTableProps {
    autoScroll: boolean;
    startAtRowNumber: number; // the first row to show
    totalRowCount: number; // total number of rows in the table
    pageRowCount: number; // number of rows per page
    className: string;
    children: React.ReactNode;
}

export default class AnimatedScrollTable extends React.PureComponent<AnimatedScrollTableProps> {
    static Header = (props: {
        className?: string;
        children: React.ReactNode;
    }) => {
        return wrapChildren("animated-scroll-table-header", props);
    };

    static Row = (props: { className?: string; children: React.ReactNode }) => {
        return wrapChildren("animated-scroll-table-row", props);
    };

    static Body = (props: {
        className?: string;
        children: React.ReactNode;
    }) => {
        return (
            <div className="animated-scroll-table-container">
                {wrapChildren("animated-scroll-table-rows", props)}
            </div>
        );
    };

    autoScrollTimeLine: TimelineMax | null = null;
    table: Element | null = null;

    componentDidMount() {
        this.adjustAutoScroll(this.props.autoScroll);
    }

    getSnapshotBeforeUpdate(prevProps: AnimatedScrollTableProps) {
        const { autoScroll, startAtRowNumber } = prevProps;

        return {
            autoScroll,
            startAtRowNumber,
        };
    }

    componentDidUpdate(
        _prevProps: AnimatedScrollTableProps,
        _prevState: unknown,
        snapshot: { autoScroll: boolean; startAtRowNumber: number },
    ) {
        if (!snapshot) return;

        const { autoScroll, startAtRowNumber } = this.props;

        const {
            autoScroll: prevAutoScroll,
            startAtRowNumber: prevStartAtRowNumber,
        } = snapshot;

        if (autoScroll !== prevAutoScroll) {
            this.adjustAutoScroll(autoScroll);
        }

        // Only adjust scroll position if we are not about to start auto-scrolling, and either
        //     * We previously WERE auto scrolling; or
        //     * The page index has changed
        if (
            !autoScroll &&
            (prevAutoScroll || prevStartAtRowNumber !== startAtRowNumber)
        ) {
            this.adjustScrollPosition(prevStartAtRowNumber, startAtRowNumber);
        }
    }

    adjustAutoScroll = (autoScroll: boolean) => {
        const { totalRowCount, pageRowCount } = this.props;
        if (totalRowCount < pageRowCount) return;

        if (!autoScroll) {
            if (this.autoScrollTimeLine) {
                this.autoScrollTimeLine.kill();
                this.autoScrollTimeLine = null;
            }

            return;
        }

        // Already scrolling...
        if (this.autoScrollTimeLine) return;

        const $table = $(this.table!);
        const $header = $(".animated-scroll-table-header", this.table!);
        const $rows = $(".animated-scroll-table-rows", this.table!);

        // Adjust the position back to the top to cater for going from paging mode to scrolling mode.
        TweenLite.to($rows, ANIMATE_BACK_SECONDS, {
            ease: Back.easeOut.config(1.3),
            y: 0,
        });

        // Cater for tables that don't have a header
        const headerHeight = ($header.length && $header.height()) || 0;
        const scrollContainerHeight = ($table.height() || 0) - headerHeight;
        const rowsTotalHeight = $rows.height() || 0;
        if (rowsTotalHeight <= scrollContainerHeight) return;

        // TODO: Why do we scroll more than needed by the headerHeight
        const delta =
            rowsTotalHeight - scrollContainerHeight + headerHeight - 2;

        const animateForwardSeconds =
            (totalRowCount - pageRowCount) * ANIMATE_FORWARD_MULTIPLIER_SECONDS;
        const animateBackDelaySeconds =
            ANIMATE_BACK_DELAY_SECONDS +
            ANIMATE_FORWARD_DELAY_SECONDS +
            animateForwardSeconds;

        const tl = (this.autoScrollTimeLine = new TimelineMax({ repeat: -1 }));
        tl.to(
            $rows,
            animateForwardSeconds,
            { ease: Power0.easeNone, y: -delta },
            ANIMATE_FORWARD_DELAY_SECONDS,
        );
        tl.to(
            $rows,
            ANIMATE_BACK_SECONDS,
            { ease: Back.easeOut.config(1.3), y: 0 },
            animateBackDelaySeconds,
        );
    };

    adjustScrollPosition = (
        currentStartAtRowNumber: number,
        nextStartAtRowNumber: number,
    ) => {
        const $nextRow = $(".animated-scroll-table-row", this.table!).eq(
            nextStartAtRowNumber,
        );
        if (!$nextRow.length) return;

        const $container = $(".animated-scroll-table-container", this.table!);
        const $rows = $(".animated-scroll-table-rows", this.table!);

        const rowDistanceFromTopOfContainer =
            $nextRow.offset()!.top - $rows.offset()!.top;

        // Don't scroll if the position hasn't changed - this _shouldn't_ happen because we guard against
        // this inside of `getSnapshotBeforeUpdate`.
        const containerTop = $container.offset()!.top;
        const runnersTop = $rows.offset()!.top;
        if (containerTop - runnersTop === rowDistanceFromTopOfContainer) return;

        // Work out the scroll time based on how far apart the rows are so we can work out how long to transition
        // for, in order to keep the scroll transition consistent no matter how many rows are moving.
        //
        // Cater for coming out of a scrolling component - in this case the startAtNumber hasn't changed so
        // the scroll time ends up being 0.
        let scrollTime;

        if (currentStartAtRowNumber === nextStartAtRowNumber) {
            scrollTime = ANIMATE_BACK_SECONDS;
        } else {
            scrollTime =
                Math.abs(currentStartAtRowNumber - nextStartAtRowNumber) * 0.12;
        }

        TweenLite.to($rows, scrollTime, {
            ease: Power0.easeNone,
            y: -rowDistanceFromTopOfContainer,
        });
    };

    render() {
        const { className } = this.props;
        const classNames = cn(className, "animated-scroll-table");

        return (
            <div className={classNames} ref={(table) => (this.table = table)}>
                {this.props.children}
            </div>
        );
    }
}
