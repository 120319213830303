import { TruckEvent, TruckEventHandler, RecordedTruckEvent } from "../events";

type TruckRecording = {
    events: RecordedTruckEvent[];
};

export default class TruckRecordingDriver {
    private nextEvent = 0;
    private startTime = 0;
    private lastPauseTimestamp = 0;

    private timeout:
        | {
              id: number;
              startTime: number;
          }
        | undefined = undefined;

    constructor(
        private eventHandler: TruckEventHandler,
        private recording: TruckRecording,
    ) {}

    play() {
        this.startTime = performance.now();
        this.next();
    }

    pause() {
        if (this.timeout) {
            this.nextEvent--;
            this.lastPauseTimestamp =
                (this.recording.events[this.nextEvent - 1]?.timestamp ?? 0) +
                performance.now() -
                this.timeout.startTime;
            clearTimeout(this.timeout.id);
            this.timeout = undefined;
        }
    }

    setPosition(timestamp: number) {
        const duration = this.duration;

        if (timestamp < 0 || timestamp > duration) {
            throw new Error("Timestamp out of range");
        }

        this.pause();

        const lastEvent = this.recording.events[this.nextEvent - 1];
        if (lastEvent && timestamp >= lastEvent.timestamp) {
            // we are going forwards in time, so we can just fast-forward ahead directly
        } else {
            // we are going backwards in time, so just reset everything and start from the beginning
            this.dispatchEvent({ type: "reset" });
            this.nextEvent = 0;
        }
        this.lastPauseTimestamp = timestamp;

        setTimeout(() => {
            this.play();
        }, 0);
    }

    get duration() {
        const lastEvent =
            this.recording.events[this.recording.events.length - 1];
        if (lastEvent) {
            return lastEvent.timestamp;
        }
        return 0;
    }

    private next() {
        this.timeout = undefined;
        for (;;) {
            const event = this.recording.events[this.nextEvent++];
            if (!event) {
                break;
            }

            const now = performance.now();
            const waitTime =
                event.timestamp -
                this.lastPauseTimestamp -
                (now - this.startTime);
            if (waitTime > 0) {
                const id = window.setTimeout(() => {
                    this.dispatchEvent(event);
                    this.next();
                }, waitTime);

                this.timeout = {
                    id,
                    startTime: now,
                };

                break;
            }

            this.dispatchEvent(event);
        }
    }

    private dispatchEvent(event: TruckEvent) {
        this.eventHandler?.handleEvent(event);
    }
}
