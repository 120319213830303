import { RaceType } from "truck-module-common-timing/types";
import classNames from "classnames";

import SaddleCloths from "./assets/saddle-cloths";

import css from "./Chiclet.module.css";

type Props = {
    className?: string | undefined;
    raceType: RaceType;
    number: number;
    baseColour: string;
    textColour: string;
};

export default function Chiclet(props: Props) {
    switch (props.raceType) {
        case RaceType.R:
            return (
                <div
                    style={{
                        backgroundColor: props.baseColour,
                        color: props.textColour,
                    }}
                    className={classNames(css.chiclet, props.className)}
                >
                    {props.number}
                </div>
            );
        case RaceType.H:
            return (
                <div
                    style={{
                        backgroundImage: `url(${
                            SaddleCloths[
                                props.number as keyof typeof SaddleCloths
                            ]
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    className={classNames(css.chiclet, props.className)}
                />
            );
    }
}
