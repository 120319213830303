import classNames from "classnames";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import { RaceType } from "truck-module-common-timing/types";
import ScaledText from "truck-core/ScaledText";

import Chiclet from "./Chiclet";

import css from "./RunningNumbers.module.css";
import fade from "./fade.module.css";
import { formatPrice } from "truck-module-common-timing/formatPrice";

type Jockey = {
    number: number;
    name: string;
    rank: number;
    silk: string;
    totePrice: number | null;
    baseColour: string;
    textColour: string;
};

type RunningNumbersState = {
    positions: Jockey[];
    firstHorseOnRight: boolean;
    raceType: RaceType;
};

const defaultState: RunningNumbersState = {
    positions: [],
    firstHorseOnRight: false,
    raceType: RaceType.R,
};

export default function RunningNumbers(props: { modulePrefix: string }) {
    const state = useTruckComponent(
        `${props.modulePrefix}/lf_jockeyPositions`,
        defaultState,
    );
    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: fade.enter,
        exit: fade.exit,
    });

    const { data } = state;

    if (!visible) {
        return null;
    }

    return (
        <div
            className={classNames(css.runningNumbers, {
                [css.reverse!]: data.firstHorseOnRight,
            })}
            ref={animationRef}
        >
            {data.positions.map((jockey, i) => (
                // The index is used as a key for useFlipTransition. Since we
                // want the horses to transition when the display direction
                // changes, we need to have the index depend on
                // firstHorseOnRight.
                <JockeyPosition
                    key={jockey.number}
                    index={state.data.firstHorseOnRight ? -i - 1 : i}
                    raceType={data.raceType}
                    {...jockey}
                />
            ))}
        </div>
    );
}

function JockeyPosition(props: Jockey & { index: number; raceType: RaceType }) {
    const ref = useFlipTransition(props.index);

    return (
        <div className={css.jockeyPosition}>
            <div className={css.position}>{rankToString(props.rank)}</div>
            <div ref={ref} className={css.jockey}>
                <img className={css.silk} src={props.silk} />
                <div className={css.name}>
                    <ScaledText>{props.name}</ScaledText>
                </div>
                <Chiclet
                    className={css.number}
                    raceType={props.raceType}
                    number={props.number}
                    baseColour={props.baseColour}
                    textColour={props.textColour}
                />
                {props.totePrice && (
                    <div className={css.price}>
                        {formatPrice(props.totePrice, true)}
                    </div>
                )}
            </div>
        </div>
    );
}

function rankToString(rank: number) {
    switch (rank) {
        case 1:
            return "leader";
        case 2:
            return "second";
        case 3:
            return "third";
        case 4:
            return "fourth";
        default:
            return rank.toString();
    }
}
