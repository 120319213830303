import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import transition from "./assets/correctWeightsTransition.mp4";
import css from "./Transition.module.css";

export default function Transition() {
    const state = useTruckComponent("ATCBigScreen/ff_transition", {});

    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div ref={animationRef} className={css.transition}>
            <video src={transition} autoPlay />
        </div>
    );
}
