import React from "react";
import useAnimation from "truck-core/hooks/useAnimation";

import Images, { ImageTypes } from "./assets";
import useTruckComponent from "truck-core/hooks/useTruckComponent";

import css from "./FixedBackground.module.css";

export default function Backdrop(props: {
    moduleId: string;
    type: ImageTypes;
}) {
    const state = useTruckComponent(`${props.moduleId}/ff_fixedBackground`, {});

    const { visible, ref: animationRef } = useAnimation(state.visible, {
        enter: css.backgroundEnter,
        exit: css.backgroundExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.backdrop} ref={animationRef}>
            <img src={Images[props.type]} alt="Backdrop" />
        </div>
    );
}
