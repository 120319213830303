import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useAnimatedTruckComponents from "truck-core/hooks/useAnimatedTruckComponents";

import ChicletMap from "./ChicletMap";
import Clock from "./Clock";
import RunningNumbers from "./RunningNumbers";
import Sectionals from "./Sectionals";
import Sponsor from "./Sponsor";
import MapAndDistance from "./TrackMapAndDistance";
import Watermark from "truck-module-common-timing/Watermark";
import Weather from "./Weather";

import cn from "classnames";

import css from "./index.module.css";

function ClockAndSponsor(props: { modulePrefix: string }) {
    const { visible, ref } = useAnimatedTruckComponents(
        [
            `${props.modulePrefix}/lf_runningClock`,
            `${props.modulePrefix}/lf_timingSponsor`,
        ],
        {
            enter: css.clockAndSponsorEnter,
            exit: css.clockAndSponsorExit,
        },
    );

    if (!visible.some((v) => v)) {
        return null;
    }

    return (
        <div className={css.clockAndSponsor} ref={ref}>
            <TruckErrorBoundary>
                <Clock
                    modulePrefix={props.modulePrefix}
                    visible={visible[0]!}
                />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Sponsor
                    modulePrefix={props.modulePrefix}
                    visible={visible[1]!}
                />
            </TruckErrorBoundary>
        </div>
    );
}

function TimingBox(props: { modulePrefix: string }) {
    return (
        <div
            className={cn(css.timingBox, {
                [css.skyOne!]: props.modulePrefix === "SkyOne",
            })}
        >
            <ClockAndSponsor modulePrefix={props.modulePrefix} />
            <TruckErrorBoundary>
                <MapAndDistance modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Sectionals modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
        </div>
    );
}

export default function SkyTiming(props: { modulePrefix: string }) {
    return (
        <div className={css.skyTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
            <TimingBox modulePrefix={props.modulePrefix} />
            <TruckErrorBoundary>
                <ChicletMap modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RunningNumbers modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Weather modulePrefix={props.modulePrefix} />
            </TruckErrorBoundary>
        </div>
    );
}
