import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./Clock.module.css";
import fade from "./fade.module.css";

type RaceState = "Scheduled" | "Armed" | "Running" | "Finished";

type ClockState = {
    time: number;
    state: RaceState;
};

const defaultState: ClockState = {
    time: 0,
    state: "Scheduled",
};

export default function Clock(props: { prefix: string; visible: boolean }) {
    const { data } = useTruckComponent(
        `${props.prefix}/lf_runningClock`,
        defaultState,
    );

    const { visible, ref } = useAnimation(props.visible, {
        enter: fade.enter,
        exit: fade.exit,
    });

    if (!visible) {
        return null;
    }

    switch (data.state) {
        case "Armed":
            return (
                <div ref={ref} className={css.clock}>
                    0.0
                </div>
            );
        case "Finished":
            return (
                <div ref={ref} className={css.clock}>
                    {formatTime(data.time, 2)}
                </div>
            );
        case "Running":
            return (
                <div ref={ref} className={css.clock}>
                    {formatTime(data.time, 1)}
                </div>
            );
        default:
            return null;
    }
}
