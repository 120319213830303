import React from "react";
import cn from "classnames";
import Placing from "./Placing";

import { PlaceData } from "../types";

const getPlaceName = (placeNumber: number) => {
    switch (placeNumber) {
        case 1:
            return "1st";
        case 2:
            return "2nd";
        case 3:
            return "3rd";
        default:
            return null;
    }
};

const getDeadHeatBannerText = (runnersForPlaces: PlaceData[][]) => {
    for (let i = 0; i < runnersForPlaces.length; i++) {
        if (runnersForPlaces[i]!.length < 2) continue;

        const placeName = getPlaceName(i + 1);
        return placeName && `Dead heat for ${placeName} place`;
    }

    return null;
};

interface DividendsPlacesProps {
    numberOfPlaces: number;
    places: PlaceData[];
    trainerLeftOffset: string;
    scrollingDistance: number;
}

class DividendsPlaces extends React.PureComponent<DividendsPlacesProps> {
    render() {
        // number of places can be passed as 0, so check that the type is a number.
        const numberOfPlaces =
            typeof this.props.numberOfPlaces === "number"
                ? this.props.numberOfPlaces
                : 3;
        const runnersForPlaces: PlaceData[][] = [];

        for (let place = 1; place <= 3; place++) {
            const runnersAtPlace = this.props.places.filter(
                (placing) => placing.place === place,
            );
            runnersForPlaces.push(runnersAtPlace);
        }

        const deadHeatBannerText = getDeadHeatBannerText(runnersForPlaces);
        const deadHeatBannerClasses = cn("dead-heat-banner", {
            "dead-heat": !!deadHeatBannerText,
        });

        return (
            <div className="horses">
                <div className={deadHeatBannerClasses}>
                    {deadHeatBannerText}
                </div>
                <div className="table">
                    {runnersForPlaces.map((runners, index) => (
                        <Placing
                            key={index}
                            place={index + 1}
                            numberOfPlaces={numberOfPlaces}
                            runners={runners}
                            trainerLeftOffset={this.props.trainerLeftOffset}
                            scrollingDistance={this.props.scrollingDistance}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default DividendsPlaces;
