import { useEffect, useLayoutEffect, useRef, useState } from "react";

export type Interpolatable<T> = {
    timestamp: number;
    data: T;
};

const MAX_QUEUE_LENGTH = 10;

export default function useInterpolation<T>(
    data: T,
    timestamp: number,
    interpolate: (prev: T, next: T, proportionCompleted: number) => T,
    delay: number,
) {
    const history = useRef<Interpolatable<T>[]>([]);
    const timestampOffset = useRef(0);
    const [interpolated, setInterpolated] = useState<T>(data);

    useEffect(() => {
        const latestTimestamp =
            history.current[history.current.length - 1]?.timestamp;
        if (
            latestTimestamp !== undefined &&
            timestamp > latestTimestamp &&
            history.current.length < MAX_QUEUE_LENGTH
        ) {
            history.current.push({ timestamp, data });
        } else if (timestamp === latestTimestamp) {
            history.current[length - 1] = { timestamp, data };
        } else {
            timestampOffset.current = performance.now() - timestamp;
            history.current = [{ timestamp, data }];
        }
    }, [timestamp, data]);

    useLayoutEffect(() => {
        let animationRequest = requestAnimationFrame(runInterpolation);

        function runInterpolation(now: number) {
            // delay updates so that we are able to interpolate between them
            now -= delay + timestampOffset.current;

            while (history.current[1] && history.current[1].timestamp <= now) {
                history.current.shift();
            }

            if (history.current.length === 0) {
                // do nothing
            } else if (history.current.length === 1) {
                setInterpolated(history.current[0]!.data);
            } else {
                const prev = history.current[0]!;
                const next = history.current[1]!;
                setInterpolated(
                    interpolate(
                        prev.data,
                        next.data,
                        (now - prev.timestamp) /
                            (next.timestamp - prev.timestamp),
                    ),
                );
            }

            animationRequest = requestAnimationFrame(runInterpolation);
        }

        return () => {
            cancelAnimationFrame(animationRequest);
        };
    }, [interpolate, delay]);

    return interpolated;
}
