import useTruckComponent from "truck-core/hooks/useTruckComponent";
import { useAnimationList } from "truck-core/hooks/useAnimation";

export default function useAnimatedTruckComponents(
    componentIds: string[],
    animation: Parameters<typeof useAnimationList>[1],
) {
    const visible = [];
    for (const id of componentIds) {
        // this is safe as long as the componentIds array is constant
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const data = useTruckComponent(id, {});
        visible.push(data.visible);
    }

    return useAnimationList(visible, animation);
}
