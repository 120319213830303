import ScaledText from "truck-core/ScaledText";
import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import cn from "classnames";

import css from "./JockeyList.module.css";
import { useState } from "react";
import { formatPrice } from "truck-module-common-timing/formatPrice";
import useInterval from "truck-core/hooks/useInterval";

type Runner = {
    name: string;
    number: number;
    barrierNumber: number;
    jockey: string;
    silk: string;
    winAmount: string;
    placeAmount: string;
    priceCompared: ComparedToAverage;
    isScratched: boolean;
};

interface JockeyState {
    runners: Runner[];
}

const defaultState: JockeyState = {
    runners: [],
};

export enum ComparedToAverage {
    Up = ">",
    Down = "<",
    Equal = "=",
}

function UpDownCaret(props: { compared: ComparedToAverage | undefined }) {
    switch (props.compared) {
        case ComparedToAverage.Up:
            return <div className={css.caretUp} />;
        case ComparedToAverage.Down:
            return <div className={css.caretDown} />;
        case ComparedToAverage.Equal:
        default:
            return <div className={css.caretEmpty} />;
    }
}

export default function JockeyList() {
    const state = useTruckComponent(
        "AtcHomestraight/Grub_HorseList",
        defaultState,
    );
    const [profilesShown, setProfilesShown] = useState(0);

    useInterval(() => {
        if (profilesShown >= state.data.runners.length) {
            // Start again from the start
            setProfilesShown(0);
        } else {
            // Show one more profile
            setProfilesShown(profilesShown + 1);
        }
    }, 3000);

    if (!state.visible) {
        if (profilesShown != 0) {
            // Reset the profiles shown to 0 when the component hides
            setProfilesShown(0);
        }
        return null;
    }

    const pageStart = Math.floor(profilesShown / 12) * 12;

    return (
        <div className={css.jockeyList}>
            {state.data.runners.map((jockey, i) => (
                <JockeyProfile
                    key={jockey.number}
                    visible={
                        state.visible && pageStart <= i && i <= profilesShown
                    }
                    {...jockey}
                />
            ))}
        </div>
    );
}

function JockeyProfile(props: Runner & { visible: boolean }) {
    const { visible: silkVisible, ref: silkAnimationRef } = useAnimation(
        props.visible,
        { enter: css.silkEnter },
    );
    const { visible: textVisible, ref: textAnimationRef } = useAnimation(
        props.visible,
        { enter: css.jockeyInfoEnter },
    );
    if (!silkVisible || !textVisible) {
        return null;
    }
    return (
        <div
            className={cn(css.jockeyProfile, {
                [css.jockeyScratched!]: props.isScratched,
            })}
        >
            <div className={css.silk}>
                <img src={props.silk} ref={silkAnimationRef} />
            </div>
            <div className={css.jockeyInfo} ref={textAnimationRef}>
                <div className={css.jockeyInfoNameAndDriver}>
                    <div className={css.jockeyInfoName}>
                        <ScaledText>
                            {props.number}. {props.name} ({props.barrierNumber})
                        </ScaledText>
                    </div>
                    <div className={css.jockeyInfoDriver}>
                        <ScaledText>
                            Jockey / <b>{props.jockey}</b>
                        </ScaledText>
                    </div>
                </div>
                <div className={css.jockeyInfoPrice}>
                    <UpDownCaret compared={props.priceCompared} />
                    <ScaledText>
                        {props.winAmount === "SCR" ||
                        props.winAmount === "L-SCR" ||
                        props.winAmount === "--"
                            ? props.winAmount
                            : `$${formatPrice(parseFloat(props.winAmount))}`}
                    </ScaledText>
                </div>
            </div>
        </div>
    );
}
