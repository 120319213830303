import classNames from "classnames";

import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useTruckComponent from "truck-core/hooks/useTruckComponent";

import MileRate from "./MileRate";
import Clock from "./Clock";
import TopSpeed from "./TopSpeed";
import Sponsor from "./Sponsor";
import QuarterSectionals from "./QuarterSectionals";
import ChicletMap from "./ChicletMap";

import css from "./index.module.css";
import Watermark from "truck-module-common-timing/Watermark";
import TrackMapAndDistance from "./TrackMapAndDistance";

export default function HRVTiming() {
    return (
        <div className={css.hrvTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="HRV" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <MileRate />
            </TruckErrorBoundary>
            <TimingBox />
            <TruckErrorBoundary>
                <QuarterSectionals />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <ChicletMap />
            </TruckErrorBoundary>
        </div>
    );
}

function TimingBox() {
    let className = css.timingBox0;
    const sponsor = useTruckComponent("HRV/lf_timingSponsor", {});
    const clock = useTruckComponent("HRV/lf_runningClock", {});
    if (clock.visible) {
        className = css.timingBox1;
    }
    const trackMap = useTruckComponent("HRV/lf_trackMap", {});
    if (trackMap.visible) {
        className = css.timingBox2;
    }
    const runningDistance = useTruckComponent("HRV/lf_runningDistance", {});
    if (runningDistance.visible) {
        className = css.timingBox2;
    }
    const topSpeed = useTruckComponent("HRV/lf_topSpeed", {});
    if (topSpeed.visible) {
        className = css.timingBox3;
    }

    return (
        <div className={css.timingBoxContainer}>
            <div className={classNames(css.timingBox, className)}>
                <div className={css.topSpeed}>
                    <TruckErrorBoundary>
                        <TopSpeed visible={topSpeed.visible} />
                    </TruckErrorBoundary>
                </div>
                <div className={css.mapAndDistance}>
                    <TruckErrorBoundary>
                        <TrackMapAndDistance
                            trackMapVisible={trackMap.visible}
                            runningDistanceVisible={runningDistance.visible}
                        />
                    </TruckErrorBoundary>
                </div>
                <div className={css.clock}>
                    <TruckErrorBoundary>
                        <Clock visible={clock.visible} />
                    </TruckErrorBoundary>
                </div>
            </div>
            <div className={css.sponsor}>
                <TruckErrorBoundary>
                    <Sponsor visible={sponsor.visible} />
                </TruckErrorBoundary>
            </div>
        </div>
    );
}
