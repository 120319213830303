import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import formatTime from "truck-module-common-timing/formatTime";

import UpDownCaret, { ComparedToAverage } from "../hrv-timing/UpDownCaret";

import css from "./MileRate.module.css";

type MileRateState = {
    mileRate: number;
    comparedToAverage?: ComparedToAverage;
};

const defaultState: MileRateState = {
    mileRate: 0,
};

export default function MileRate() {
    const state = useTruckComponent(
        "QldHarnessRacing/lf_mileRate",
        defaultState,
    );

    const { data } = state;

    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.mileRate} ref={ref}>
            <div className={css.mileRateInner}>
                <div className={css.name}>MR</div>
                <div className={css.time}>
                    <UpDownCaret compared={data.comparedToAverage} />
                    {formatTime(data.mileRate, 2)}
                </div>
            </div>
        </div>
    );
}
