type Options = {
    showPositiveSign?: boolean,
    removeLeadingZero?: boolean,
}

export default function formatTime(
    totalMilliseconds: number,
    millisecondsPlaces = 1,
    options?: Options,
) {
    const showPositiveSign = options?.showPositiveSign ?? false;
    const removeLeadingZero = options?.removeLeadingZero ?? false;

    let negation = "";
    if (totalMilliseconds < 0) {
        negation = "-";
        totalMilliseconds = -totalMilliseconds;
    } else if (showPositiveSign) {
        negation = "+";
    }

    const totalSeconds = totalMilliseconds / 1000;
    const totalWholeSeconds = Math.floor(totalSeconds);
    const seconds = totalWholeSeconds % 60;
    const minutes = (totalWholeSeconds - seconds) / 60;

    let secondsDisplay: string, minutesDisplay: string;
    if (minutes > 0) {
        secondsDisplay = seconds.toString().padStart(2, "0");
        minutesDisplay = `${minutes}:`;
    } else {
        if (removeLeadingZero && seconds == 0) {
            secondsDisplay = "";
        } else {
            secondsDisplay = seconds.toString();
        }
        minutesDisplay = "";
    }

    const milliseconds = totalMilliseconds - totalWholeSeconds * 1000;
    const millisecondsDisplay = milliseconds
        .toString()
        .padStart(3, "0")
        .substring(0, millisecondsPlaces)
        .padEnd(millisecondsPlaces, "0");

    return `${negation}${minutesDisplay}${secondsDisplay}.${millisecondsDisplay}`;
}
