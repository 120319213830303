import cn from "classnames";

import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useAnimation from "truck-core/hooks/useAnimation";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import formatTime from "truck-module-common-timing/formatTime";
import ScaledText from "truck-core/ScaledText";
import Watermark from "truck-module-common-timing/Watermark";

import css from "./index.module.css";

type Jockey = {
    name: string;
    breeding: string;
    lastSectional: number | null;
};

type JockeyPositionState = {
    positions: Jockey[];
};

const defaultJockeyPositionsState: JockeyPositionState = {
    positions: [],
};

type RunningClockState = {
    time: number;
};

const defaultRunningClockState: RunningClockState = {
    time: 0,
};

export default function BreezeUp() {
    const jockeyPositions = useTruckComponent(
        "BreezeUp/lf_jockeyPositions",
        defaultJockeyPositionsState,
    );

    const clock = useTruckComponent(
        "BreezeUp/lf_runningClock",
        defaultRunningClockState,
    );

    const positions = jockeyPositions.data.positions
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

    const horse1 = jockeyPositionToLotInfoProps(positions[0]);
    const horse2 = jockeyPositionToLotInfoProps(positions[1]);

    return (
        <div className={css.breezeup}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="BreezeUp" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                {horse1 && (
                    <LotInfo
                        visible={jockeyPositions.visible}
                        position="left"
                        {...horse1}
                    />
                )}
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                {horse2 && (
                    <LotInfo
                        visible={jockeyPositions.visible}
                        position="right"
                        {...horse2}
                    />
                )}
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Clock visible={clock.visible} time={clock.data.time} />
            </TruckErrorBoundary>
        </div>
    );
}

type LotInfoProps = {
    visible: boolean;
    position: "left" | "right";
    name: string;
    breeding: string;
    time: number | null;
};

function LotInfo(props: LotInfoProps) {
    const { ref, visible } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    const className = cn(css.lotInfo, {
        [css.lotInfoLeft!]: props.position == "left",
        [css.lotInfoRight!]: props.position == "right",
    });

    return (
        <div className={className} ref={ref}>
            <div className={css.lotAndBreeding}>
                <div className={css.lot}>{props.name}</div>
                <div className={css.breeding}>
                    <ScaledText>{props.breeding}</ScaledText>
                </div>
            </div>
            {props.time && (
                <div className={css.lotTime}>
                    200m–0m: {formatTime(props.time, 2)}
                </div>
            )}
        </div>
    );
}

function jockeyPositionToLotInfoProps(jockey: Jockey | undefined) {
    if (!jockey) {
        return undefined;
    }

    return {
        name: jockey.name,
        breeding: jockey.breeding,
        time: jockey.lastSectional,
    };
}

type ClockProps = {
    visible: boolean;
    time: number;
};

function Clock(props: ClockProps) {
    const { ref, visible } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.clock} ref={ref}>
            {formatTime(props.time)}
        </div>
    );
}
