import css from "./UpDownCaret.module.css";

export enum ComparedToAverage {
    Up = ">",
    Down = "<",
    Equal = "=",
}

export default function UpDownCaret(props: {
    compared: ComparedToAverage | undefined;
}) {
    switch (props.compared) {
        case ComparedToAverage.Up:
            return <div className={css.caretUp} />;
        case ComparedToAverage.Down:
            return <div className={css.caretDown} />;
        case ComparedToAverage.Equal:
        default:
            return <div className={css.caretEmpty} />;
    }
}
