import React from "react";
import $ from "jquery";
import { TweenLite, TimelineMax, Power3, Back } from "gsap";
import classNames from "classnames";

import oldStyleComponent from "../../core/oldStyleComponent";
import RaceClassConditions from "./RaceClassConditions";
import SponsorTile from "../_shared/SponsorTile";
import measureAndScrollRaceTitle from "../_shared/RaceTitleScrolling";

import "./RaceTitle.styles.less";

const ANIMATION_TIMEOUT = { enter: 3000, exit: 3000 };

interface RaceTitleProps {
    raceNumber?: number;
    raceName: string;
    distanceMetres?: number;
    raceClassConditions: string;
    logo: string;
    meetingName: string;
}

class RaceTitle extends React.Component<RaceTitleProps> {
    container: HTMLDivElement | null = null;
    titleBoxInside: HTMLDivElement | null = null;
    timeline: TimelineMax | null = null;

    componentWillUnmount() {
        this.timeline && this.timeline.kill();
    }

    componentDidMount() {
        // Delay the element measuring by 1.6 seconds so we measure the final sizes after the entering transitions are complete
        setTimeout(() => {
            if (this.titleBoxInside) {
                this.timeline = measureAndScrollRaceTitle(this.titleBoxInside);
            }
        }, 1600);
    }

    onEntering = () => {
        const $logo = $(".sponsor-tile", this.container!);
        const $titleBox = $(".title-box", this.container!);
        const $titleBoxInside = $(".title-box-inside", this.container!);

        const tl = new TimelineMax();

        tl.fromTo(
            this.container,
            0.6,
            { ease: Back.easeOut.config(1.3), opacity: 0, x: -1000 },
            { ease: Back.easeOut.config(1.3), opacity: 1, x: 0 },
            0,
        );

        tl.fromTo(
            $logo,
            0.45,
            { ease: Back.easeOut.config(1.3), opacity: 0, x: -500, scale: 1 },
            { ease: Back.easeOut.config(1.3), opacity: 1, x: 0, scale: 1.5 },
            0.5,
        );
        tl.to($logo, 0.35, { ease: Power3.easeOut, x: 0, scale: 1 }, 0.8);

        tl.fromTo(
            $titleBox,
            0.35,
            { ease: Power3.easeOut, x: -110, opacity: 0 },
            { ease: Power3.easeOut, x: 0, opacity: 1 },
            0.8,
        );
        tl.fromTo(
            $titleBoxInside,
            0.15,
            { ease: Back.easeOut.config(1.3), x: -20 },
            { ease: Back.easeOut.config(1.3), x: 10 },
            0.85,
        );
        tl.to(
            $titleBoxInside,
            0.075,
            { ease: Back.easeOut.config(1.3), x: 0 },
            1,
        );

        tl.to($logo, 0.25, { ease: Power3.easeOut, y: 0, x: 0 }, 1.35);
        tl.to($titleBox, 0.25, { ease: Power3.easeOut, y: 0, x: 0 }, 1.35);
    };

    onExiting = () => {
        TweenLite.to(this.container, 0.6, {
            ease: Power3.easeIn,
            x: -1000,
            opacity: 0,
        });
    };

    render() {
        const {
            raceNumber,
            raceName,
            distanceMetres,
            raceClassConditions,
            logo,
            meetingName,
        } = this.props;

        return (
            <div
                className="lf_race-title-container"
                ref={(container) => (this.container = container)}
            >
                <div className="title">
                    <SponsorTile src={logo} />

                    <div className="title-box">
                        <div
                            className="title-box-inside"
                            ref={(titleBoxInside) =>
                                (this.titleBoxInside = titleBoxInside)
                            }
                        >
                            {raceNumber && (
                                <h1
                                    className={classNames({
                                        "double-digit": raceNumber > 9,
                                    })}
                                >
                                    {raceNumber}
                                </h1>
                            )}
                            <div className="title-box-inside-right">
                                <div>
                                    <span className="meeting">
                                        {meetingName}
                                    </span>
                                    {distanceMetres !== undefined && (
                                        <span className="distance">
                                            {distanceMetres}m
                                        </span>
                                    )}
                                </div>
                                <div className="text">
                                    {raceName}
                                    <RaceClassConditions
                                        value={raceClassConditions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RaceTitleProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_raceTitle",
    {} as RaceTitleProps,
    mapStateToProps,
    RaceTitle,
    { timeout: ANIMATION_TIMEOUT },
);
