import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import GraphicsTestPattern from "./assets/GraphicsTestPattern.svg";
import TripleSWatermark from "./assets/TripleSWatermark.png";

import css from "./Watermark.module.css";

interface IProps {
    modulePrefix: string;
}

interface WatermarkState {
    graphicsTest: boolean;
    venkmanName: string;
}

const defaultState: WatermarkState = {
    graphicsTest: false,
    venkmanName: "",
};

export default function Watermark(props: IProps) {
    const state = useTruckComponent(
        `${props.modulePrefix}/lf_watermark`,
        defaultState,
    );
    const {
        data: { graphicsTest, venkmanName },
    } = state;

    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return graphicsTest ? (
        <div ref={ref}>
            <svg viewBox="0 0 1920 1080">
                <image href={GraphicsTestPattern} height="100%" width="100%" />
                <text
                    x="960"
                    y="400"
                    className={css.venkmanName}
                    dominantBaseline="central"
                >
                    {venkmanName}
                </text>
            </svg>
        </div>
    ) : (
        <div className={css.watermark} ref={ref}>
            <img src={TripleSWatermark} className={css.watermarkImg} />
        </div>
    );
}
