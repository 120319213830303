import { useCallback, useRef, useState } from "react";

export default function useElementDimensions() {
    const elementRef = useRef<HTMLElement | null>(null);
    const observerRef = useRef<ResizeObserver | null>(null);
    const [dimensions, setDimensions] = useState<DOMRectReadOnly>();
    const callback = useCallback((element: HTMLElement | null) => {
        if (elementRef.current && observerRef.current) {
            // disconnect existing observer
            observerRef.current.disconnect();
            observerRef.current = null;
        }

        elementRef.current = element;
        if (!elementRef.current) {
            return;
        }

        // We use a ResizeObserver rather than detecting the size directly,
        // because the size can change without the react component being
        // re-rendered. This happens, for example, if the element contains
        // text that uses a web font that isn't yet loaded when the
        // component is initially rendered, so we end up calculating the
        // size based on the browser's fallback font.
        observerRef.current = new ResizeObserver(() => {
            if (elementRef.current) {
                setDimensions(elementRef.current.getBoundingClientRect());
            }
        });

        observerRef.current.observe(elementRef.current);
    }, []);

    return [callback, dimensions] as const;
}
