import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import ScaledText from "truck-core/ScaledText";

import css from "./JockeyPositions.module.css";

type Position = {
    number: number;
    silk: string;
    name: string;
    rank: number;
    position: string;
    horseId: string;
    state: string;
};

interface JockeyPositionsState {
    positions: Position[];
}

const defaultState: JockeyPositionsState = {
    positions: [],
};

export default function JockeyPositions() {
    const state = useTruckComponent("TripleS/lf_jockeyPositions", defaultState);
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.jockeyPositions} ref={ref}>
            {state.data.positions.map((p, i) => (
                <JockeyPosition key={p.horseId} index={i} {...p} />
            ))}
        </div>
    );
}

function JockeyPosition(props: Position & { index: number }) {
    const ref = useFlipTransition(props.index);
    return (
        <div className={css.jockeyPosition} ref={ref}>
            <div className={css.jockeyDetails}>
                <img className={css.silk} src={props.silk} />
                <div className={css.number}>{props.number}</div>
            </div>
            <div className={css.positionDetails}>
                <div className={css.rank}>{props.rank}</div>
                <div className={css.position}>{props.position}</div>
            </div>
            <div className={css.name}>
                <ScaledText>{props.name}</ScaledText>
            </div>
        </div>
    );
}
