import React from "react";

import oldStyleComponent from "../../core/oldStyleComponent";
import FullFrameAnimated from "../_animation/ff_animated/FullFrameAnimated";

import SponsorTile from "../_shared/SponsorTile";
import DividendsPlaces from "./components/DividendsPlaces";

import "./PastThePost.styles.less";
import classNames from "classnames";
import {
    DividendsComponentState,
    PastThePost as PastThePostType,
} from "./types";
import reducer, { initialState } from "./Dividends.reducer";

interface PastThePostProps extends PastThePostType {
    logo: string;
}

class PastThePost extends React.Component<PastThePostProps> {
    container = null;
    animator: FullFrameAnimated | null = null;

    onEntering = () => this.animator!.onEntering();
    onExiting = () => this.animator!.onExiting();

    render() {
        const {
            raceNumber,
            raceName,
            distanceMetres,
            places,
            logo,
            meetingName,
            numberOfPlaces,
            isInterim,
        } = this.props;
        const distance = `${distanceMetres}m`;

        const dividendClasses = classNames({
            interim: isInterim,
            final: !isInterim,
        });

        const statusBoxContentClass = classNames(
            "status-box-content",
            dividendClasses,
        );
        const animatedClass = classNames("past-the-post", dividendClasses);

        return (
            <FullFrameAnimated
                ref={(animator) => (this.animator = animator)}
                meetingName={meetingName}
                raceNumber={raceNumber}
                raceName={raceName}
                distance={distance}
                hideFooter={true}
                classNames={animatedClass}
                align="bottom"
                meetingMaxWidth={485}
                meetingMaxHeight={63}
                meetingInitialFontSize={63}
                logo={<SponsorTile src={logo} />}
                status={
                    <div className={statusBoxContentClass}>
                        {isInterim ? "Interim" : "Final"}
                    </div>
                }
            >
                <DividendsPlaces
                    places={places}
                    numberOfPlaces={numberOfPlaces}
                    trainerLeftOffset={"428px"}
                    scrollingDistance={35}
                />
            </FullFrameAnimated>
        );
    }
}

const mapStateToProps = (state: DividendsComponentState) => reducer(state);

export default oldStyleComponent(
    "RacingAustralia/lf_pastThePost",
    initialState,
    mapStateToProps,
    PastThePost,
    { timeout: { enter: 3000, exit: 250 } },
);
