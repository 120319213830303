import useTruckComponent from "truck-core/hooks/useTruckComponent";
import formatTime from "truck-module-common-timing/formatTime";

import css from "./Clock.module.css";

type RaceState = "Scheduled" | "Armed" | "Running" | "Finished";

type ClockState = {
    time: number;
    state: RaceState;
};

const defaultState: ClockState = {
    time: 0,
    state: "Scheduled",
};

export default function Clock(props: {
    modulePrefix: string;
    visible: boolean;
}) {
    const { data } = useTruckComponent(
        `${props.modulePrefix}/lf_runningClock`,
        defaultState,
    );
    if (!props.visible) {
        return null;
    }

    switch (data.state) {
        case "Armed":
            return <div className={css.clock}>0.0</div>;
        case "Finished":
            return <div className={css.clock}>{formatTime(data.time, 2)}</div>;
        case "Running":
            return <div className={css.clock}>{formatTime(data.time, 1)}</div>;
        default:
            return null;
    }
}
