import gsap from "gsap";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

import TruckErrorBoundary from "truck-core/TruckErrorBoundary";

import Dividends, {
    RedcliffeDividends,
} from "./components/ff_dividends/Dividends";
import FixedBackground from "./components/ff_fixed-background/FixedBackground";
import PastThePost from "./components/ff_dividends/PastThePost";
import Totes, { RedcliffeTotes } from "./components/ff_totes/Totes";
import {
    WinningOwner,
    VenueWinningOwner,
} from "./components/ff_winning-owner/WinningOwner";
import SponsorCycle from "./components/ff_sponsorCycle/SponsorCycle";
import SingleImage from "./components/ff_singleImage/SingleImage";
import LateScratching from "./components/lf_late-scratching/LateScratching";
import PhotoAllPlacings from "./components/lf_photo-all-placings/PhotoAllPlacings";
import Protest from "./components/lf_protest/Protest";
import SilkAndName from "./components/lf_silk-and-name/SilkAndName";
import Super from "./components/lf_super/Super";
import RaceTitle from "./components/lf_race-title/RaceTitle";
import RunningNumbers from "./components/lf_running-numbers/RunningNumbers";
import TopHorses from "./components/lf_top-horses/TopHorses";
import YardPage from "./components/lf_yard-page/YardPage";
import CommentatorPick from "./components/lf_commentator-pick/CommentatorPick";
import Watermark from "truck-module-common-timing/Watermark";

import css from "./index.module.css";

gsap.registerPlugin(DrawSVGPlugin, MotionPathPlugin);

export default function HorseRacing() {
    return (
        <div className={css.horseRacing}>
            <TruckErrorBoundary>
                <Dividends />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <FixedBackground moduleId="RacingAustralia" type="ATC" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <PastThePost />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Totes />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <WinningOwner />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <SponsorCycle component="RacingAustralia/ff_sponsorCycle" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <SponsorCycle component="RacingAustralia/ff_raceSponsor" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <LateScratching />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <PhotoAllPlacings />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Protest />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <SilkAndName />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Super />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RaceTitle />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RunningNumbers />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <TopHorses />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <YardPage />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <CommentatorPick />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <SingleImage />
            </TruckErrorBoundary>
        </div>
    );
}

export function AtcWinningOwnerModule() {
    return (
        <div className={css.horseRacing}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="AtcWinningOwner" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <VenueWinningOwner />
            </TruckErrorBoundary>
        </div>
    );
}

export function RedcliffeInternalScreens() {
    return (
        <div className={css.horseRacing}>
            <TruckErrorBoundary>
                <RedcliffeDividends />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <FixedBackground
                    moduleId="RedcliffeInternalScreens"
                    type="Redcliffe"
                />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RedcliffeTotes />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <Watermark modulePrefix="RedcliffeInternalScreens" />
            </TruckErrorBoundary>
        </div>
    );
}
