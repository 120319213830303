import React, { useRef, useEffect } from "react";

export default function useInterval(
    cb: CallableFunction,
    delay: number,
    deps?: React.DependencyList,
) {
    const savedCB = useRef<CallableFunction>();

    useEffect(() => {
        savedCB.current = cb;
    }, [cb]);

    useEffect(() => {
        if (delay !== null) {
            const t = setInterval(() => savedCB.current?.(), delay);
            return () => clearInterval(t);
        }
        // So we can pass deps into this useEffect
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...(deps ?? [])]);
}
