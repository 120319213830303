import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import css from "./RaceTitle.module.css";

type RaceTitleState = {
    venueName: string;
    raceName: string;
    raceNumber: number;
    raceLength: number;
    trackCondition: string | null;
};

const defaultState: RaceTitleState = {
    venueName: "",
    raceName: "",
    raceNumber: -1,
    raceLength: 0,
    trackCondition: null,
};

export default function RaceTitle() {
    const state = useTruckComponent("SkyHarness/lf_raceTitle", defaultState);
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.raceTitle} ref={ref}>
            <div className={css.raceNumber}>{data.raceNumber}</div>
            <div className={css.lines}>
                <div className={css.topLine}>
                    <div className={css.venue}>{data.venueName}</div>
                    <div className={css.raceLength}>{data.raceLength}m</div>
                </div>
                <div className={css.bottomLine}>
                    <div className={css.raceName}>{data.raceName}</div>
                    <div className={css.trackCondition}>
                        {data.trackCondition}
                    </div>
                </div>
            </div>
        </div>
    );
}
