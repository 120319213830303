import React from "react";
import classNames from "classnames";
import $ from "jquery";
import { TweenLite, TimelineMax, Power3, Back } from "gsap";
import { Transition } from "react-transition-group";

import HashTag from "../../_shared/HashTag";
import measureAndScrollRaceTitle from "../../_shared/RaceTitleScrolling";
import ScaledText from "../../_shared/scaled-text";

import "./FullFrameAnimated.styles.less";

export const FF_ANIMATED_EXIT_TIMEOUT_SECONDS = 0.4;

interface FullFrameAnimatedProps {
    align?: string;
    delay?: number;
    raceNumber: number;
    raceName: string;
    distance: string;
    status: JSX.Element;
    in?: boolean;
    meetingName: string;
    logo: JSX.Element;
    hideFooter?: boolean;
    classNames?: string;
    meetingMaxWidth: number;
    meetingMaxHeight: number;
    meetingInitialFontSize: number;
    children: React.ReactNode | React.ReactNode[];
}

class FullFrameAnimated extends React.Component<FullFrameAnimatedProps> {
    container: HTMLDivElement | null = null;
    titleBoxInside: Element | null = null;
    timeline: TimelineMax | null = null;

    componentWillUnmount() {
        this.timeline && this.timeline.kill();
    }

    componentDidMount() {
        // Delay the element measuring by 1.5 seconds so we measure the final sizes after the entering transitions are complete
        setTimeout(() => {
            this.timeline = measureAndScrollRaceTitle(this.titleBoxInside!);
        }, 1500);
    }

    onEntering = () => {
        const { align, delay } = this.props;
        const isCentered = align !== "bottom";

        const $logo = $(".sponsor-tile", this.container!);
        const $titleBox = $(".title-box", this.container!);
        const $titleBoxInside = $(".title-box-inside", this.container!);
        const $statusBoxContent = $(".status-box-content", this.container!);
        const $statusBox = $(".status-box", this.container!);
        const $maskContent = $(".mask-content", this.container!);
        const $border = $(".border", this.container!);
        const $bottom = $(".bottom", this.container!);

        const tl = new TimelineMax();
        if (delay) {
            tl.duration(10);
            tl.delay(delay);
        }

        const logoMidpointY = isCentered ? 335 : 125;

        tl.fromTo(
            $logo,
            0.3,
            { ease: Back.easeOut.config(1.3), y: 1150, x: 650, scale: 2 },
            {
                ease: Back.easeOut.config(1.3),
                y: logoMidpointY,
                x: 650,
                scale: 2,
            },
            0.5,
        );
        tl.to(
            $logo,
            0.35,
            { ease: Power3.easeOut, y: logoMidpointY, x: 0, scale: 1 },
            1,
        );

        tl.fromTo(
            $titleBox,
            0.35,
            { ease: Power3.easeOut, y: logoMidpointY, x: -110, opacity: 0 },
            { ease: Power3.easeOut, y: logoMidpointY, x: 0, opacity: 1 },
            1.2,
        );
        tl.fromTo(
            $titleBoxInside,
            0.15,
            { ease: Back.easeOut.config(1.3), x: -20 },
            { ease: Back.easeOut.config(1.3), x: 10 },
            1.25,
        );
        tl.to(
            $titleBoxInside,
            0.075,
            { ease: Back.easeOut.config(1.3), x: 0 },
            1.4,
        );
        tl.fromTo(
            $statusBox,
            0.25,
            { ease: Power3.easeOut, y: logoMidpointY, x: -110 },
            { ease: Power3.easeOut, y: logoMidpointY, x: 0 },
            1.3,
        );
        tl.fromTo(
            $statusBox,
            0.1,
            { ease: Power3.easeOut, opacity: 0 },
            { ease: Power3.easeOut, opacity: 1 },
            1.3,
        );
        tl.from(
            $statusBoxContent,
            0.15,
            { ease: Back.easeOut.config(3), x: 15, opacity: 0 },
            1.7,
        );
        tl.to(
            $statusBoxContent,
            0.15,
            { ease: Back.easeOut.config(3), x: 0, opacity: 1 },
            1.8,
        );
        tl.to($logo, 0.25, { ease: Power3.easeOut, y: 0, x: 0 }, 1.35);
        tl.to($titleBox, 0.25, { ease: Power3.easeOut, y: 0, x: 0 }, 1.35);
        tl.to($statusBox, 0.25, { ease: Power3.easeOut, y: 0, x: 0 }, 1.35);

        tl.from(
            $maskContent,
            0.15,
            { ease: Power3.easeOut, x: -800, y: -815 },
            1.5,
        );
        tl.from($border, 0.15, { ease: Power3.easeOut, x: -800, y: -815 }, 1.5);
        tl.from($bottom, 0.15, { ease: Power3.easeOut, x: -800, y: -865 }, 1.5);
    };

    onExiting = () => {
        TweenLite.to(this.container, FF_ANIMATED_EXIT_TIMEOUT_SECONDS, {
            opacity: 0,
        });
    };

    render() {
        const {
            raceName,
            raceNumber,
            distance,
            status,
            logo,
            hideFooter,
            align,
            meetingName,
            meetingMaxWidth,
            meetingMaxHeight,
        } = this.props;
        const containerClasses = classNames(
            "ff_animated-container",
            this.props.classNames,
            {
                "justify-content-top": align !== "bottom",
                "justify-content-bottom": align === "bottom",
            },
        );
        const raceNumberClasses = classNames({
            "double-digit": raceNumber > 9,
        });

        return (
            <Transition
                in={this.props.in}
                timeout={{ enter: 3000, exit: 400 }}
                onEntering={this.onEntering}
                onExiting={this.onExiting}
            >
                <div
                    className={containerClasses}
                    ref={(container) => (this.container = container)}
                >
                    <div className="title">
                        {logo}

                        <div className="title-box">
                            <div
                                className="title-box-inside"
                                ref={(titleBoxInside) =>
                                    (this.titleBoxInside = titleBoxInside)
                                }
                            >
                                <h1 className={raceNumberClasses}>
                                    {raceNumber}
                                </h1>
                                <div className="title-box-inside-right">
                                    <ScaledText
                                        className="meeting"
                                        targetWidth={meetingMaxWidth}
                                        targetHeight={meetingMaxHeight}
                                    >
                                        <span>{meetingName}</span>
                                    </ScaledText>
                                    <span className="distance">{distance}</span>
                                    <span className="text">{raceName}</span>
                                </div>
                            </div>
                        </div>
                        <div className="status-box">{status}</div>
                    </div>

                    <div className="mask">
                        <div className="border" />

                        <div className="mask-content">
                            {this.props.children}
                        </div>

                        {!hideFooter && (
                            <div className="bottom">
                                <HashTag />
                            </div>
                        )}
                    </div>
                </div>
            </Transition>
        );
    }
}

export default FullFrameAnimated;
