import { useState } from "react";
import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";
import useFlipTransition from "truck-core/hooks/useFlipTransition";
import formatTime from "truck-module-common-timing/formatTime";
import useInterval from "truck-core/hooks/useInterval";
import cn from "classnames";

import css from "./Sectionals.module.css";

interface SectionalData {
    endDistance: number;
    time: number;
    intermediateTime: number;
    averageTime: number | null;
}

interface SectionalsState {
    // It is assumed that stages later in the array were recorded at
    // a later time (with a shorter race distance remaining)
    stages: SectionalData[];
    averageTime1200: number | null;
}

const defaultState: SectionalsState = {
    stages: [],
    averageTime1200: null,
};

const MAX_STAGES = 4;

export default function Sectionals() {
    const state = useTruckComponent("MRC/lf_runningStages", defaultState);
    const { data } = state;
    const { visible, ref } = useAnimation(state.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const [showDeltas, setShowDeltas] = useState(false);
    useInterval(() => setShowDeltas((s) => !s), showDeltas ? 2000 : 4000);

    if (!visible) {
        return null;
    }

    const stages = data.stages.slice().reverse();

    return (
        <div className={css.sectionals} ref={ref}>
            {stages.map((stage, i) => (
                <Sectional
                    key={stage.endDistance}
                    index={i}
                    {...stage}
                    visible={i < MAX_STAGES}
                    showDelta={showDeltas}
                    averageTime1200={
                        stage.endDistance == 1200 ? data.averageTime1200 : null
                    }
                />
            ))}
        </div>
    );
}

const MIN_TIME_EQUAL = 10;

function Sectional(
    props: SectionalData & {
        index: number;
        visible: boolean;
        showDelta: boolean;
        averageTime1200: number | null;
    },
) {
    const { visible, ref: animationRef } = useAnimation(props.visible, {
        enter: css.sectionalEnter,
        exit: css.sectionalExit,
    });
    const flipRef = useFlipTransition(props.index);

    if (!visible) {
        return null;
    }

    const isTo1200Time = props.averageTime1200 !== null;
    const comparedToAverage = isTo1200Time
        ? getAverageTimeDelta(props.intermediateTime, props.averageTime1200)
        : getAverageTimeDelta(props.time, props.averageTime);

    const showDelta =
        props.showDelta && comparedToAverage !== null && !isTo1200Time;

    return (
        <div
            className={css.sectionalContainer}
            ref={(r) => {
                flipRef(r);
                animationRef(r);
            }}
        >
            <div
                className={cn(css.sectional, {
                    [css.fast!]:
                        comparedToAverage !== null &&
                        comparedToAverage <= -MIN_TIME_EQUAL,
                    [css.similar!]:
                        comparedToAverage === null ||
                        (comparedToAverage > -MIN_TIME_EQUAL &&
                            comparedToAverage < MIN_TIME_EQUAL),
                    [css.slow!]:
                        comparedToAverage !== null &&
                        comparedToAverage >= MIN_TIME_EQUAL,
                    [css.withComparison1200!]:
                        comparedToAverage !== null && isTo1200Time,
                })}
            >
                <div className={css.distance}>{props.endDistance}M</div>
                <div className={cn(css.time, { [css.showDelta!]: showDelta })}>
                    <div>{formatTime(props.time, 1)}</div>
                    {comparedToAverage !== null && !isTo1200Time && (
                        <div>
                            (
                            {formatTime(comparedToAverage, 2, {
                                showPositiveSign: true,
                                removeLeadingZero: true,
                            })}
                            s)
                        </div>
                    )}
                </div>
            </div>
            {comparedToAverage !== null && isTo1200Time && (
                <div className={cn(css.comparison, css.comparison1200)}>
                    <span>TO 1200M:</span>
                    <span>
                        (
                        {formatTime(comparedToAverage, 2, {
                            showPositiveSign: true,
                            removeLeadingZero: true,
                        })}
                        s)
                    </span>
                </div>
            )}
        </div>
    );
}

function getAverageTimeDelta(time: number, averageTime: number | null) {
    if (averageTime === null) return null;

    return Math.floor((time - averageTime) / 10) * 10;
}
