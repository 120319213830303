import React from "react";

import LowerFrameAnimatedWithImageBox from "../_animation/lf_animatedWithImageBox/LowerFrameAnimatedWithImageBox";

import "./Protest.styles.less";
import oldStyleComponent from "../../core/oldStyleComponent";
import ScaledText from "../_shared/scaled-text";
import { PlaceData } from "../_shared/types";
import SponsorTile from "../_shared/SponsorTile";

type ProtestParticipant = Pick<
    PlaceData,
    "horseName" | "horseNumber" | "place"
>;

interface ProtestProps {
    accuser: ProtestParticipant;
    accusee: ProtestParticipant;
    reason?: string;
    logo: string;
}

interface ProtestState {
    participantsFontSize: number;
}

const PLACE_CONVERSION: { [place: number]: string } = {
    1: "1st",
    2: "2nd",
    3: "3rd",
    4: "4th",
};

class Protest extends React.Component<ProtestProps, ProtestState> {
    constructor(props: ProtestProps) {
        super(props);
        this.state = {
            participantsFontSize: 40,
        };
    }
    animator: LowerFrameAnimatedWithImageBox | null = null;
    textRef: HTMLElement | null = null;

    onEntering = () => this.animator!.onEntering();
    onExiting = () => this.animator!.onExiting();

    getHorseDescription = (horse: ProtestParticipant) => (
        <span>
            <span className="name">
                {horse.horseNumber}. {horse.horseName}
            </span>{" "}
            ({PLACE_CONVERSION[horse.place]})
        </span>
    );

    render() {
        const { accuser, accusee, reason, logo } = this.props;
        const protestText = (
            <span>
                PROTEST: {this.getHorseDescription(accuser)}{" "}
                <span className="separator">v</span>{" "}
                {this.getHorseDescription(accusee)}
            </span>
        );
        return (
            <LowerFrameAnimatedWithImageBox
                ref={(animator) => (this.animator = animator)}
                className="lf_protest"
                image={<SponsorTile src={logo} />}
            >
                <div className="two-line">
                    <ScaledText
                        className="participants"
                        targetWidth={1394}
                        targetHeight={65}
                    >
                        {protestText}
                    </ScaledText>
                    <div className="second-line">
                        <ScaledText
                            className="reason"
                            targetWidth={1394}
                            targetHeight={45}
                        >
                            {reason}
                        </ScaledText>
                    </div>
                </div>
            </LowerFrameAnimatedWithImageBox>
        );
    }
}

const mapStateToProps = (state: ProtestProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_protest",
    {} as ProtestProps,
    mapStateToProps,
    Protest,
    { timeout: { enter: 3000, exit: 3000 } },
);
