import { useEffect, useRef, useState } from "react";

import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import redcliffe from "./assets/redcliffe.png";
import bell from "../hrv-timing/assets/bell.svg";
import css from "../qld-horseracing-timing/Sponsor.module.css";

type SponsorState = {
    url: string | undefined;
    isInLastLap: boolean;
    distanceToFinish: number;
};

const defaultState: SponsorState = {
    url: undefined,
    isInLastLap: false,
    distanceToFinish: 0,
};

export default function Sponsor(props: { visible: boolean }) {
    const { data } = useTruckComponent(
        "QldHarnessRacing/lf_timingSponsor",
        defaultState,
    );

    const { visible, ref } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    const isBell = useIsBell(data.isInLastLap);
    const isSponsor = !!(data.url && data.distanceToFinish % 200 >= 100);

    const { visible: bellVisible, ref: bellRef } = useAnimation(isBell, {
        enter: css.bellEnter,
        exit: css.bellExit,
    });

    const { visible: sponsorVisible, ref: sponsorRef } = useAnimation(
        isSponsor && !isBell,
        {
            enter: css.sponsorEnter,
            exit: css.sponsorExit,
        },
    );

    const { visible: trotsVisible, ref: trotsRef } = useAnimation(!isSponsor, {
        enter: sponsorVisible ? css.trotsEnter : "",
        exit: css.trotsExit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div ref={ref} className={css.sponsor}>
            {!isBell && trotsVisible && (
                <img className={css.trots} ref={trotsRef} src={redcliffe} />
            )}
            {!isBell && sponsorVisible && (
                <img className={css.bell} ref={sponsorRef} src={data.url} />
            )}
            {bellVisible && (
                <img className={css.bell} ref={bellRef} src={bell} />
            )}
        </div>
    );
}

const BELL_TIME = 5000;

function useIsBell(isInLastLap: boolean) {
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const [prevIsInLastLap, setPrevIsInLastLap] = useState(isInLastLap);
    const [isBell, setIsBell] = useState(false);

    if (prevIsInLastLap != isInLastLap) {
        if (isInLastLap) {
            setIsBell(true);
        }
        setPrevIsInLastLap(isInLastLap);
    }

    useEffect(() => {
        if (isBell && timeout.current == null) {
            timeout.current = setTimeout(() => {
                setIsBell(false), (timeout.current = null);
            }, BELL_TIME);
        }

        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [isBell]);

    return isBell;
}
