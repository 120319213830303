import classNames from "classnames";

import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import useTruckComponent from "truck-core/hooks/useTruckComponent";

import RaceTitle from "./RaceTitle";
import Clock from "../qld-horseracing-timing/Clock";
import TrackMapAndRunningDistance from "./TrackMapAndRunningDistance";
import Sponsor from "./Sponsor";
import ChicletMap from "./ChicletMap";
import QuarterSectionals from "./QuarterSectionals";
import TopSpeed from "./TopSpeed";
import Watermark from "truck-module-common-timing/Watermark";
import MileRate from "./MileRate";
import JockeyPositions from "./JockeyPositions";

import css from "./index.module.css";

export default function QldHarnessRacing() {
    return (
        <div className={css.hrvTiming}>
            <TruckErrorBoundary>
                <Watermark modulePrefix="QldHarnessRacing" />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <RaceTitle />
            </TruckErrorBoundary>
            <TimingBox />
            <TruckErrorBoundary>
                <QuarterSectionals />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <MileRate />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <ChicletMap />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <JockeyPositions />
            </TruckErrorBoundary>
        </div>
    );
}

function TimingBox() {
    let className = css.timingBox0;
    const sponsor = useTruckComponent("QldHarnessRacing/lf_timingSponsor", {});
    const clock = useTruckComponent("QldHarnessRacing/lf_runningClock", {});
    if (clock.visible) {
        className = css.timingBox1;
    }
    const trackMap = useTruckComponent("QldHarnessRacing/lf_trackMap", {});
    if (trackMap.visible) {
        className = css.timingBox2;
    }
    const runningDistance = useTruckComponent(
        "QldHarnessRacing/lf_runningDistance",
        {},
    );
    if (runningDistance.visible) {
        className = css.timingBox2;
    }
    const topSpeed = useTruckComponent("QldHarnessRacing/lf_topSpeed", {});
    if (topSpeed.visible) {
        className = css.timingBox3;
    }

    return (
        <div className={css.timingBoxContainer}>
            <div className={classNames(css.timingBox, className)}>
                <div className={css.topSpeed}>
                    <TruckErrorBoundary>
                        <TopSpeed visible={topSpeed.visible} />
                    </TruckErrorBoundary>
                </div>
                <div className={css.mapAndDistance}>
                    <TruckErrorBoundary>
                        <TrackMapAndRunningDistance />
                    </TruckErrorBoundary>
                </div>
                <div className={css.clock}>
                    <TruckErrorBoundary>
                        <Clock
                            prefix="QldHarnessRacing"
                            visible={clock.visible}
                        />
                    </TruckErrorBoundary>
                </div>
            </div>
            <div className={css.sponsor}>
                <TruckErrorBoundary>
                    <Sponsor visible={sponsor.visible} />
                </TruckErrorBoundary>
            </div>
        </div>
    );
}
