export const asMoney = (amount: string | number): string => {
    const money = typeof amount === "string" ? parseFloat(amount) : amount;

    // if the amount isn't a number, return it unchanged
    if (isNaN(money)) {
        return amount.toString();
    }

    return money.toFixed(2);
};

export const zeroPad = (number: number, width = 2): string => {
    const isNegative = number < 0;

    let result = Math.abs(number).toString();
    while (result.length < width) {
        result = `0${result}`;
    }

    return isNegative ? `-${result}` : result;
};
