import classNames from "classnames";
import { useState, CSSProperties } from "react";

import useElementDimensions from "truck-core/hooks/useElementDimensions";
import useInterpolation from "truck-core/hooks/useInterpolation";

import css from "./ChicletMap.module.css";

type Box = {
    left: number;
    top: number;
    width: number;
    height: number;
};

type Viewport = {
    track: Box;
    screen: Box;
};

type Horse = {
    number: number;
    distanceToRail: number;
    distanceToFinish: number;
};

type ChicletMapProps<H extends Horse> = {
    className?: string;
    horses: H[];
    timestamp: number;
    raceLength: number;
    raceDirection: "clockwise" | "anticlockwise";
    isInFinishStraight: boolean;
    xRangeMetres: number;
    yRangeStdDevs: number;
    renderChiclet: (horse: H) => JSX.Element;
    renderDistanceMarker: (distance: number) => JSX.Element;
};

const INTERPOLATION_DELAY = 350;

export default function ChicletMap<H extends Horse>(props: ChicletMapProps<H>) {
    const [mapRef, mapDims] = useElementDimensions();
    const [chicletsAreaRef, chicletsAreaDims] = useElementDimensions();

    // Sort so that when we interpolate each horse will always be at the same
    // position in the array. We use horse numbers in reverse order so that the
    // chiclet divs will have the correct stacking order.
    const horses = props.horses.slice().sort((h1, h2) => h2.number - h1.number);

    const interpolatedPositions = useInterpolation(
        horses,
        props.timestamp,
        interpolatePositions,
        INTERPOLATION_DELAY,
    );

    const [rotation, setRotation] = useState(0);
    const [wasInFinishStraight, setWasInFinishStraight] = useState(false);
    if (wasInFinishStraight != props.isInFinishStraight) {
        setRotation(rotation + 180);
        setWasInFinishStraight(props.isInFinishStraight);
    }

    const chicletsTrackBox = calculateChicletsTrackBox(
        interpolatedPositions,
        props.raceLength,
        props.xRangeMetres,
        props.yRangeStdDevs,
    );

    const chicletsViewport =
        chicletsAreaDims && mapDims
            ? {
                  track: chicletsTrackBox,
                  screen: {
                      left: chicletsAreaDims.left - mapDims.left,
                      top: chicletsAreaDims.top - mapDims.top,
                      width: chicletsAreaDims.width,
                      height: chicletsAreaDims.height,
                  },
              }
            : undefined;

    const markersViewport =
        chicletsViewport && mapDims
            ? {
                  track: {
                      left:
                          chicletsViewport.track.left -
                          chicletsViewport.screen.left *
                              (chicletsViewport.track.width /
                                  chicletsViewport.screen.width),
                      top: chicletsViewport.track.top,
                      width:
                          chicletsViewport.track.width *
                          (mapDims.width / chicletsViewport.screen.width),
                      height: chicletsViewport.track.height,
                  },
                  screen: {
                      left: 0,
                      top: 0,
                      width: mapDims.width,
                      height: mapDims.height,
                  },
              }
            : undefined;

    const rotationStyle = {
        "--rotate": `${rotation}deg`,
    } as CSSProperties;

    return (
        <div
            className={classNames(css.rotator, {
                [css.flipHorizontal!]: props.raceDirection == "anticlockwise",
            })}
            style={rotationStyle}
            ref={mapRef}
        >
            {markersViewport && (
                <DistanceMarkers
                    raceLength={props.raceLength}
                    viewport={markersViewport}
                    renderDistanceMarker={props.renderDistanceMarker}
                />
            )}
            <div className={css.chicletsArea} ref={chicletsAreaRef}>
                {chicletsViewport &&
                    interpolatedPositions.map((horse) => (
                        <Chiclet<H>
                            key={horse.number}
                            horse={horse}
                            renderChiclet={props.renderChiclet}
                            raceLength={props.raceLength}
                            viewport={chicletsViewport}
                            rotation={rotation}
                        />
                    ))}
            </div>
        </div>
    );
}

function interpolatePositions<H extends Horse>(
    prev: H[],
    next: H[],
    proportionCompleted: number,
) {
    // When we lose a horse, its not safe to use the index of array any more.
    // Convert to a dictionary instead.
    const nextIndexed: H[] = [];
    next.forEach((h) => (nextIndexed[h.number] = h));
    return prev.map((p) => {
        const n = nextIndexed[p.number];
        if (n === undefined) {
            return p;
        }

        const pos = { ...p };
        pos.distanceToFinish +=
            (n.distanceToFinish - p.distanceToFinish) * proportionCompleted;
        pos.distanceToRail +=
            (n.distanceToRail - p.distanceToRail) * proportionCompleted;
        return pos;
    });
}

function calculateChicletsTrackBox(
    positions: Horse[],
    raceLength: number,
    trackBoxWidth: number,
    trackBoxHeight: number,
) {
    const leaderDistanceToFinish = Math.min(
        ...positions.map((h) => h.distanceToFinish),
    );

    const weightedFunction = (x: number) => {
        const now = x - leaderDistanceToFinish - trackBoxWidth;
        if (now <= 1) return 1;
        return 1 / Math.pow(now, Math.log(now) / Math.log(100));
    };

    const weightedPositions = positions.map((h) => ({
        ...h,
        weight: weightedFunction(h.distanceToFinish),
    }));

    const sumWeights = weightedPositions.reduce(
        (prev, h) => prev + h.weight,
        0,
    );

    const avgDTR =
        weightedPositions.reduce(
            (prev, position) =>
                prev + position.distanceToRail * position.weight,
            0,
        ) / sumWeights;

    // Weighted standard deviation
    // https://stats.stackexchange.com/questions/6534/how-do-i-calculate-a-weighted-standard-deviation-in-excel
    const stdDevDTR = Math.sqrt(
        weightedPositions.reduce(
            (prev, position) =>
                prev +
                position.weight * (avgDTR - position.distanceToRail) ** 2,
            0,
        ) /
            (((weightedPositions.length - 1) / weightedPositions.length) *
                sumWeights),
    );

    const xValues = positions.map((p) => raceLength - p.distanceToFinish);

    const left = Math.max(...xValues) - trackBoxWidth;
    const top = avgDTR - trackBoxHeight * stdDevDTR;

    return {
        left,
        top,
        width: trackBoxWidth,
        height: 2 * trackBoxHeight * stdDevDTR,
    };
}

type ChicletProps<H extends Horse> = {
    horse: H;
    renderChiclet: (horse: H) => JSX.Element;
    raceLength: number;
    viewport: Viewport;
    rotation: number;
};

function Chiclet<H extends Horse>(props: ChicletProps<H>) {
    const [ref, dims] = useElementDimensions();
    const x =
        ((props.raceLength -
            props.horse.distanceToFinish -
            props.viewport.track.left) /
            props.viewport.track.width) *
            props.viewport.screen.width -
        (dims?.width ?? 0);
    const y =
        (1 -
            (props.horse.distanceToRail - props.viewport.track.top) /
                props.viewport.track.height) *
        props.viewport.screen.height;

    return (
        <div
            className={css.chicletContainer}
            ref={ref}
            style={{ transform: `translate(${x}px, ${y}px)` }}
        >
            <div className={css.chicletRotator}>
                {props.renderChiclet(props.horse)}
            </div>
        </div>
    );
}

function DistanceMarkers(props: {
    raceLength: number;
    viewport: Viewport;
    renderDistanceMarker: (distance: number) => JSX.Element;
}) {
    let distance = getNextDistance(props.viewport.track.left, props.raceLength);
    const markers = [];
    while (
        distance != null &&
        distance <= props.viewport.track.left + props.viewport.track.width
    ) {
        markers.push(
            <DistanceMarker
                key={distance}
                distance={distance}
                raceLength={props.raceLength}
                viewport={props.viewport}
                renderDistanceMarker={props.renderDistanceMarker}
            />,
        );
        distance = getNextDistance(distance + 1, props.raceLength);
    }

    return <>{markers}</>;
}

function DistanceMarker(props: {
    distance: number;
    raceLength: number;
    viewport: Viewport;
    renderDistanceMarker: (distance: number) => JSX.Element;
}) {
    const [ref, dims] = useElementDimensions();
    const x =
        ((props.distance - props.viewport.track.left) /
            props.viewport.track.width) *
            props.viewport.screen.width +
        (dims?.width ?? 0);
    return (
        <div
            className={css.distanceMarkerContainer}
            style={{ transform: `translateX(${x}px) rotate(90deg)` }}
            ref={ref}
        >
            <div className={css.distanceMarkerRotator}>
                {props.renderDistanceMarker(props.distance)}
            </div>
        </div>
    );
}

function getNextDistance(distance: number, raceLength: number) {
    const DISTANCE_MARKER_INTERVAL = 200;
    const distanceToFinish = raceLength - distance;

    // there's always a distance marker at 100m DTF
    if (
        distanceToFinish < DISTANCE_MARKER_INTERVAL &&
        distanceToFinish >= 100
    ) {
        return raceLength - 100;
    }

    const nextDTFMarker =
        Math.ceil(
            (distanceToFinish - DISTANCE_MARKER_INTERVAL + 1) /
                DISTANCE_MARKER_INTERVAL,
        ) * DISTANCE_MARKER_INTERVAL;

    // there's never a marker at/before the end
    if (nextDTFMarker <= 0) {
        return null;
    }

    // no markers before start of race
    if (nextDTFMarker > raceLength) {
        return raceLength - (raceLength % DISTANCE_MARKER_INTERVAL);
    }

    return raceLength - nextDTFMarker;
}
