import useTruckComponent from "truck-core/hooks/useTruckComponent";
import useAnimation from "truck-core/hooks/useAnimation";

import css from "./TopSpeed.module.css";

type TopSpeedState = {
    currentSpeed: number;
    leadTime?: number;
    leadTimeHorse?: number;
};

const defaultState: TopSpeedState = {
    currentSpeed: 0.0,
};

export default function TopSpeed(props: { visible: boolean }) {
    const { data } = useTruckComponent(
        "QldHarnessRacing/lf_topSpeed",
        defaultState,
    );

    const { visible, ref } = useAnimation(props.visible, {
        enter: css.enter,
        exit: css.exit,
    });

    if (!visible) {
        return null;
    }

    return (
        <div className={css.topSpeed} ref={ref}>
            <div className={css.title}>Top Speed</div>
            <div className={css.value}>
                {(data.currentSpeed * 3.6).toFixed(2)}
                <span className={css.unit}>km/h</span>
            </div>
        </div>
    );
}
