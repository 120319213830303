import useTruckComponent from "truck-core/hooks/useTruckComponent";
import TruckErrorBoundary from "truck-core/TruckErrorBoundary";
import PostRaceTable from "./PostRaceTable";
import PreRaceTable from "./PreRaceTable";

export type RunnerDetails = {
    number: number;
    name: string;
    silk: string;
};

export type Runner = RunnerDetails & {
    flucs: number[];
    officialPrice: number;
    tabWin: number;
    tabPlace: number;
    isScratched: boolean;
};

export type Weight = RunnerDetails & {
    placing: string;
    officialPriceTf: number;
    officialPriceSp: number;
    tabWin: number;
    tabPlace: number;
};

export type Deduction = RunnerDetails & {
    winPrice: number;
    placePrice: number;
    secondPlacePrice: number;
    thirdPlacePrice: number;
    scratchedTime: string;
};

export interface JockeyState {
    venueName: string;
    raceType: string;
    raceNumber: number;
    jurisdiction: string;
    raceName: string;
    raceTime: string;
    runners: Runner[];
    weights: Weight[] | null;
    deductions: Deduction[] | null;
    venueColor: string | null;
    isPastThePost: boolean;
}

const defaultState: JockeyState = {
    venueName: "",
    raceType: "",
    raceNumber: 0,
    jurisdiction: "",
    raceName: "",
    raceTime: "",
    runners: [],
    weights: null,
    deductions: null,
    venueColor: null,
    isPastThePost: false,
};

export default function RaceTable() {
    const state = useTruckComponent("ATCBigScreen/ABA_HorseList", defaultState);

    const {
        data: { runners, weights },
        visible,
    } = state;

    const isPreRace =
        visible && runners.length > 0 && !(weights?.length ?? 0 > 0);
    const isPostRace = visible && (weights?.length ?? 0) > 0;

    return (
        <>
            <TruckErrorBoundary>
                <PreRaceTable data={state.data} visible={isPreRace} />
            </TruckErrorBoundary>
            <TruckErrorBoundary>
                <PostRaceTable data={state.data} visible={isPostRace} />
            </TruckErrorBoundary>
        </>
    );
}

export function formatPrice(price: number) {
    if (price == 0) {
        return "";
    }
    return price % 1 == 0
        ? price.toFixed(0).toString()
        : price.toFixed(1).toString();
}

export function getOfficialPriceHeader(jurisdiction: string) {
    switch (jurisdiction) {
        case "QLD":
            return "QOP";
        case "VIC":
            return "VOP";
        case "NSW":
            return "NOP";
        default:
            return "OP";
    }
}
