import React, { useEffect, useState } from "react";

import { TruckEventHandler } from "./events";
import TruckPlayer, {
    ComponentState,
    ComponentUpdateCallback,
    EnabledModuleCallback,
} from "./player";
import HarmonyClient from "./drivers/harmony";
import TruckRecordingDriver from "./drivers/recording";
import TruckRecorder, { TruckRecorderConfig } from "./drivers/recorder";

export type Player = {
    subscribeToComponent<T>(
        id: string,
        callback: ComponentUpdateCallback<T>,
    ): ComponentState<T> | undefined;
    unsubscribeFromComponent<T>(
        id: string,
        callback: ComponentUpdateCallback<T>,
    ): void;
    subscribeToEnabledModules(callback: EnabledModuleCallback): string[];
    unsubscribeFromEnabledModules(callback: EnabledModuleCallback): void;
};

const noopPlayer = {
    subscribeToComponent: () => undefined,
    unsubscribeFromComponent: () => {},
    subscribeToEnabledModules: () => [],
    unsubscribeFromEnabledModules: () => {},
};

const TruckPlayerContext = React.createContext<Player>(noopPlayer);

export { ComponentState, TruckPlayerContext };

export function useTruckPlayer() {
    const [player] = useState(new TruckPlayer());
    return player;
}

export type HarmonyConfig = {
    url: string;
    sessionToken: string;
};

export function useHarmonyClient(
    handler: TruckEventHandler,
    config: HarmonyConfig | undefined,
) {
    const [client, setClient] = useState<HarmonyClient>();

    useEffect(() => {
        const client =
            config &&
            new HarmonyClient(handler, config.url, config.sessionToken);
        setClient(client);
        return () => {
            if (client) {
                client.disconnect();
            }
        };
    }, [handler, config]);

    return client;
}

export function useTruckRecording(
    handler: TruckEventHandler,
    recordingUrl: string | undefined,
) {
    const [driver, setDriver] = useState<TruckRecordingDriver>();

    useEffect(() => {
        if (recordingUrl) {
            void (async () => {
                const recording = await fetch(recordingUrl).then((r) =>
                    r.json(),
                );
                setDriver(
                    recording && new TruckRecordingDriver(handler, recording),
                );
            })();
        } else {
            setDriver(undefined);
        }
    }, [handler, recordingUrl]);

    return driver;
}

export { TruckRecorderConfig };

export function useTruckRecorder(
    handler: TruckEventHandler | undefined,
    name: string | undefined,
    config: TruckRecorderConfig | undefined,
) {
    const [recorder, setRecorder] = useState<TruckRecorder>();

    useEffect(() => {
        const recorder =
            handler && config ? new TruckRecorder(handler, config) : undefined;
        setRecorder(recorder);
    }, [handler, config]);

    useEffect(() => {
        if (recorder && name) {
            recorder.start(name);
        }

        return () => {
            if (recorder) {
                recorder.finish();
            }
        };
    }, [recorder, name]);

    return recorder;
}
