export type VenkmanConfig = {
    harmonyUrl: string;
    licenceKey: string;
    identity: string;
    description: string;

    onConnect?: () => void;
    onOpenTruck?: (sessionToken: string, url: string) => void;
    onClose?: () => void;
};

type VideoCompositor = {
    name: string;
    description: string;
    compositorKey: string;
};

export default class Venkman {
    private ws: WebSocket;

    constructor(private readonly config: VenkmanConfig) {
        this.ws = new WebSocket(this.buildHarmonyUrl(), "json");

        this.ws.onopen = () => {
            this.config?.onConnect?.();
            this.addVideoCompositor({
                name: this.config.identity,
                description: this.config.description,
                compositorKey: this.config.identity,
            });
        };

        this.ws.onmessage = ({ data: raw }) => {
            const msg = JSON.parse(raw);
            switch (msg?.msg) {
                case "open":
                    this.config?.onOpenTruck?.(
                        msg.data.sessionToken,
                        msg.data.truckUrl,
                    );
                    break;
                case "name":
                    this.send("name", {
                        name: this.config.identity,
                        description: this.config.description,
                    });
                    break;
                default:
                    throw new Error(`Unknown message '${msg?.msg}'`);
            }
        };

        this.ws.onclose = () => {
            this.config?.onClose?.();
        };
    }

    public disconnect(): void {
        this.ws.close();
        this.config?.onClose?.();
    }

    private addVideoCompositor(data: VideoCompositor) {
        this.send("addedVideoCompositor", data);
    }

    private send(msg: string, data: { [name: string]: unknown }) {
        this.ws.send(JSON.stringify({ msg, data }));
    }

    private buildHarmonyUrl(): string {
        const url = new URL("venkman", this.config.harmonyUrl);
        url.searchParams.set("x-licence-key", this.config.licenceKey);
        url.searchParams.set("x-identity", this.config.identity);
        return url.toString();
    }
}
