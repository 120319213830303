import Saddle1 from "./1.svg";
import Saddle2 from "./2.svg";
import Saddle3 from "./3.svg";
import Saddle4 from "./4.svg";
import Saddle5 from "./5.svg";
import Saddle6 from "./6.svg";
import Saddle7 from "./7.svg";
import Saddle8 from "./8.svg";
import Saddle9 from "./9.svg";
import Saddle10 from "./10.svg";
import Saddle11 from "./11.svg";
import Saddle12 from "./12.svg";
import Saddle13 from "./13.svg";

export default {
    1: Saddle1,
    2: Saddle2,
    3: Saddle3,
    4: Saddle4,
    5: Saddle5,
    6: Saddle6,
    7: Saddle7,
    8: Saddle8,
    9: Saddle9,
    10: Saddle10,
    11: Saddle11,
    12: Saddle12,
    13: Saddle13,
};
