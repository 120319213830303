import React from "react";
import AnimatedScrollTable from "../../_animation/animated-scroll-table/AnimatedScrollTable";
import { DIVIDENDS_PER_PAGE } from "../Dividends.reducer";

import { Exotic } from "../types";

interface DividendsExoticsProps {
    exotics: Exotic[];
    autoScroll: boolean;
    startAtRowNumber: number; // the first row to show
}

class DividendsExotics extends React.PureComponent<DividendsExoticsProps> {
    render() {
        const { exotics, autoScroll, startAtRowNumber } = this.props;

        return (
            <AnimatedScrollTable
                pageRowCount={DIVIDENDS_PER_PAGE}
                totalRowCount={exotics.length}
                startAtRowNumber={startAtRowNumber}
                autoScroll={autoScroll}
                className="exotics table"
            >
                <AnimatedScrollTable.Body>
                    {exotics.map(renderExotic)}
                </AnimatedScrollTable.Body>
            </AnimatedScrollTable>
        );
    }
}

function renderExotic(exotic: Exotic) {
    const selections = exotic.selections.join(" - ");
    const key = `${exotic.name}-${selections}`;

    return (
        <AnimatedScrollTable.Row key={key} className="row">
            <div className="exotic-name">{exotic.name}</div>
            <div className="exotic-numbers">{selections}</div>
            <div className="exotic-payout">{exotic.amount}</div>
        </AnimatedScrollTable.Row>
    );
}

export default DividendsExotics;
