import React from "react";

interface TimeToRaceProps {
    startTime: string;
    render: (minutes: number, seconds: number) => JSX.Element;
}

interface TimeToRaceState {
    startTimeValue?: number;
    minutes: number;
    seconds: number;
}

class TimeToRace extends React.Component<TimeToRaceProps, TimeToRaceState> {
    updateInterval: NodeJS.Timeout | null = null;

    constructor(props: TimeToRaceProps) {
        super(props);

        const { startTime } = this.props;
        this.state = this.calculateTimeToRace(new Date(startTime).valueOf());
    }

    updateStartTime = (startTime: string) => {
        const { startTimeValue } = this.state;
        const nextStartTimeValue = new Date(startTime).valueOf();

        if (nextStartTimeValue === startTimeValue) {
            return;
        }

        this.setState(() => ({ startTimeValue: nextStartTimeValue }));

        return nextStartTimeValue;
    };

    calculateTimeToRace = (startTimeValue: number) => {
        const currentTime = new Date().valueOf();

        const seconds = Math.round((startTimeValue - currentTime) / 1000);
        const minutes =
            seconds > 0 ? Math.floor(seconds / 60) : Math.ceil(seconds / 60);

        return { minutes, seconds };
    };

    updateTimeToRace = (startTimeValue: number | undefined) => {
        const timeToRace = this.calculateTimeToRace(startTimeValue ?? NaN);

        this.setState(() => timeToRace);
    };

    componentDidMount() {
        const { startTime } = this.props;

        const startTimeValue = this.updateStartTime(startTime);
        this.updateTimeToRace(startTimeValue);

        this.updateInterval = setInterval(() => {
            const { startTimeValue } = this.state;
            this.updateTimeToRace(startTimeValue);
        }, 1000);
    }

    shouldComponentUpdate(
        nextProps: TimeToRaceProps,
        nextState: TimeToRaceState,
    ) {
        const { startTime } = this.props;
        const { startTime: nextStartTime } = nextProps;

        // Start time comes in as a string, so we can just use a string comparison to check if it's equal
        if (startTime !== nextStartTime) {
            return true;
        }

        const { minutes, seconds } = this.state;
        const { minutes: nextMinutes, seconds: nextSeconds } = nextState;

        return minutes !== nextMinutes || seconds !== nextSeconds;
    }

    UNSAFE_componentWillReceiveProps(nextProps: TimeToRaceProps) {
        const { startTime } = nextProps;
        this.updateStartTime(startTime);
    }

    componentWillUnmount() {
        clearInterval(this.updateInterval!);
    }

    render() {
        const { minutes, seconds } = this.state;
        const { render } = this.props;

        return render(minutes, seconds % 60);
    }
}

export default TimeToRace;
