import React from "react";
import classNames from "classnames";
import $ from "jquery";
import { TimelineLite, Back, Power4 } from "gsap";

import * as format from "../../core/format";

import oldStyleComponent from "../../core/oldStyleComponent";
import SponsorTile from "../_shared/SponsorTile";
import TimeToRace from "../_shared/TimeToRace";
import LivePrice from "../_shared/live-price/LivePrice";

import "./TopHorses.styles.less";

const UPDATE_DELAY_SECONDS = 0.2;

interface TopHorsesRunner {
    horseNumber: number;
    returnWin: number;
}

interface TopHorsesProps {
    runners: TopHorsesRunner[];
    raceNumber: number;
    startTime: string;
    logo: string;
}

class TopHorses extends React.Component<TopHorsesProps> {
    container: HTMLDivElement | null = null;
    timeline: TimelineLite | null = null;
    updateDelays: { [horseNumber: number]: number } = {};

    onEntering = () => {
        const header = $(".header", this.container!);
        const runners = $(".runners", this.container!);
        const timer = $(".time-to-race", this.container!);

        const tl = (this.timeline = new TimelineLite());
        tl.fromTo(
            header,
            0.32,
            { ease: Back.easeOut.config(1.7), x: -400, opacity: 0.5 },
            { ease: Back.easeOut.config(1.7), x: 0, opacity: 1 },
            0.4,
        );

        tl.fromTo(
            runners,
            0.2,
            { ease: Power4.easeOut, y: -200, opacity: 0 },
            { ease: Power4.easeOut, y: 0, opacity: 1 },
            0.64,
        );
        tl.fromTo(
            timer,
            0.2,
            { ease: Power4.easeOut, y: -200, opacity: 0 },
            { ease: Power4.easeOut, y: 0, opacity: 1 },
            0.64,
        );
    };

    onExiting = () => {
        this.timeline!.reverse();
    };

    UNSAFE_componentWillUpdate(nextProps: TopHorsesProps) {
        this.updateDelays = {};

        const { runners } = this.props;
        const { runners: nextRunners } = nextProps;
        if (!(runners && nextRunners)) return;

        let updateDelay = 0;

        for (let i = 0; i < Math.min(runners.length, nextRunners.length); i++) {
            const { horseNumber, returnWin } = runners[i]!;
            const { horseNumber: nextHorseNumber, returnWin: nextReturnWin } =
                nextRunners[i]!;

            if (
                horseNumber !== nextHorseNumber ||
                returnWin !== nextReturnWin
            ) {
                this.updateDelays[nextHorseNumber] = updateDelay;
                updateDelay += UPDATE_DELAY_SECONDS;
            } else {
                this.updateDelays[nextHorseNumber] = 0;
            }
        }
    }

    render() {
        const { raceNumber, runners, startTime, logo } = this.props;
        return (
            <div
                className="lf_topHorses"
                ref={(container) => (this.container = container)}
            >
                <div className="header">
                    <div className="logo">
                        <SponsorTile src={logo} />
                    </div>
                    <div className="race-details">
                        <h1>R{raceNumber}</h1>
                        <h3>Top {runners.length}</h3>
                    </div>
                </div>

                <div className="runners">{runners.map(this.renderRunner)}</div>

                <TimeToRace
                    startTime={startTime}
                    render={this.renderTimeToRace}
                />
            </div>
        );
    }

    renderRunner = (runner: TopHorsesRunner) => {
        const updateDelay = this.updateDelays[runner.horseNumber];

        return (
            <div className="runner" key={runner.horseNumber}>
                <div className="runner-number">{runner.horseNumber}</div>
                <div className="runner-price">
                    <LivePrice price={runner.returnWin} delay={updateDelay} />
                </div>
            </div>
        );
    };

    renderTimeToRace = (minutes: number, seconds: number) => {
        // Check minutes <= 1 so the change occurs on transition from 2:00 to 1:59
        const timeToRaceCssClasses = classNames("time-to-race", {
            "t-2minutes": minutes <= 1,
        });

        const sign = seconds < 0 ? "-" : "";

        return (
            <div className={timeToRaceCssClasses}>
                {sign}
                {Math.abs(minutes)}:{format.zeroPad(Math.abs(seconds))}
            </div>
        );
    };
}

const initialState: TopHorsesProps = {
    raceNumber: 0,
    runners: [],
    startTime: "",
    logo: "",
};

const mapStateToProps = (state: TopHorsesProps) => state;

export default oldStyleComponent(
    "RacingAustralia/lf_topHorses",
    initialState,
    mapStateToProps,
    TopHorses,
    1500,
);
