import React from "react";
import { Transition } from "react-transition-group";
import { TimelineLite, Back } from "gsap";

type Props = {
    children?: React.ReactNode | React.ReactNode[];
};

class DetailLine extends React.Component<Props> {
    container: HTMLDivElement | null = null;

    onEntering = () => {
        const timeline = new TimelineLite();
        timeline.from(
            this.container,
            0.52,
            { rotationX: -360, ease: Back.easeOut.config(1.3) },
            0,
        );
    };

    render() {
        return (
            <Transition
                {...this.props}
                onEntering={this.onEntering}
                timeout={{ enter: 1000, exit: 0 }}
            >
                <div
                    className="container"
                    ref={(container) => (this.container = container)}
                >
                    {this.props.children}
                </div>
            </Transition>
        );
    }
}

export default DetailLine;
